import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import petrovich from 'petrovich';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';

import { getUser } from 'common/helpers';
import { loadUserPhotos, deletePhoto, restorePhoto, photoAdd, userUpdate } from 'common/actions';

import EmptyPage from 'components/EmptyPage';
import FormButton from 'components/FormButton';
import FormUploader from 'components/FormUploader';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ModalPhoto from 'components/ModalPhoto';
import Preloader from 'components/Preloader';

import './style.css';

const MODAL_PHOTO = 'MODAL_PHOTO';

class ModalAlbum extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.closePhoto = this.closePhoto.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.loadData = this.loadData.bind(this);
    this.openPhoto = this.openPhoto.bind(this);
    this.restoreItem = this.restoreItem.bind(this);
    this.selectPhoto = this.selectPhoto.bind(this);
    this.showAll = this.showAll.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.state = {
      isAllShown: false,
      modalOpen: '',
      currentPhotoId: 0,
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpen, data } = this.props;

    if (isOpen && !prevProps.isOpen && !data.length) {
      this.loadData();
    }
  }

  showAll() {
    this.setState({
      isAllShown: true,
    });
  }

  loadData() {
    const { user, loadUserPhotos } = this.props;

    loadUserPhotos(user.id);
  }

  deleteItem(e) {
    const photoId = parseInt(e.currentTarget.dataset.id, 10);

    this.props.deletePhoto(photoId);
  }

  restoreItem(e) {
    const photoId = parseInt(e.currentTarget.dataset.id, 10);

    this.props.restorePhoto(photoId);
  }

  openPhoto(e) {
    const photoId = parseInt(e.currentTarget.dataset.photoId, 10);

    this.setState({
      modalOpen: MODAL_PHOTO,
      currentPhotoId: photoId,
    });
  }

  closePhoto(callback) {
    this.setState({
      modalOpen: '',
    }, () => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  }

  closeAll() {
    this.closePhoto(this.props.handleClose);
  }

  selectPhoto(e) {
    const { handleClose, userUpdate } = this.props;
    const { onChoose } = this.props.settings;
    const photoId = parseInt(e.currentTarget.dataset.photoId, 10);
    const photoSrc = e.currentTarget.dataset.photoSrc;

    if (onChoose) {
      onChoose([{ id: photoId, src: photoSrc }]);
    } else {
      userUpdate({
        avatar: photoId,
      }, {
        updateType: 'avatar',
      });
    }

    handleClose();
  }

  renderTitle() {
    const { isSelf, user, settings } = this.props;

    if (settings.allowSelect) {
      return 'Выберите фотографию';
    }
    if (isSelf) {
      return 'Ваши фотографии ';
    }

    return `Фотоальбом ${petrovich[user.gender.code].first.genitive(user.name).replace(/(?:(ж|ш)ы)/g, '$1и')} `;
  }

  render() {
    const { isOpen, handleClose, isSelf, user, requestState, photoAdd, settings, dataObject } = this.props;
    const { allowSelect, onChoose } = settings;
    const { isAllShown, modalOpen, currentPhotoId } = this.state;
    const LIMIT = isSelf ? 7 : 8;
    const data = this.props.data.slice(0).filter(p => !(allowSelect && p.deleted));

    return (
      <React.Fragment>
        <Modal isOpen={ isOpen && !modalOpen } handleClose={ handleClose }>
          <div className="modal-album">
            { !isEmpty(user) &&
              <React.Fragment>
                <h2>
                  { this.renderTitle() }
                  { !allowSelect &&
                    <span className="text-gray">{ user.photosCount }</span>
                  }
                </h2>
                { requestState.request &&
                  <Preloader />
                }
                { !!requestState.error &&
                  <EmptyPage>
                    <h2>Ошибка!</h2>
                    <p>Не удалось загрузить фотографии. <br />Проверьте соединение с Интернетом и повторите попытку.</p>
                    <FormButton skin={['size-m', 'darkblue']} onClick={ this.loadData }>
                      Попробовать снова
                    </FormButton>
                  </EmptyPage>
                }
                { requestState.success &&
                  <React.Fragment>
                    { data.length
                      ? <div className="modal-album__photos">
                          { isSelf &&
                            <div className="modal-album__item">
                              <FormUploader
                                mode="album"
                                onComplete={ onChoose || photoAdd }
                                multiple
                              />
                            </div>
                          }
                          {
                            data.slice(0, isAllShown ? Infinity : LIMIT).map(photo =>
                              <div className="modal-album__item" key={ photo.id }>
                                <div
                                  className={classNames(
                                    'modal-album__item-body',
                                    { '-deleted': !allowSelect && photo.deleted },
                                  )}
                                >
                                  { !photo.deleted &&
                                    <button
                                      type="button"
                                      className="modal-album__item-body-opener"
                                      onClick={ allowSelect ? this.selectPhoto : this.openPhoto }
                                      data-photo-id={ photo.id }
                                      data-photo-src={ photo.src.default }
                                    />
                                  }
                                  <div className="modal-album__item-body-main">
                                    { !allowSelect && isSelf &&
                                      <FormButton skin={ ['circle', 'small'] } icon="option-delete" onClick={ this.deleteItem } data-id={ photo.id } />
                                    }
                                    <div className="modal-album__item-overlay">
                                      { !allowSelect &&
                                        <React.Fragment>
                                          <div className="modal-album__item-overlay-stat">
                                            <Icon glyph="heart-filled" />
                                            <span className="modal-album__item-overlay-stat-value">{ photo.likesCount }</span>
                                          </div>
                                          <div className="modal-album__item-overlay-stat">
                                            <Icon glyph="chat-stat" />
                                            <span className="modal-album__item-overlay-stat-value">{ photo.commentsCount }</span>
                                          </div>
                                        </React.Fragment>
                                      }
                                    </div>
                                    <div className="modal-album__item-photo" style={{ backgroundImage: `url("${photo.src.default}")` }} />
                                  </div>
                                  { !allowSelect &&
                                    <div className="modal-album__item-infobox">
                                      <p>Фото удалено <br />из альбома</p>
                                      <FormButton skin={['size-m', 'size-s-mobile', 'lightblue']} onClick={ this.restoreItem } data-id={ photo.id }>
                                        Отмена
                                      </FormButton>
                                    </div>
                                  }
                                </div>
                              </div>
                            )
                          }
                          <div className="flex-spacer" />
                        </div>
                      : <EmptyPage>
                          <h2>Альбом пуст</h2>
                          { isSelf
                            ? <React.Fragment>
                                <p>Вы ещё не добавили ни одной фотографии. <br />Самое время исправить это!</p>
                                <FormButton skin={['size-m', 'darkblue']} onClick={ this.showAll }>
                                  Добавить фото
                                </FormButton>
                              </React.Fragment>
                            : <p>{ user.name } ещё не добавил{ user.gender.code === 'female' && 'а' } ни одной фотографии</p>
                          }
                        </EmptyPage>
                    }
                  </React.Fragment>
                }
                { !isAllShown && data.length > LIMIT &&
                  <div className="modal-album__more">
                    <FormButton skin={['size-m', 'size-s-mobile', 'lightblue']} onClick={ this.showAll }>
                      Показать еще<span className="hidden-b-m"> фото</span>
                    </FormButton>
                  </div>
                }
              </React.Fragment>
            }
          </div>
        </Modal>
        <ModalPhoto
          user={ user }
          data={ dataObject }
          initialPhotoId={ currentPhotoId }
          isOpen={ modalOpen === MODAL_PHOTO }
          handleClose={ this.closePhoto }
          handleCloseAll={ this.closeAll }
          isReversed
        />
      </React.Fragment>
    );
  }
};

function mapStateToProps(state, ownProps) {
  const {
    session,
    states,
    modal: {
      payload,
    },
    data: {
      userPhotos,
    },
    entities: {
      photos,
    },
  } = state;

  const { userId, ...settings } = payload;
  const isSelf = !settings.isGuestMode && (session || {}).user === userId;
  const user = getUser(state, userId);
  const data = ((userPhotos || {})[userId] || []).map(id => photos[id]);
  const dataObject = (data || []).slice(0).reduce((map, obj) => {
    if (!obj.deleted) {
      map[obj.id] = obj;
    }

    return map;
  }, {});
  const requestState = states.userPhotos || {};

  return {
    isSelf,
    user,
    data,
    dataObject,
    requestState,
    settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadUserPhotos,
    deletePhoto,
    restorePhoto,
    photoAdd,
    userUpdate,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalAlbum);

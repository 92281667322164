import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { sendTicketCooperation } from 'common/actions';

import ModalTicket from 'components/ModalTicket';
import FormInput from 'components/FormInput';

const ModalTicketCooperation = ({
  isOpen,
  handleClose,
  sendTicketCooperation,
  actionState,
}) => (
  <ModalTicket
    isOpen={ isOpen }
    handleClose={ handleClose }
    title="Связаться с&nbsp;LinkYou по&nbsp;вопросам сотрудничества"
    formId="form-ticket-cooperation"
    action={ sendTicketCooperation }
    actionState={ actionState }
  >
    <FormInput
      placeholder="Ваше имя"
      type="text"
      name="name"
      validate="required"
      autofocus
    />
    <FormInput
      placeholder="Контакт для связи"
      type="text"
      name="contact"
      validate="required"
      autofocus
    />
    <FormInput
      isTextarea
      placeholder="Комментарий"
      name="message"
      rows={ 3 }
      validate="required"
    />
  </ModalTicket>
);

ModalTicketCooperation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    actionState: state.states.ticketCooperation || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    sendTicketCooperation,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTicketCooperation);

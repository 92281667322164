import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateUnreadMessagesCount, imMessageGet, imDialogRead } from 'common/actions';
import { playSound } from 'common/helpers';

class BadgeUpdater extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  static defaultProps = {
    location: {},
  }

  componentDidUpdate(prevProps) {
    if (this.props.imDeletedDialogue.timestamp !== prevProps.imDeletedDialogue.timestamp) {
      this.handleIMDialogueDelete();
    }

    if (this.props.imNewMessage.messageId !== prevProps.imNewMessage.messageId) {
      this.handleIMNewMessage();
      playSound('newMessage');
    }
  }

  handleIMDialogueDelete() {
    const { imDialogs, updateUnreadMessagesCount, imDeletedDialogue } = this.props;
    const unreadCount = (imDialogs[imDeletedDialogue.id] || {}).unreadMessagesCount || 0;

    if (unreadCount) {
      updateUnreadMessagesCount(imDeletedDialogue.id, -unreadCount);
    }
  }

  handleIMNewMessage() {
    const {
      location,
      imMessages,
      imNewMessage,
      imMessageGet,
      updateUnreadMessagesCount,
      imDialogRead,
    } = this.props;
    const { dialogId } = imNewMessage;

    if (location.pathname.search('/im') !== 0) {
      return updateUnreadMessagesCount(dialogId, 1);
    }

    imMessageGet(imNewMessage).then(() => {
      if (location.search !== `?d=${dialogId}`) {
        updateUnreadMessagesCount(dialogId, 1);
      } else {
        imDialogRead(dialogId);
      }
    });
  }

  render() {
    return null;
  }
};

function mapStateToProps(state) {
  return {
    imDialogs: state.entities.imDialogs || {},
    imMessages: state.data.imMessages || {},
    imDeletedDialogue: state.wsUpdates.imDeletedDialogue,
    imNewMessage: state.wsUpdates.imNewMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    imDialogRead,
    imMessageGet,
    updateUnreadMessagesCount,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BadgeUpdater);

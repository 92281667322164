import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isMobile from 'ismobilejs';
import Helmet from 'react-helmet';

import { isSSR } from 'common/helpers';

let windowResizingTimeout;

export default class LayoutHelmet extends Component {
  static propTypes = {
    isLanding: PropTypes.bool.isRequired,
    navIsVisible: PropTypes.bool.isRequired,
    shouldBlockInteractions: PropTypes.bool.isRequired,
    pageType: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
    this.applyAccessibility = this.applyAccessibility.bind(this);
    this.state = {
      windowIsResizing: false,
      accessibility: false,
      isMounted: false,
    };
  }

  componentDidMount() {
    if (!isSSR()) {
      window.addEventListener('resize', this.handleResize, false);
      document.addEventListener('mousedown', this.applyAccessibility, false);
      document.addEventListener('keydown', this.applyAccessibility, false);
    }

    this.setState({
      isMounted: true,
    });
  }

  componentWillUnmount() {
    if (!isSSR()) {
      clearTimeout(windowResizingTimeout);
      window.removeEventListener('resize', this.handleResize, false);
      document.removeEventListener('mousedown', this.applyAccessibility, false);
      document.removeEventListener('keydown', this.applyAccessibility, false);
    }
  }

  handleResize() {
    clearTimeout(windowResizingTimeout);

    this.setState({
      windowIsResizing: true,
    }, () => {
      windowResizingTimeout = setTimeout(() => {
        this.setState({
          windowIsResizing: false,
        });
      }, 400);
    });
  }

  applyAccessibility(e) {
    const keyCode = e.keyCode || e.charCode;
    const { accessibility } = this.state;
    let nextAccessibility = true;

    if (
      e.type === 'mousedown' ||
      (e.type === 'keydown' && keyCode !== 9 && !accessibility)
    ) {
      nextAccessibility = false;
    }

    this.setState({
      accessibility: nextAccessibility,
    });
  }

  render() {
    const {
      windowIsResizing,
      accessibility,
      isMounted,
    } = this.state;
    const {
      shouldBlockInteractions,
      navIsVisible,
      isLanding,
      pageType,
    } = this.props;

    const config = {
      htmlAttributes: {
        'data-accessibility': accessibility ? 'keyboard' : 'mouse',
        class: classNames(
          `page-${pageType}`,
          isMobile.any ? 'is-mobile' : 'no-mobile',
          `skin-${isLanding ? 'white' : 'gray'}`,
          { 'is-resizing': windowIsResizing },
          { 'is-loading': !isMounted },
          { 'with-nav': navIsVisible },
        ),
      },
    };

    if (shouldBlockInteractions) {
      config.htmlAttributes.style = 'pointer-events: none !important; width: 100%; position: fixed; top: 0px; overflow: hidden;';
    }

    // if (!isMounted) {
    //   config.style = [{
    //     cssText: `
    //       html,
    //       body {
    //         background-color: #f6f8fb;
    //       }
    //       #root {
    //         height: 100%;
    //         pointer-events: none;
    //       }
    //       .layout {
    //         visibility: hidden;
    //       }
    //     `,
    //   }];
    // }

    return <Helmet { ...config } />;
  }
};

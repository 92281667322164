import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SectionBody from './components/Body';
import SectionHeader from './components/Header';

import './style.css';

const Section = ({ narrow, blue, children, className, anchor }) => (
  <section
    className={classNames(
      'section',
      { '-narrow': narrow },
      { '-blue': blue },
      className,
    )}
  >
    { !!anchor &&
      <div className="section__anchor" id={ anchor }></div>
    }
    { children }
  </section>
);

Section.propTypes = {
  narrow: PropTypes.bool,
  blue: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  className: PropTypes.string,
  anchor: PropTypes.string,
};

export default Section;
export {
  SectionBody,
  SectionHeader,
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import petrovich from 'petrovich';
import noop from 'lodash/noop';

import { getUser } from 'common/helpers';
import { loadGiftsStore, openModalPay, closeModal } from 'common/actions';

import FormButton from 'components/FormButton';
import Tabs from 'components/Tabs';
import Modal from 'components/Modal';
import ModalGiftSend from 'components/ModalGiftSend';

import './style.css';

class ModalGiftsStore extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleCloseAll: PropTypes.func.isRequired,
    recipientId: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props);
    this.give = this.give.bind(this);
    this.cancelGiving = this.cancelGiving.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.modalCloseAll = this.modalCloseAll.bind(this);
    this.state = {
      selectedTab: '',
      selectedGift: 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.selectedTab && props.tabsData && props.tabsData[0]) {
      return {
        selectedTab: props.tabsData[0].value,
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.loadGiftsStore();
  }

  changeTab(newTab) {
    this.setState({
      selectedTab: newTab,
    });
  }

  give(e) {
    const { hasFreeGifts, recipientId, openModalPay } = this.props;
    const selectedGift = parseInt(e.currentTarget.dataset.id, 10);

    this.setState({
      selectedGift,
    }, () => {
      if (!hasFreeGifts) {
        openModalPay({
          recipientId,
          entityId: selectedGift,
          handleClose: this.modalCloseAll,
          mode: 'gift',
        });
      }
    });
  }

  cancelGiving(callback = noop) {
    this.setState({
      selectedGift: 0,
    }, callback);
  }

  modalCloseAll() {
    this.cancelGiving(() => {
      this.props.closeModal();
      this.props.handleCloseAll();
    });
  }

  render() {
    const { isOpen, handleClose, tabsData, user, showcase, gifts, recipientId, currentUser, hasFreeGifts } = this.props;
    const { selectedTab, selectedGift } = this.state;
    const isFirstGift = user.gifts && !user.gifts.count;
    const data = showcase[selectedTab] || [];

    return (
      <React.Fragment>
        <Modal isOpen={ isOpen } handleClose={ handleClose } isHidden={ !!selectedGift }>
          <div className="modal-gifts-store">
            <h2>Сделать{ isFirstGift && ' первый' } подарок { user.id && petrovich[user.gender.code].first.dative(user.name) }{ isFirstGift && '!' }</h2>
            <Tabs
              className="-link -size-s"
              selected={ selectedTab }
              items={ tabsData }
              onChange={ this.changeTab }
            />
            <div className="modal-gifts-store__showcase">
              {
                data.map(gift =>
                  <div className="modal-gifts-store__item" onClick={ this.give } data-id={ gift } key={ gift }>
                    <figure>
                      <img src={ gifts[gift].picture } alt={ gifts[gift].name } />
                    </figure>
                    <div className="modal-gifts-store__item-give">
                      { !hasFreeGifts &&
                        <div className="modal-gifts-store__item-give-price">{ gifts[gift].cost } руб.</div>
                      }
                      <FormButton skin={ ['darkblue', 'size-m', 'size-s-mobile'] } noop>
                        Подарить
                      </FormButton>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </Modal>
        { !!selectedGift && hasFreeGifts &&
          <ModalGiftSend
            recipientId={ recipientId }
            giftId={ selectedGift }
            handleClose={ this.cancelGiving }
            handleCloseAll={ this.modalCloseAll }
            isOpen
          />
        }
      </React.Fragment>
    );
  }
};

function mapStateToProps(state, ownProps) {
  const { recipientId } = ownProps;
  const {
    data: {
      giftsStore,
    },
    entities: {
      gifts,
      users,
      usersCompact,
    },
  } = state;
  const user = (usersCompact || {})[recipientId] || (users || {})[recipientId] || {};
  const tabsData = (giftsStore || []).map(category => ({
    label: category.name,
    value: `${category.id}`,
  }));
  const showcase = (giftsStore || []).slice(0).reduce((map, obj) => {
    map[obj.id] = obj.items;
    return map;
  }, {});

  const currentUser = getUser(state, (state.session || {}).user);
  const hasFreeGifts = currentUser.gifts && currentUser.gifts.freeGifts > 0;

  return {
    tabsData,
    user,
    showcase,
    gifts,
    currentUser,
    hasFreeGifts,
    isPayment: state.modal.type === 'MODAL_PAY',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadGiftsStore,
    openModalPay,
    closeModal,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalGiftsStore);

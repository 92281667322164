import { generateTypes } from 'common/helpers';
import { CALL_API, Schemas } from 'common/middleware/api';

export const loadBlogs = () => ({
  [CALL_API]: {
    types: generateTypes('BLOG'),
    method: 'get',
    endpoint: 'blogs',
    schema: Schemas.BLOGS,
  }
});

export const loadBlogPost = (id) => ({
  [CALL_API]: {
    types: generateTypes('BLOG_POST'),
    method: 'get',
    endpoint: `blog/${id}`,
    schema: Schemas.BLOG,
  }
});

export const loadBlogComments = (id) => ({
  [CALL_API]: {
    types: generateTypes('BLOG_COMMENTS'),
    method: 'get',
    endpoint: `blog/${id}/comments`,
    schema: Schemas.BLOG_COMMENTS,
  }
});

const blogCommentAddLocal = (response) => ({
  response,
  type: 'BLOG_COMMENT_ADD_LOCAL',
});

const blogCommentAddRemote = (postId, tempId, comment) => ({
  [CALL_API]: {
    body: {
      comment,
    },
    payload: {
      tempId,
    },
    types: generateTypes('BLOG_COMMENT_ADD_REMOTE'),
    method: 'post',
    endpoint: `blog/${postId}/comment/add`,
    schema: Schemas.BLOG_COMMENT,
  }
});

export const blogCommentAdd = (postId, comment, localResponse) => dispatch => {
  dispatch(blogCommentAddLocal(localResponse));
  dispatch(blogCommentAddRemote(postId, localResponse.result, comment));
};

export const blogCommentDelete = (postId, commentId) => ({
  [CALL_API]: {
    payload: {
      postId,
      commentId,
    },
    types: generateTypes('BLOG_COMMENT_DELETE'),
    method: 'post',
    endpoint: `blog/${postId}/comment/${commentId}/delete`,
  }
});

export const blogLike = (userId, postId) => ({
  [CALL_API]: {
    payload: {
      userId,
      postId,
    },
    types: generateTypes('BLOG_LIKE'),
    method: 'post',
    endpoint: `blog/${postId}/like`,
  }
});

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openModalAlbum } from 'common/actions';
import { isSSR, resolution } from 'common/helpers';

import Icon from 'components/Icon';
import UserAvatar from 'components/UserAvatar';
import UserName from 'components/UserName';
import UserJob from 'components/UserJob';
import Indicator from 'components/Indicator';
import FormButton from 'components/FormButton';

import './style.css';

const DRAG_THRESHOLD = 40;
const DRAG_MAX = 90;

class UserCard extends Component {
  static propTypes = {
    user: PropTypes.object,
    switchAction: PropTypes.func,
    deletedMessage: PropTypes.string,
    empty: PropTypes.bool,
    disableMobileView: PropTypes.bool,
  }

  static defaultProps = {
    deletedMessage: 'Анкета удалена',
    disableMobileView: false,
  }

  static getDerivedStateFromProps(props) {
    return {
      isDeletable: !!props.switchAction,
    };
  }

  constructor(props) {
    super(props);
    this.openAlbum = this.openAlbum.bind(this);
    this.adapt = this.adapt.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.restoreUser = this.restoreUser.bind(this);
    this.startDrag = this.startDrag.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.finishDrag = this.finishDrag.bind(this);
    this.state = {
      rootSkin: props.disableMobileView ? '' : '-mobile',
      avatarSkin: props.disableMobileView ? 'square' : 'circle',
      isDeletable: false,
      isDeleted: false,
      isDragged: false,
      dragStartOffset: 0,
      dragMovement: 0,
    };
  }

  componentDidMount() {
    if (!isSSR() && !this.props.disableMobileView) {
      window.addEventListener('resize', this.adapt, false);
      this.adapt();
    }
  }

  componentWillUnmount() {
    if (!isSSR() && !this.props.disableMobileView) {
      window.removeEventListener('resize', this.adapt, false);
    }
  }

  adapt() {
    const prevAvatarSkin = this.state.avatarSkin;
    const nextAvatarSkin = resolution.isMobile() ? 'circle' : 'square';
    const prevRootSkin = this.state.rootSkin;
    const nextRootSkin = resolution.isMobile() && !this.props.disableMobileView ? '-mobile' : '';

    if (prevAvatarSkin !== nextAvatarSkin || prevRootSkin !== nextRootSkin) {
      this.setState({
        avatarSkin: nextAvatarSkin,
        rootSkin: nextRootSkin,
      });
    }
  }

  openAlbum() {
    const {
      openModalAlbum,
      user: {
        id,
      },
    } = this.props;

    openModalAlbum(id);
  }

  deleteUser() {
    const { switchAction, user: { id } } = this.props;

    this.setState({
      isDeleted: true,
      isDragged: false,
      dragStartOffset: 0,
      dragMovement: 0,
    });

    switchAction(id);
  }

  restoreUser() {
    const { switchAction, user: { id } } = this.props;

    this.setState({
      isDeleted: false,
    });

    switchAction(id);
  }

  startDrag(e) {
    const { isDeletable } = this.state;
    const { touches } = e;

    if (!isDeletable) {
      return;
    }

    if (touches.length === 1) {
      this.setState({
        isDragged: true,
        dragStartOffset: touches[0].pageX,
      });
    }
  }

  handleDrag(e) {
    const { isDragged, dragStartOffset, dragMovement } = this.state;
    const { touches } = e;

    if (!isDragged) {
      return;
    }

    const pixelsMoved = dragStartOffset - touches[0].pageX;

    if ((pixelsMoved > 0 && dragMovement >= DRAG_MAX) || (pixelsMoved < 0 && dragMovement <= 0)) {
      return;
    }

    this.setState({
      dragMovement:
        pixelsMoved > 0
          ? Math.min(pixelsMoved, DRAG_MAX * 1.2)
          : Math.max(DRAG_MAX + pixelsMoved, 0)
    });
  }

  finishDrag() {
    const { isDragged, dragMovement } = this.state;

    if (!isDragged) {
      return;
    }

    this.setState({
      isDragged: false,
      dragStartOffset: 0,
      dragMovement: dragMovement > DRAG_THRESHOLD ? DRAG_MAX : 0,
    });
  }

  render() {
    const { user, deletedMessage, empty, isAMP } = this.props;
    const { avatarSkin, rootSkin, isDeletable, isDeleted, isDragged, dragMovement } = this.state;

    if (empty) {
      return (
        <div className="user-card" />
      );
    }

    if (!user) {
      return null;
    }

    return (
      <div
        className={classNames(
          'user-card',
          rootSkin,
          { '-vip': user.isVip },
          { '-deletable': isDeletable },
          { '-deleting': !!dragMovement },
          { '-deleted': isDeleted },
          { '-dragged': isDragged },
        )}
        onTouchStart={ this.startDrag }
        onTouchMove={ this.handleDrag }
        onTouchEnd={ this.finishDrag }
      >
        { user.extra && user.extra.time &&
          <div className="user-card__time">{ user.extra.time }</div>
        }
        <div
          className="user-card__body"
          style={{
            transform: `translateX(-${dragMovement}px)`,
          }}
        >
          { isDeletable && !isDeleted &&
            <div className="user-card__remove">
              <FormButton
                skin={ ['circle', 'small'] }
                icon="option-delete"
                onClick={ this.deleteUser }
              />
              <button className="user-card__remove-mobile" onClick={ this.deleteUser }>
                <Icon glyph="cross" />
              </button>
            </div>
          }
          { isDeleted &&
            <div className="user-card__message">
              <div className="user-card__message-text" dangerouslySetInnerHTML={{ __html: deletedMessage }} />
              <FormButton skin={ ['size-m', 'size-s-mobile', 'lightblue'] } onClick={ this.restoreUser }>
                Отменить
              </FormButton>
            </div>
          }
          <div className="user-card__avatar">
            <UserAvatar user={user} skin={avatarSkin} size="large" withLink={user.roleStatus !== 'deleted'} isAMP={isAMP} />
          </div>
          <div className="user-card__data">
            <UserName user={ user } withLink={ user.roleStatus !== 'deleted' } withAge />
            <div className="user-card__data-job">
              { user.roleStatus !== 'deleted' &&
                <UserJob job={ user.job } />
              }
            </div>
            <div className="user-card__data-indicators">
              { user.roleStatus !== 'deleted' &&
                <React.Fragment>
                  <Indicator icon="photo" value={ user.photosCount } onClick={ this.openAlbum } />
                  <Indicator icon="location" value={ user.location.cityName } />
                </React.Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModalAlbum,
  }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps,
)(UserCard);

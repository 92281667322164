import React from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames';

import { getUserName } from 'common/helpers';

import Badge from 'components/Badge';
import Link from 'components/Link';
import LazyLoadImage from 'components/LazyLoadImage';

import './style.css';

const SQUARE = 'square';
const CIRCLE = 'circle';
const SMALL = 'small';
const MEDIUM = 'medium';
const LARGE = 'large';

const SIZES = {
  small: 20,
  medium: 50,
  large: 70,
};

const UserAvatar = ({ skin, size, user, withoutBadges, withLink, onClick, anonymous, isSelf, isAMP }) => {
  const dimension = skin === CIRCLE ? SIZES[size] : null;

  let tagName;
  let src;
  let props = {
    className: classNames(
      'user-avatar',
      { [`-${skin}`]: skin },
      { [`-${size}`]: size },
      { '-vip': !withoutBadges && !anonymous && user.isVip },
      { '-premium': !withoutBadges && !anonymous && user.isPremium },
      { '-online': !withoutBadges && !anonymous && user.isOnline },
    ),
  };

  if (withLink) {
    tagName = Link;
    Object.assign(props, {
      to: `/user/${user.id}`,
    });
  } else if (onClick) {
    tagName = 'button';
    Object.assign(props, {
      onClick,
      type: 'button',
      'data-photo-id': user.avatar && user.avatar.id,
    });
  } else {
    tagName = 'span';
  }

  if (user.role < 10 && !isSelf) {
    src = '/images/noavatar/inactive.svg';
  } else if (!anonymous && user && user.avatar && user.avatar.src && user.avatar.src.square) {
    src = user.avatar.src.square;
  } else {
    src = '/images/noavatar/user.svg';
  }

  return React.createElement(
    tagName,
    props,
    <React.Fragment>
      <span className="user-avatar__figure">
        {!isAMP &&
          <>
            <img
              src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
              width={ dimension }
              height={ dimension }
              alt=""
              className="user-avatar__placeholder"
            />
            <LazyLoadImage
              alt={getUserName(user, anonymous)}
              src={src}
              width={dimension}
              height={dimension}
              className="user-avatar__image"
            />
          </>
        }
        {isAMP &&
          <amp-img
            src={src}
            width="300"
            height="300"
            layout="responsive"
          />
        }
      </span>
      { !anonymous && !withoutBadges &&
        <React.Fragment>
          { user.top100 && skin === SQUARE &&
            <Badge type="crown" size="l" value={ user.top100 } />
          }
          { user.isVip &&
            <Badge type="crown" size={ skin === CIRCLE ? 's' : 'm' } />
          }
          { user.isPremium && !user.isVip &&
            <Badge type="star" size={ skin === CIRCLE ? 's' : 'm' } />
          }
          { user.isOnline &&
            <span className="user-avatar__online" />
          }
        </React.Fragment>
      }
    </React.Fragment>,
  );
};

UserAvatar.propTypes = {
  skin: PropTypes.oneOf([CIRCLE, SQUARE]),
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
  user: PropTypes.object,
  withoutBadges: PropTypes.bool,
  withLink: PropTypes.bool,
  onClick: PropTypes.func,
  anonymous: PropTypes.bool,
  isSelf: PropTypes.bool,
};

UserAvatar.defaultProps = {
  isSelf: false,
};

export default UserAvatar;

import io from 'socket.io-client';
import { camelizeKeys } from 'humps';

import { handlePacket } from 'common/actions';
import { shouldUseDevAPI } from 'common/helpers';

export const START_TYPING = 'start_typing';
export const STOP_TYPING = 'stop_typing';
export const USER_OFFLINE = 'user_offline';
export const USER_ONLINE = 'user_online';
export const IM_MESSAGES_DELETE = 'im_messages_delete';
export const IM_DIALOGUE_DELETE = 'im_dialogue_delete';
export const GIFT_NEW = 'gift_new';

const server = shouldUseDevAPI()
  ? 'ws://socket.linkyou.klendev.ru:8080'
  : 'wss://socket.linkyou.ru:443';

export const socket = io(server, {
  autoConnect: false,
});

export const connectSockets = token => {
  socket.connect();
  socket.emit('handshake', token);
};

export const disconnectSockets = () => {
  socket.off('packet');
  socket.disconnect();
};

export const sendPacket = (cmd, data, users) => {
  socket.emit('redirect', JSON.stringify({
    users,
    packet: { cmd, data },
  }));
};

export default store => {
  socket.on('packet', packet => {
    if (!packet.hasOwnProperty('cmd')) {
      return console.warn('socketio: bad event received: ' + JSON.stringify(event));
    }

    const cmd = `WS_${packet.cmd.toUpperCase()}`;
    const data = camelizeKeys(packet.data || {});

    store.dispatch(handlePacket(cmd, data));
  });

  return next => action => next(action);
};

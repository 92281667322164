import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'components/Icon';
import Modal from 'components/Modal';

import './style.css';

const DELAY = 4000;

class ModalSuccess extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    headline: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.autoHider = null;

    this.handleClose = this.handleClose.bind(this);

    this.state = {
      animated: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.autoHider = setTimeout(() => {
        this.setState({
          animated: true,
        }, () => {
          this.autoHider = setTimeout(() => {
            this.setState({
              animated: false,
            }, this.handleClose);
          }, DELAY);
        });
      }, 150);
    }
  }

  handleClose() {
    clearTimeout(this.autoHider);
    this.props.handleClose();
  }

  render() {
    const { isOpen, headline } = this.props;
    const { animated } = this.state;

    return (
      <Modal isOpen={ isOpen } handleClose={ this.handleClose }>
        <div className="modal-success">
          <h2>{ headline }</h2>
          <div
            className={classNames(
              'modal-success__icon',
              { '-animated': animated },
            )}
          >
            <div className="modal-success__icon-overlay">
              <div className="modal-success__icon-overlay-door" />
            </div>
            <Icon glyph="success" />
          </div>
        </div>
      </Modal>
    );
  }
};

export default ModalSuccess;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';

import { isSSR } from 'common/helpers';

import Icon from 'components/Icon';
import Link from 'components/Link';

import './style.css';

const Indicator = ({ icon, value, className, ...rest }) => {
  let TagName;
  let props = {
    className: classNames(
      'indicator',
      className,
    ),
    ...rest
  };

  if (rest.to) {
    TagName = rest.to.search('#') !== 0 && !isSSR() ? HashLink : Link;
  } else if (rest.onClick) {
    TagName = 'button';
    props.type = 'button';
  } else {
    TagName = 'span';
  }

  return (
    <TagName { ...props }>
      <span className="indicator__icon">
        <Icon glyph={ icon } />
      </span>
      <span className="indicator__value" title={ value }>{ value }</span>
    </TagName>
  );
};

Indicator.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  className: PropTypes.string,
};

export default Indicator;

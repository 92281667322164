import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';

import { visibilitySwitch, hideNav } from 'common/actions';
import { isSSR, isUserDisabled, getUser } from 'common/helpers';

import { LayoutContext } from 'document/Layout';

import FormButton from 'components/FormButton';
import Icon from 'components/Icon';
import Link from 'components/Link';
import ModalGoInvisible from 'components/ModalGoInvisible';
import ModalGoPremium from 'components/ModalGoPremium';
import Notification from 'components/Notification';
import Switcher from 'components/Switcher';
import UserAvatar from 'components/UserAvatar';
import UserName from 'components/UserName';

import './style.css';

const MODAL_GO_PREMIUM = 'MODAL_GO_PREMIUM';
const MODAL_GO_INVISIBLE = 'MODAL_GO_INVISIBLE';

class UserPanelProfile extends Component {
  constructor(props) {
    super(props);

    this.openModalPremium = this.openModalPremium.bind(this);
    this.openModalInvisible = this.openModalInvisible.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.visibilitySwitch = this.visibilitySwitch.bind(this);

    this.state = {
      modal: null,
      shouldShowBackButton: false,
    };
  }

  componentDidUpdate(prevProps) {
    const prevPath = prevProps.location.pathname || '';
    const path = this.props.location.pathname || '';

    if (path === prevPath) {
      return;
    }

    this.setState(state => {
      const shouldShowBackButton = prevPath === '/search' && path.search('/user/') === 0;

      if (state.shouldShowBackButton !== shouldShowBackButton) {
        return {
          shouldShowBackButton,
        };
      }

      return null;
    });
  }

  openModal(modal) {
    this.setState({
      modal,
    });
  }

  openModalPremium() {
    const { hideNav } = this.props;
    hideNav();
    this.openModal(MODAL_GO_PREMIUM);
  }

  openModalInvisible() {
    this.openModal(MODAL_GO_INVISIBLE);
  }

  closeModal() {
    this.setState({
      modal: null,
    });
  }

  visibilitySwitch() {
    const { currentUser, visibilitySwitch, hideNav } = this.props;

    if (currentUser.isStealth) {
      visibilitySwitch(currentUser.id);
    } else {
      hideNav();
      this.openModalInvisible();
    }
  }

  renderMenu() {
    const { badges, currentUser } = this.props;

    const data = [
      { icon: 'edit', title: 'Редактировать анкету', link: '/edit' },
      { icon: 'chat', title: 'Мои сообщения', link: '/im', notification: badges.messages || -1 },
      { icon: 'heart', title: 'Мои лайки', link: '/answers', notification: badges.likes || -1 },
      { icon: 'eye', title: 'Просмотры анкеты', link: '/guests', notification: badges.guests || -1 },
      { icon: 'user', title: 'Вам подходят', link: '/matched' },
      { icon: 'star-stroke', title: 'Избранное', link: '/favorites' },
      { icon: 'write', title: 'Написать в блог', link: `/user/${currentUser.id}#post`, hash: true },
      { icon: 'settings', title: 'Настройки аккаунта', link: '/settings' },
    ];

    return data.map(item => {
      const TagName = item.hash && !isSSR() ? HashLink : Link;

      return (
        <div className="user-panel-profile__menu-item" key={ item.icon }>
          <TagName to={ item.link } className="user-panel-profile__menu-item-link">
            <span className="user-panel-profile__menu-item-link-icon">
              <Icon glyph={ item.icon }/>
            </span>
            <span className="user-panel-profile__menu-item-link-value">{ item.title }</span>
            { item.notification &&
              <Notification value={ item.notification } />
            }
          </TagName>
        </div>
      );
    });
  }

  render() {
    const { modal, shouldShowBackButton } = this.state;
    const { currentUser, visibilitySwitchRequestState, history } = this.props;

    if (!currentUser) {
      return null;
    }

    return (
      <div
        className={classNames(
          'user-panel-profile',
          { '-disabled': isUserDisabled(currentUser) },
        )}
      >
        { shouldShowBackButton &&
          <div className="user-panel-profile__back">
            <FormButton skin={ ['darkblue', 'size-s', 'size-m-tablet'] } onClick={ history.goBack }>
              ← К результатам поиска
            </FormButton>
          </div>
        }
        <div className="user-panel-profile__title">
          <Link to={ `/user/${currentUser.id}` }>
            { currentUser.avatar && currentUser.avatar.id !== 0 &&
              <UserAvatar user={ currentUser } skin="circle" size="small" isSelf withoutBadges />
            }
            <UserName user={ currentUser } withAge isSelf />
            { currentUser.isPremium &&
              <span className="user-panel-profile__badge" title={ `Premium истекает через ${moment().to(currentUser.endPremium, true)}` }>
                <Icon glyph="star-fill" />
              </span>
            }
            { currentUser.isVip &&
              <span className="user-panel-profile__badge" title={ `Размещение на главной истекает через ${moment().to(currentUser.endVip, true)}` }>
                <Icon glyph="crown-fill" />
              </span>
            }
          </Link>
        </div>
        <div className="user-panel-profile__menu">
          { this.renderMenu() }
          <div className="user-panel-profile__menu-item">
            <button className="user-panel-profile__menu-item-link" onClick={ this.visibilitySwitch } disabled={ visibilitySwitchRequestState.request }>
              <Switcher active={ currentUser.isInvisible } />
              <span className="user-panel-profile__menu-item-link-value">Режим невидимости</span>
            </button>
          </div>
        </div>
        { !currentUser.isPremium && currentUser.role >= 10 &&
          <React.Fragment>
            <div className="user-panel-profile__rise">
              <FormButton skin={ ['darkblue', 'size-s', 'size-m-tablet'] } onClick={ this.openModalPremium }>
                Стать Premium
              </FormButton>
            </div>
            <ModalGoPremium isOpen={ modal === MODAL_GO_PREMIUM } handleClose={ this.closeModal } />
          </React.Fragment>
        }
        { !currentUser.isStealth &&
          <ModalGoInvisible isOpen={ modal === MODAL_GO_INVISIBLE } handleClose={ this.closeModal } />
        }
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    badges: state.badges || {},
    currentUser: getUser(state, (state.session || {}).user),
    visibilitySwitchRequestState: state.states.visibilitySwitch || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    visibilitySwitch,
    hideNav,
  }, dispatch);
}

const UserPanelProfileConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPanelProfile);

export default props => (
  <LayoutContext.Consumer>
    { ({ location, history }) => <UserPanelProfileConnected { ...props } location={ location } history={ history } /> }
  </LayoutContext.Consumer>
);

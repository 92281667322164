import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import validator from 'validator';
import isEmpty from 'lodash/isEmpty';

import { startFormJob, finishFormJob } from 'common/actions';
import { isSSR, LS_KEYS, getLSKey, REGEX, normalizeValue } from 'common/helpers';

import Icon from 'components/Icon';

import './style.css';

const FormContext = React.createContext();

class Form extends Component {
  static propTypes = {
    id: PropTypes.string,
    entityIndex: PropTypes.number,
    entityId: PropTypes.number,
    entityKey: PropTypes.string,
    className: PropTypes.string,
    action: PropTypes.func.isRequired,
    actionState: PropTypes.object,
    autoSubmit: PropTypes.bool,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    doForceUpdate: PropTypes.bool,
  }

  static defaultProps = {
    autoSubmit: false,
  }

  constructor(props) {
    super(props);

    this.rootRef = React.createRef();

    this.handleAutoSubmit = this.handleAutoSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.autofocus = this.autofocus.bind(this);

    this.state = {
      isRequest: false,
      isTouched: false,
      showValidation: false,
      model: {},
      errors: {},
    };
  }

  componentDidMount() {
    this.createModel().then(this.autofocus);
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, actionState, autoSubmit, doForceUpdate } = this.props;

    if (actionState) {
      this.handleActionStateChange(prevProps);
    }

    if (id && id !== prevProps.id) {
      this.autosaveToStorage(prevProps, prevState);
      this.handleIdChange();
    }

    if (doForceUpdate && !prevProps.doForceUpdate) {
      this.createModel().then(() => {
        if (autoSubmit) {
          this.handleSubmit();
        }
      });
    }
  }

  createModel() {
    const inputs = this.rootRef.current.querySelectorAll('input, textarea, button.form-select__dropdown-option.-selected');
    const savedModel = this.restoreFromStorage();
    const model = {};

    return new Promise(resolve => {
      inputs.forEach(input => {
        const { name } = input;
        const value = normalizeValue(input.value);
        const defaultValue = input.dataset.defaultValue;

        if (name) {
          model[name] = savedModel[name] || value || defaultValue || '';
        }
      });

      this.setState({
        model,
      }, resolve);
    });
  }

  handleIdChange() {
    this.setState({
      showValidation: false,
      model: {},
      errors: {},
    }, () => {
      this.createModel().then(this.autofocus);
    });
  }

  handleActionStateChange(prevProps) {
    const { id, actionState, startFormJob, finishFormJob } = this.props;
    const prevActionState = (prevProps && prevProps.actionState) || {};

    // Handle successful form submit
    if (actionState.success && !prevActionState.success) {      
      this.clearOwnStorage();
      finishFormJob(id)

      return this.setState({
        isRequest: false,
        showValidation: false,
        model: {},
        errors: {},
      });
    }

    // Handle server-side validation
    if (actionState.error && !prevActionState.error) {
      finishFormJob(id);
    }

    // Handle request
    if (actionState.request !== prevActionState.request) {
      this.setState({
        isRequest: actionState.request,
      }, () => {
        if (actionState.request) {
          startFormJob(id);
        }
      });
    }
  }

  autofocus(onError = false) {
    if (!this.rootRef.current) {
      return;
    }

    const selector = onError
      ? '[aria-invalid="true"]'
      : '[data-autofocus]';
    const input = this.rootRef.current.querySelector(selector);

    if (input) {
      setTimeout(() => {
        input.focus();
      }, 200); // wait untill (possibly) parent transition ends
    }

    return null;
  }

  getAutosaveKey(id = this.props.id) {
    return getLSKey(LS_KEYS.AUTOSAVE, id);
  }

  autosaveToStorage(props = this.props, state = this.state) {
    const { id } = props;

    if (isSSR() || !id || !window.localStorage) {
      return;
    }

    const key = this.getAutosaveKey(id);
    const model = Object.assign({}, state.model);

    delete model.password;

    localStorage.setItem(key, JSON.stringify(model));
  }

  restoreFromStorage() {
    const { id } = this.props;

    if (isSSR() || !id || !window.localStorage) {
      return {};
    }

    const key = this.getAutosaveKey();
    const savedData = localStorage.getItem(key);

    if (!savedData) {
      return {};
    }

    return JSON.parse(savedData);
  }

  clearOwnStorage() {
    if (isSSR() || !window.localStorage) {
      return;
    }

    localStorage.removeItem(this.getAutosaveKey());
  }

  validateInput(validate, name) {
    const { model } = this.state;
    const validators = {
      youtubeVideo: {
        isEmpty: { error: 'Вставьте ссылку на YouTube-видео', assert: false },
        matches: {
          error: 'Вставьте корректную ссылку на YouTube-видео',
          assert: true,
          params: [REGEX.YOUTUBE],
        },
      },
      yandexMusic: {
        isEmpty: { error: 'Вставьте ссылку на Яндекс.Музыку', assert: false },
        matches: {
          error: 'Вставьте корректную ссылку на Яндекс.Музыку',
          assert: true,
          params: [REGEX.YANDEX_MUSIC],
        },
      },
      email: {
        isEmpty: { error: 'Укажите E-mail', assert: false },
        isEmail: { error: 'Введите корректный E-mail', assert: true },
      },
      password: {
        isEmpty: { error: 'Укажите пароль', assert: false },
      },
      passwordConfirm: {
        equals: {
          error: 'Пароли не совпадают!',
          assert: true,
          params: [String(model.password)],
        },
      },
      height: {
        allowEmpty: true,
        isInt: {
          error: 'Рост указан неверно',
          assert: true,
          params: [{
            min: 50,
            max: 299,
            allow_leading_zeroes: false,
          }],
        },
      },
      weight: {
        allowEmpty: true,
        isInt: {
          error: 'Вес указан неверно',
          assert: true,
          params: [{
            min: 20,
            max: 299,
            allow_leading_zeroes: false,
          }],
        },
      },
      required: {
        isEmpty: { error: 'Заполните поле', assert: false },
      },
    };

    if (!validate) {
      return '';
    }

    const rules = validate.split(',').reduce((result, rule) => Object.assign(result, validators[rule]), {});
    let error = '';

    // Не выводим ошибку, если поле пустое и ему разрешено быть пустым
    if (rules.allowEmpty && !model[name]) {
      return '';
    }

    // Если это поле не пустое, удаляем валидатор "allowEmpty",
    // т.к. он не является разрешённым валидатором в validator.js...
    delete rules.allowEmpty;

    // ...и перебираем все остальные правила подряд
    for (let rule in rules) {
      const ruleParams = rules[rule];
      const args = [String(model[name]), ...(ruleParams.params || [])];

      if (validator[rule](...args) !== ruleParams.assert) {
        error = ruleParams.error;
        break;
      }
    }

    return error;
  }

  validateForm() {
    const inputs = this.rootRef.current.querySelectorAll('input, textarea, button.form-select__dropdown-option.-selected');
    const errors = {};

    return new Promise((resolve, reject) => {
      inputs.forEach(input => {
        const { name, dataset: { validate } } = input;

        if (!!validate) {
          const error = this.validateInput(validate, name);

          if (error) {
            errors[name] = error;
          }
        }
      });

      this.setState({
        errors,
      }, () => {
        if (isEmpty(errors)) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  handleInput(e, callback) {
    const input = e.currentTarget;
    const { model } = this.state;
    const { onChange } = this.props;
    const value = normalizeValue(input.value);

    this.setState({
      isTouched: true,
      model: {
        ...model,
        [input.name]: value,
      },
    }, () => {
      this.autosaveToStorage();

      if (typeof onChange === 'function') {
        onChange(this.state.model, input.name);
      }

      if (typeof callback === 'function') {
        callback();
      }
    });
  }

  handleAutoSubmit(e) {
    const field = e.target.closest('input, textarea, button.form-select__dropdown-option');

    setTimeout(() => {
      const { autoSubmit } = this.props;
      const { isTouched } = this.state;

      if (!isTouched || !autoSubmit || !field) {
        return;
      }

      this.handleSubmit();
      this.setState({
        isTouched: false,
      });
    });
  }

  handleSubmit(e) {
    const { action } = this.props;
    const { model } = this.state;

    if (!!e) {
      e.preventDefault();
    }

    this.validateForm().then(() => {
      action(model);
    }).catch(() => {
      this.setState({
        showValidation: true,
      }, () => {
        this.autofocus(true);
      });
    });
  }

  render() {
    const { children, className, id, entityId, entityKey, entityIndex, onDelete } = this.props;

    const contextValue = Object.assign(
      { updateModel: this.handleInput },
      this.state,
    );

    return (
      <form
        id={ id }
        className={classNames(
          'form', 
          className,
          { '-with-delete': !!onDelete },
        )}
        onBlur={ this.handleAutoSubmit }
        onClick={ this.handleAutoSubmit }
        onSubmit={ this.handleSubmit }
        ref={ this.rootRef }
        noValidate
      >
        <FormContext.Provider value={ contextValue }>
          { children }
        </FormContext.Provider>
        { !!onDelete &&
          <button
            type="button"
            className="form__delete"
            onClick={ onDelete }
            data-entity-index={ entityIndex }
            data-entity-key={ entityKey }
            data-entity-id={ entityId }
          >
            <Icon glyph="cross-thin" />
          </button>
        }
      </form>
    );
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    startFormJob,
    finishFormJob,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(Form);
export {
  FormContext,
};

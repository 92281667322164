import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noScroll from 'no-scroll';

import modalManager from './manager';
import { isSSR } from 'common/helpers';
import { api } from 'common/middleware/api';

import ModalClose from 'components/ModalClose';

import './style.css';

class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    isHidden: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClickCross = this.handleClickCross.bind(this);
    this.handleClickOverlay = this.handleClickOverlay.bind(this);
  }

  componentDidMount() {
    if (this.props.isOpen) {
      this.handleOpen();
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      this.handleOpen();
    }

    if (prevProps.isOpen && !isOpen) {
      this.handleClose();
    }
  }

  componentWillUnmount() {
    if (this.props.isOpen) {
      this.handleClose();
    }
  }

  static getDerivedStateFromError() {
    return null;
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === 'production') {
      api.post('debug/error', {
        error: error + info.componentStack,
      });
    }
  }

  handleOpen() {
    noScroll.on();
    modalManager.add(this);
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  handleClose(completely = false) {
    const { handleClose } = this.props;

    if (!handleClose) {
      return;
    }

    modalManager.remove(this);
    document.removeEventListener('keydown', this.handleKeyDown, false);

    if (modalManager.modals().length === 0) {
      noScroll.off();
    }
    if (completely) {
      handleClose();
    }
  }

  handleClickCross() {
    this.handleClose(true);
  }

  handleClickOverlay(e) {
    if (e.target === e.currentTarget) {
      this.handleClose(true);
    }
  }

  handleKeyDown(e) {
    const keyCode = e.keyCode || e.charCode;

    if (keyCode !== 27 || !modalManager.isTopModal(this)) {
      return;
    }

    this.handleClose(true);
  }

  getPortalClassNames() {
    const childrenClassNames = this.props.children.props.className.split(' ');

    return childrenClassNames.map(className => className.search('modal-') === 0
      ? `${className}-portal`
      : className
    );
  }

  render() {
    const { children, isHidden, isOpen, handleClose } = this.props;

    if (isSSR() || !isOpen) {
      return null;
    }

    return createPortal(
      <div
        className={classNames(
          'modal',
          { '-hidden': isHidden },
          ...this.getPortalClassNames(),
        )}
        onClick={ this.handleClickOverlay }
      >
        <div className="modal__body">
          { !!handleClose &&
            <ModalClose onClick={ this.handleClickCross } />
          }
          { children }
        </div>
      </div>,
      document.body,
    );
  }
};

export default Modal;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ymaps from 'ymaps';
import axios from 'axios';

import { isSSR, withParams } from 'common/helpers';

import FormButton from 'components/FormButton';
import Preloader from 'components/Preloader';

import './style.css';
import moveImage from './images/move.png';

const API = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';

class WritePaneLocation extends Component {
  static propTypes = {
    onClose: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.mapRef = React.createRef();

    this.emitAddress = this.emitAddress.bind(this);
    this.loadYandexMaps = this.loadYandexMaps.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handlePlacemarkMove = this.handlePlacemarkMove.bind(this);

    this.state = {
      coords: [55.753215, 37.622504],
      address: 'Красная площадь, Москва, Россия',
      isError: false,
      mapsConstructor: null,
      map: null,
      placemark: null,
    };
  }

  componentDidMount() {
    this.loadYandexMaps();
  }

  componentDidUpdate(prevProps, prevState) {
    const { mapsConstructor } = this.state;

    if (mapsConstructor && !prevState.mapsConstructor) {
      this.initYandexMaps();
    }
  }

  loadYandexMaps() {
    const existingAPI = document.querySelector(`script[src="${API}"]`);

    if (existingAPI) {
      existingAPI.parentNode.removeChild(existingAPI);
    }

    setTimeout(() => {
      this.setState({
        isError: false,
      });

      ymaps.load(API).then(maps => {
        this.setState({
          mapsConstructor: maps,
        });
      }).catch(() => {
        this.setState({
          isError: true,
        });
      });
    });
  }

  initYandexMaps() {
    const { mapsConstructor, coords } = this.state;

    if (!mapsConstructor) {
      return this.setState({
        isError: true,
      });
    }

    this.setState({
      map: new mapsConstructor.Map(this.mapRef.current, {
        center: coords,
        zoom: 14,
        controls: ['zoomControl', 'geolocationControl'],
      }),
      placemark: new mapsConstructor.Placemark(coords, {}, {
        iconLayout: 'default#image',
        iconImageHref: moveImage,
        iconImageSize: [33, 32],
        iconImageOffset: [-16, -16],
        draggable: true,
      }),
    }, () => {
      this.state.placemark.events.add('dragend', this.handlePlacemarkMove);
      this.state.map.events.add('click', this.handleMapClick);
      this.state.map.geoObjects.add(this.state.placemark);

      this.getUserLocation();
    });
  }

  handlePlacemarkMove() {
    const coords = this.state.placemark.geometry.getCoordinates();

    this.geocode(coords);
    this.setState({
      coords,
    });
  }

  handleMapClick(e) {
    this.geocode(e.get('coords'), true);
  }

  geocode(query, shouldUpdatePoint = false) {
    let preparedQuery;

    if (Array.isArray(query)) {
      preparedQuery = query.slice(0).reverse().join(',');
    }
    if (typeof query === 'string') {
      preparedQuery = query.replace(/\s/g, '+');
    }

    if (!preparedQuery) {
      return;
    }

    const endpoint = withParams('https://geocode-maps.yandex.ru/1.x/', {
      geocode: preparedQuery,
      format: 'json',
      results: 1,
    });

    axios.get(endpoint, {
      transformRequest(data, headers) {
        delete headers.common.Authorization;
        delete headers['x-production'];

        return data;
      },
    }).then(({ data }) => {
      const result = data.response.GeoObjectCollection.featureMember;

      if (!result.length) {
        return;
      }

      const object = result[0].GeoObject;
      const coords = object.Point.pos.split(' ').reverse().map(c => parseFloat(c, 10));
      const address = [object.name, object.description].join(', ');

      this.setState({
        coords,
        address,
      }, () => {
        if (shouldUpdatePoint) {
          this.state.map.setCenter(coords);
          this.state.placemark.geometry.setCoordinates(coords);
        }
      });
    });
  }

  getUserLocation() {
    if (!isSSR() && 'geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        this.geocode([coords.latitude, coords.longitude], true);
      });
    }
  }

  emitAddress() {
    const { coords, address } = this.state;
    const { onChoose, onClose } = this.props;

    onChoose(`
      <a
        href="https://yandex.ru/maps/?text=${coords.join(',')}"
        class="geo-link"
        target="_blank"
        rel="noopener noreferrer"
        contenteditable="false"
      >
        <span>${address}</span>
      </a>
    `);

    onClose();
  }

  render() {
    const { isError } = this.state;
    const { onClose } = this.props;

    return (
      <div className="write-pane-location">
        <div className="write-pane-location__map" ref={ this.mapRef }>
          <div className="write-pane-location__map-preloader">
            { isError
              ? <p>Ошибка при загрузке карты. <button type="button" onClick={ this.loadYandexMaps }>Попробовать снова</button></p>
              : <Preloader />
            }
          </div>
        </div>
        <div className="write-pane-location__buttons">
          <FormButton type="button" skin={ ['darkblue', 'size-s'] } onClick={ this.emitAddress }>
            Отправить
          </FormButton>
          <FormButton type="button" skin={ ['lightblue', 'size-s'] } onClick={ onClose }>
            Отмена
          </FormButton>
        </div>
      </div>
    );
  }
}

export default WritePaneLocation;

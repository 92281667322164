import { LIST_LIMIT, generateTypes, withParams } from 'common/helpers';
import { CALL_API, Schemas } from 'common/middleware/api';

export const addUblog = (body, userId) => ({
  [CALL_API]: {
    body,
    payload: {
      userId,
    },
    types: generateTypes('UBLOG_ADD'),
    method: 'post',
    endpoint: 'ublogs/add',
    schema: Schemas.UBLOG,
  }
});

export const ublogEdit = (body, userId, postId) => ({
  [CALL_API]: {
    body,
    payload: {
      userId,
      postId,
    },
    types: generateTypes('UBLOG_EDIT'),
    method: 'post',
    endpoint: `ublog/${postId}/edit`,
    schema: Schemas.UBLOG,
  }
});

export const deleteUblog = (userId, postId) => ({
  [CALL_API]: {
    payload: {
      userId,
      postId,
    },
    types: generateTypes('UBLOG_DELETE'),
    method: 'post',
    endpoint: `ublog/${postId}/delete`,
  }
});

export const ublogPin = (userId, postId) => ({
  [CALL_API]: {
    payload: {
      userId,
      postId,
    },
    types: generateTypes('UBLOG_PIN'),
    method: 'post',
    endpoint: `ublog/${postId}/pin`,
  }
});

export const ublogLike = (userId, postId) => ({
  [CALL_API]: {
    payload: {
      userId,
      postId,
    },
    types: generateTypes('UBLOG_LIKE'),
    method: 'post',
    endpoint: `post/${postId}/like`,
  }
});

export const ublogUnpin = (userId, postId) => ({
  [CALL_API]: {
    payload: {
      userId,
      postId,
    },
    types: generateTypes('UBLOG_UNPIN'),
    method: 'post',
    endpoint: `ublog/${postId}/unpin`,
  }
});

const addRemoteUblogComment = (postId, tempId, comment) => ({
  [CALL_API]: {
    body: {
      comment,
    },
    payload: {
      tempId,
    },
    types: generateTypes('UBLOG_COMMENT_ADD_REMOTE'),
    method: 'post',
    endpoint: `ublog/${postId}/comment/add`,
    schema: Schemas.UBLOG_COMMENT,
  }
});

const addLocalUblogComment = (response) => ({
  response,
  type: 'UBLOG_COMMENT_ADD_LOCAL',
});

export const addUblogComment = (postId, comment, localResponse) => dispatch => {
  dispatch(addLocalUblogComment(localResponse));
  dispatch(addRemoteUblogComment(postId, localResponse.result, comment));
};

export const deleteUblogComment = (postId, commentId) => ({
  [CALL_API]: {
    payload: {
      postId,
      commentId,
    },
    types: generateTypes('UBLOG_COMMENT_DELETE'),
    method: 'post',
    endpoint: `ublog/${postId}/comment/${commentId}/delete`,
  }
});

export const getUblogTags = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('UBLOGS_TAGS'),
    method: 'get',
    endpoint: withParams('ublogs/tags', {
      page: params.page,
      limit: params.limit,
    }),
    schema: Schemas.TAGS,
  }
});

export const getUblogsByTag = (params = {}) => ({
  [CALL_API]: {
    payload: {
      tag: params.tag,
    },
    types: generateTypes('UBLOGS_BY_TAG'),
    method: 'get',
    endpoint: withParams('ublogs/tag', {
      tag: params.tag,
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.UBLOGS,
  }
});

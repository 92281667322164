import { generateTypes } from 'common/helpers';
import { CALL_API, Schemas } from 'common/middleware/api';

export const loadArticles = () => ({
  [CALL_API]: {
    types: generateTypes('ARTICLES'),
    method: 'get',
    endpoint: 'articles',
    schema: Schemas.ARTICLES,
  }
});

export const loadArticlePost = (id) => ({
  [CALL_API]: {
    types: generateTypes('ARTICLE_POST'),
    method: 'get',
    endpoint: `article/${id}`,
    schema: Schemas.ARTICLE,
  }
});

export const loadArticleComments = (id) => ({
  [CALL_API]: {
    types: generateTypes('ARTICLE_COMMENTS'),
    method: 'get',
    endpoint: `article/${id}/comments`,
    schema: Schemas.ARTICLE_COMMENTS,
  }
});

export const articleLike = (userId, postId) => ({
  [CALL_API]: {
    payload: {
      userId,
      postId,
    },
    types: generateTypes('ARTICLE_LIKE'),
    method: 'post',
    endpoint: `article/${postId}/like`,
  }
});

const articleCommentAddLocal = (response) => ({
  response,
  type: 'ARTICLE_COMMENT_ADD_LOCAL',
});

const articleCommentAddRemote = (postId, tempId, comment) => ({
  [CALL_API]: {
    body: {
      comment,
    },
    payload: {
      tempId,
    },
    types: generateTypes('ARTICLE_COMMENT_ADD_REMOTE'),
    method: 'post',
    endpoint: `article/${postId}/comment/add`,
    schema: Schemas.ARTICLE_COMMENT,
  }
});

export const articleCommentAdd = (postId, comment, localResponse) => dispatch => {
  dispatch(articleCommentAddLocal(localResponse));
  dispatch(articleCommentAddRemote(postId, localResponse.result, comment));
};

export const articleCommentDelete = (postId, commentId) => ({
  [CALL_API]: {
    payload: {
      postId,
      commentId,
    },
    types: generateTypes('ARTICLE_COMMENT_DELETE'),
    method: 'post',
    endpoint: `article/${postId}/comment/${commentId}/delete`,
  }
});

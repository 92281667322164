import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isSSR } from 'common/helpers';

import './style.css';

class Tooltip extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    onHide: PropTypes.func,
    at: PropTypes.oneOf(['top', 'bottom']),
  }

  static defaultProps = {
    at: 'top',
  }

  constructor(props) {
    super(props);
    this.createRootRef = this.createRootRef.bind(this);
    this.autoclose = this.autoclose.bind(this);
    this.closebyEscape = this.closebyEscape.bind(this);
  }

  componentDidUpdate(prevProps) {
    const wasActive = prevProps.isActive;
    const nowActive = this.props.isActive;

    if (isSSR() || wasActive === nowActive) {
      return;
    }

    setTimeout(() => {
      if (nowActive) {
        window.addEventListener('click', this.autoclose, false);
        window.addEventListener('keydown', this.closebyEscape, false);
      } else {
        window.removeEventListener('click', this.autoclose, false);
        window.removeEventListener('keydown', this.closebyEscape, false);
      }
    });
  }

  createRootRef(el) {
    this.rootRef = el;
  }

  autoclose(e) {
    const { onHide } = this.props;

    if (onHide && this.rootRef && !this.rootRef.contains(e.target)) {
      onHide();
    }
  }

  closebyEscape(e) {
    const { onHide } = this.props;
    const keyCode = e.keyCode || e.charCode;

    if (onHide && keyCode === 27) {
      onHide();
    }
  }

  render() {
    const { isActive, at, children } = this.props;

    return (
      <span
        className={classNames(
          'tooltip',
          `-at-${at}`,
          { '-hoverable': isActive === undefined },
          { '-active': isActive },
        )}
        ref={ this.createRootRef }
      >
        <span className="tooltip__arrow" />
        <span className="tooltip__body">
          { children }
        </span>
      </span>
    );
  }
};

export default Tooltip;

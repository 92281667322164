// Use this component outside of any Router
// https://github.com/ReactTraining/react-router/issues/4759

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isUserDisabled } from 'common/helpers';

import { LayoutContext } from 'document/Layout';

class Link extends Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    dangerouslySetInnerHTML: PropTypes.object,
    onClick: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const {
      to,
      onClick,
      layoutContext: {
        history,
        currentUser,
        closeModal,
      },
    } = this.props;

    if (isUserDisabled(currentUser)) {
      return e.preventDefault();
    }

    closeModal();

    if (typeof onClick === 'function') {
      onClick();
    }

    if (history) {
      e.preventDefault();
      history.push(to);
    }
  }

  render() {
    const { to, className, dangerouslySetInnerHTML, children } = this.props;

    return (
      <a
        href={ to }
        className={ className }
        onClick={ this.handleClick }
        dangerouslySetInnerHTML={ dangerouslySetInnerHTML }
      >
        { children }
      </a>
    );
  }
}

export default props => (
  <LayoutContext.Consumer>
    { layoutContext => <Link { ...props } layoutContext={ layoutContext || {} } /> }
  </LayoutContext.Consumer>
);

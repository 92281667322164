import React from 'react';
import { connect } from 'react-redux';

import ModalAuth from 'components/ModalAuth';
import ModalPay from 'components/ModalPay';

import { VIEW_SIGNUP } from 'components/Auth';

const ModalPayProxy = ({ isLoggedIn, ...props }) => {
  if (!isLoggedIn) {
    return <ModalAuth isOpen={ props.isOpen } handleClose={ props.handleClose } view={ VIEW_SIGNUP } />
  }

  return <ModalPay { ...props } />
};

function mapStateToProps(state) {
  const isAuthInProgress =
    state.workingForms.indexOf('form-auth-signin') > -1 ||
    state.workingForms.indexOf('form-auth-signup') > -1;

  return {
    isLoggedIn: state.session !== null && !isAuthInProgress,
  };
}

export default connect(
  mapStateToProps,
)(ModalPayProxy);

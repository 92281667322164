export * from './article';
export * from './auth';
export * from './blog';
export * from './forms';
export * from './gifts';
export * from './im';
export * from './loaders';
export * from './payment';
export * from './photos';
export * from './ublog';
export * from './user';
export * from './write';

export const hideNav = () => ({
  type: 'HIDE_NAV',
});

export const showNav = () => ({
  type: 'SHOW_NAV',
});

export const collapseAsideFooter = () => ({
  type: 'COLLAPSE_ASIDE_FOOTER',
});

export const expandAsideFooter = () => ({
  type: 'EXPAND_ASIDE_FOOTER',
});

export const openModalCooperation = () => ({
  type: 'MODAL_COOPERATION',
  payload: {},
});

export const openModalSupport = (entity = 'general', entityId) => ({
  type: 'MODAL_SUPPORT',
  payload: {
    entity,
    entityId,
  },
});

export const openModalAuth = (view) => ({
  type: 'MODAL_AUTH',
  payload: {
    view,
  },
});

export const openModalPay = (props) => ({
  type: 'MODAL_PAY',
  payload: {
    ...props,
  },
});

export const updateModalProps = (nextProps) => ({
  type: 'MODAL_UPDATE_PROPS',
  payload: {
    ...nextProps,
  },
});

export const closeModal = () => ({
  type: 'MODAL_CLOSE',
  payload: {},
});

export const updateLayoutSettings = (settings) => ({
  type: 'UPDATE_LAYOUT_SETTINGS',
  payload: Object.assign({ nav: false }, settings),
});

export const handlePacket = (cmd, data) => ({
  type: cmd,
  payload: data,
});

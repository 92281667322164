import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import noop from 'lodash/noop';

import { loadGiftsStore, openModalPay, closeModal } from 'common/actions';
import { getUser } from 'common/helpers';

import FormButton from 'components/FormButton';
import ModalGiftSend from 'components/ModalGiftSend';
import Scrollable from 'components/Scrollable';
import Tabs from 'components/Tabs';

import './style.css';

class WritePaneGifts extends Component {
  static propTypes = {
    recipientId: PropTypes.number,
    onClose: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.give = this.give.bind(this);
    this.cancelGiving = this.cancelGiving.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.modalCloseAll = this.modalCloseAll.bind(this);
    this.state = {
      selectedTab: '',
      selectedGift: 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.selectedTab && props.tabsData && props.tabsData[0]) {
      return {
        selectedTab: props.tabsData[0].value,
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.loadGiftsStore();
  }

  changeTab(newTab) {
    this.setState({
      selectedTab: newTab,
    });
  }

  give(e) {
    const { hasFreeGifts, recipientId, openModalPay } = this.props;
    const selectedGift = parseInt(e.currentTarget.dataset.id, 10);

    this.setState({
      selectedGift,
    }, () => {
      if (!hasFreeGifts) {
        openModalPay({
          recipientId,
          entityId: selectedGift,
          handleClose: this.modalCloseAll,
          mode: 'gift',
        });
      }
    });
  }

  cancelGiving(callback = noop) {
    this.setState({
      selectedGift: 0,
    }, callback);
  }

  modalCloseAll() {
    this.cancelGiving(() => {
      this.props.closeModal();
    });
  }

  render() {
    const { tabsData, showcase, gifts, recipientId, onClose } = this.props;
    const { selectedTab, selectedGift } = this.state;
    const data = showcase[selectedTab] || [];

    return (
      <div className="write-pane-gifts">
        <div className="write-pane-gifts__tabs">
          <Tabs
            className="-link -size-m"
            selected={ selectedTab }
            items={ tabsData }
            onChange={ this.changeTab }
          />
        </div>
        <Scrollable>
          <div className="write-pane-gifts__list">
            {
              data.map(gift =>
                <div className="write-pane-gifts__gift" onClick={ this.give } data-id={ gift } key={ gift }>
                  <figure>
                    <img src={ gifts[gift].picture } alt={ gifts[gift].name } />
                  </figure>
                  <div className="write-pane-gifts__gift-give">
                    <div className="write-pane-gifts__gift-give-price">{ gifts[gift].cost } руб.</div>
                  </div>
                </div>
              )
            }
          </div>
        </Scrollable>
        <div className="write-pane-gifts__cancel">
          <FormButton type="submit" skin={ ['lightblue', 'size-s'] } onClick={ onClose }>
            Отмена
          </FormButton>
        </div>
        { !!selectedGift &&
          <ModalGiftSend
            recipientId={ recipientId }
            giftId={ selectedGift }
            handleClose={ this.cancelGiving }
            handleCloseAll={ this.modalCloseAll }
            isOpen
          />
        }
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { recipientId } = ownProps;
  const {
    data: {
      giftsStore,
    },
    entities: {
      gifts,
      users,
      usersCompact,
    },
  } = state;
  const user = (usersCompact || {})[recipientId] || (users || {})[recipientId] || {};
  const tabsData = (giftsStore || []).map(category => ({
    label: category.name,
    value: `${category.id}`,
  }));
  const showcase = (giftsStore || []).slice(0).reduce((map, obj) => {
    map[obj.id] = obj.items;
    return map;
  }, {});

  const currentUser = getUser(state, (state.session || {}).user);
  const hasFreeGifts = currentUser.gifts && currentUser.gifts.freeGifts > 0;

  return {
    tabsData,
    user,
    showcase,
    gifts,
    hasFreeGifts,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadGiftsStore,
    openModalPay,
    closeModal,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WritePaneGifts);

import React from 'react';
import PropTypes from 'prop-types';

import Form from 'components/Form';
import FormInput from 'components/FormInput';
import FormError from 'components/FormError';
import FormButton from 'components/FormButton';
import ModalSimple from 'components/ModalSimple';

import './style.css';

const ModalPasswordForgot = ({ onSubmit, isOpen, handleClose, actionState }) => {
  return (
    <ModalSimple isOpen={ isOpen } handleClose={ handleClose }>
      <h2>Восстановить пароль</h2>
      <Form id="form-password-forgot" action={ onSubmit } actionState={ actionState } className='forgot-form'>
        <FormInput
          placeholder="E-mail"
          type="email"
          name="email"
          autofocus
        />
        {actionState && actionState.error && actionState.error.message && (
          <FormError>
            {actionState.error.message}
          </FormError>
        )}
        <FormButton type="submit" skin={ ['darkblue', 'size-m'] }>
          Восстановить
        </FormButton>
      </Form>
    </ModalSimple>
  );
};

ModalPasswordForgot.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actionState: PropTypes.object.isRequired,
};

export default ModalPasswordForgot;

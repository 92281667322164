import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { getUser, makeSearchLink } from 'common/helpers';
import { logout } from 'common/actions';

import { LayoutContext } from 'document/Layout';

import Icon from 'components/Icon';
import Link from 'components/Link';

import './style.css';

const Menu = ({ session, currentUser, top100Tab, history, logout }) => {
  const DATA = [
    { to: '/welcome', icon: 'home', anchor: session ? 'Люди дня' : 'Знакомства' },
    { to: makeSearchLink(currentUser), icon: 'search', anchor: 'Поиск' },
    { to: '/like-you', icon: 'hearts', anchor: 'LikeYou', hidden: !session },
    { to: '/ublogs', icon: 'blogs', anchor: 'Блоги' },
    { to: `/top100?tab=${top100Tab}`, icon: 'cup', anchor: 'Лучшие-100' },
    { to: '/about', icon: 'linkyou', anchor: 'О нас' },
    { to: '/blog', icon: 'write', anchor: 'Наш блог' },
  ];

  const doLogout = () => {
    logout(currentUser.id);
    history.push('/');
  }

  return (
    <div className="menu">
      {
        DATA.map((item, key) => {
          if (item.hidden) {
            return null;
          }

          return (
            <div className="menu__item" key={ item.icon }>
              <Link className="menu__link" to={ item.to }>
                { item.icon &&
                  <span className="menu__link-icon">
                    <Icon glyph={ item.icon } />
                  </span>
                }
                <span className="menu__link-value">{ item.anchor }</span>
                { item.arrow &&
                  <Icon glyph="arrow-menu-right" />
                }
              </Link>
            </div>
          );
        })
      }
      <div className={classNames(
        'menu__item',
        { '-hidden': !session },
      )}>
        <button className="menu__link" onClick={ doLogout }>
          <span className="menu__link-icon">
            <Icon glyph="logout" />
          </span>
          <span className="menu__link-value">Выход</span>
        </button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const currentUser = getUser(state, (state.session || {}).user);

  return {
    currentUser,
    session: state.session,
    top100Tab: (currentUser.gender || {}).code === 'female' ? 'men' : 'women',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    logout,
  }, dispatch);
}

const MenuConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu);

export default props => (
  <LayoutContext.Consumer>
    { layoutContext => <MenuConnected { ...props } history={ layoutContext.history } /> }
  </LayoutContext.Consumer>
);

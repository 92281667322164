import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isSSR } from 'common/helpers';

import Modal from 'components/Modal';
import ModalAlert from 'components/ModalAlert';
import Form from 'components/Form';
import FormButton from 'components/FormButton';
import FormError from 'components/FormError';

import './style.css';

class ModalTicket extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    actionState: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
    this.state = {
      isSuccessModalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      handleClose,
      actionState: {
        success,
      },
    } = this.props;
    const {
      actionState: {
        success: prevSuccess,
      },
    } = prevProps;

    if (success && !prevSuccess && !isSSR()) {
      setTimeout(() => {
        handleClose();
        this.openSuccessModal();
      });
    }
  }

  openSuccessModal() {
    this.setState({
      isSuccessModalOpen: true,
    });
  }

  closeSuccessModal() {
    this.setState({
      isSuccessModalOpen: false,
    });
  }

  render() {
    const { isOpen, handleClose, formId, title, action, actionState, children } = this.props;
    const { isSuccessModalOpen } = this.state;

    return (
      <React.Fragment>
        <Modal isOpen={ isOpen } handleClose={ handleClose }>
          <div className="modal-ticket">
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <Form id={ formId } action={ action } actionState={ actionState }>
              { children }
              { !!actionState.error && !!actionState.error.message &&
                <FormError>
                  { actionState.error.message }
                </FormError>
              }
              <FormButton type="submit" skin={ ['darkblue', 'size-m'] }>
                Отправить<span className="hidden-b-m"> сообщение</span>
              </FormButton>
            </Form>
          </div>
        </Modal>
        <ModalAlert isOpen={ isSuccessModalOpen } handleClose={ this.closeSuccessModal } withLogo>
          <p>Ваше обращение в&nbsp;техподдержку успешно отправлено. <br className="hidden-b-m" /> Мы&nbsp;рассмотрим его в&nbsp;ближайшее время.</p>
        </ModalAlert>
      </React.Fragment>
    );
  }
};

export default ModalTicket;

import { generateTypes } from 'common/helpers';
import { CALL_API, Schemas } from 'common/middleware/api';

export const loadUserGifts = (userId) => ({
  [CALL_API]: {
    types: generateTypes('USER_GIFTS'),
    method: 'get',
    endpoint: `gifts/${userId}`,
    schema: Schemas.USER_GIFTS,
    payload: {
      userId,
    },
  }
});

export const loadGiftsRecommended = (userId) => ({
  [CALL_API]: {
    types: generateTypes('GIFTS_RECOMMENDED'),
    method: 'get',
    endpoint: `gifts/${userId}/recommended`,
    schema: Schemas.USER_GIFTS_RECOMMENDED,
    payload: {
      userId,
    },
  }
});

export const loadGiftsStore = () => ({
  [CALL_API]: {
    types: generateTypes('GIFTS_STORE'),
    method: 'get',
    endpoint: 'gifts',
    schema: Schemas.GIFTS_STORE,
  }
});

export const loadNewGifts = () => ({
  [CALL_API]: {
    types: generateTypes('NEW_GIFTS'),
    method: 'get',
    endpoint: 'gifts/new',
    schema: Schemas.USER_GIFTS,
  }
});

export const loadGiftsPaid = () => ({
  [CALL_API]: {
    types: generateTypes('GIFTS_PAID'),
    method: 'get',
    endpoint: 'payment/list/gift',
  }
});

export const addGift = (recipientId, body) => ({
  [CALL_API]: {
    body,
    types: generateTypes('ADD_GIFT'),
    method: 'post',
    endpoint: `gifts/${recipientId}/add`,
  }
});

export const deleteGift = (giftId) => ({
  [CALL_API]: {
    types: generateTypes('DELETE_GIFT'),
    method: 'post',
    endpoint: 'gifts/delete',
    body: {
      id: giftId,
    },
  }
});

export const restoreGift = (giftId) => ({
  [CALL_API]: {
    types: generateTypes('RESTORE_GIFT'),
    method: 'post',
    endpoint: 'gifts/recovery',
    body: {
      id: giftId,
    },
  }
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import qs from 'query-string';

import { isSSR, getLSKey, LS_KEYS } from 'common/helpers';
import { authPasswordForgot, authPasswordChange, signIn, signUp, closeModal } from 'common/actions';

import { LayoutContext } from 'document/Layout';

import Link from 'components/Link';
import Tabs from 'components/Tabs';
import Icon from 'components/Icon';
import Form from 'components/Form';
import FormInput from 'components/FormInput';
import FormButton from 'components/FormButton';
import FormError from 'components/FormError';
import ModalPasswordForgot from 'components/ModalPasswordForgot';
import ModalPasswordReset from 'components/ModalPasswordReset';
import ModalPasswordUpdate from 'components/ModalPasswordUpdate';
import ModalSuccess from 'components/ModalSuccess';

import './style.css';

const MODAL_FORGOT = 'MODAL_FORGOT';
const MODAL_RESET = 'MODAL_RESET';
const MODAL_UPDATE = 'MODAL_UPDATE';
const MODAL_SUCCESS = 'MODAL_SUCCESS';
export const VIEW_SIGNIN = 'VIEW_SIGNIN';
export const VIEW_SIGNUP = 'VIEW_SIGNUP';

class Auth extends Component {
  static propTypes = {
    huge: PropTypes.bool,
  }

  static defaultProps = {
    huge: false,
  }

  constructor(props) {
    super(props);
    this.clearOtherFormStorage = this.clearOtherFormStorage.bind(this);
    this.updateView = this.updateView.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModalForgot = this.openModalForgot.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      view: props.defaultView || VIEW_SIGNIN,
      formId: '',
      modalOpen: '',
      forgottenPasswordEmail: '',
      recoveryCode: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    const formId = `form-auth-${state.view.split('_').pop().toLowerCase()}`;

    if (state.formId === formId) {
      return null;
    }

    return {
      formId,
    };
  }

  componentDidMount() {
    if (isSSR()) {
      return;
    }

    const search = qs.parse(this.props.layoutContext.location.search);

    if (search.check_code) {
      this.openModal(MODAL_UPDATE, {
        recoveryCode: search.check_code,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { session, actionStates, closeModal, layoutContext: { history } } = this.props;
    const isSignUpCompleted =
      (actionStates[VIEW_SIGNUP] || {}).success &&
      (prevProps.actionStates[VIEW_SIGNUP] || {}).request;
    const isSignInCompleted =
      (actionStates[VIEW_SIGNIN] || {}).success &&
      (prevProps.actionStates[VIEW_SIGNIN] || {}).request;

    if (isSignInCompleted || isSignUpCompleted) {
      closeModal();
    }

    if (isSignUpCompleted) {
      history.push('/edit');
    }

    // Уведомление о том, что ссылка с кодом восстановления отправлена
    if (actionStates.forgot.success && !prevProps.actionStates.forgot.success) {
      this.openModal(MODAL_RESET);
    }

    // Уведомление о том, что пароль изменён
    if (actionStates.change.success && !prevProps.actionStates.change.success) {
      this.openModal(MODAL_SUCCESS);
    }
  }

  updateView(view) {
    this.setState({
      view,
    });
  }

  handleSubmit(model) {
    const { signIn, signUp } = this.props;
    const { view } = this.state;

    switch (view) {
      case VIEW_SIGNIN:
        return signIn(model).then(this.clearOtherFormStorage);
      case VIEW_SIGNUP:
        return signUp(model).then(this.clearOtherFormStorage);
      default:
        break;
    }
  }

  clearOtherFormStorage() {
    if (isSSR() || !window.localStorage) {
      return;
    }

    const otherId = `form-auth-${this.state.view === VIEW_SIGNIN ? 'signup' : 'signin'}`;
    const otherKey = getLSKey(LS_KEYS.AUTOSAVE, otherId);

    localStorage.removeItem(otherKey);
  }

  openModal(type, payload = {}) {
    this.setState({
      ...payload,
      modalOpen: type,
    });
  }

  openModalForgot() {
    this.openModal(MODAL_FORGOT);
  }

  closeModal() {
    this.setState({
      modalOpen: '',
    });
  }

  render() {
    const { actionStates, huge, authPasswordForgot, authPasswordChange } = this.props;
    const { view, formId, modalOpen, forgottenPasswordEmail, recoveryCode } = this.state;
    const actionState = actionStates[view];

    return (
      <div className={ `auth${huge ? ' -huge' : ' -normal'}` }>
        <Tabs
          className="-size-m"
          onChange={ this.updateView }
          selected={ view }
          items={[
            { label: 'Вход', value: VIEW_SIGNIN },
            { label: 'Регистрация', value: VIEW_SIGNUP },
          ]}
        />
        <Form
          id={ formId }
          className="auth__form"
          action={ this.handleSubmit }
          actionState={ actionState }
        >
          <FormInput
            placeholder="E-mail"
            type="email"
            name="login"
            validate="email"
            autofocus
          />
          <FormInput
            placeholder="Пароль"
            type="password"
            name="password"
            validate="password"
          />
          { actionState && actionState.error && actionState.error.message
            ? <FormError>
                { actionState.error.message }
              </FormError>
            : null
          }
          <FormButton type="submit" skin={ ['darkblue', 'size-m'] }>
            { view === VIEW_SIGNUP ? 'Зарегистрироваться' : 'Войти' }
          </FormButton>
        </Form>
        { view === VIEW_SIGNUP
          ? <div className="auth__terms">
              Регистрируясь на&nbsp;сайте вы&nbsp;соглашаетесь с&nbsp;<Link to="/rules">условиями использования</Link> и&nbsp;<Link to="/privacy">политикой безопасности</Link>
            </div>
          : <div className="auth__lostpass">
              <button type="button" onClick={ this.openModalForgot }>Забыли пароль?</button>
            </div>
        }
        {/*<div className="auth__social">
          <div className="auth__social-heading">Вход через соцсети</div>
          <div className="auth__social-list">
            <a href="/social/fb" className="auth__social-list-item">
              <Icon glyph="social-fb" />
            </a>
            <a href="/social/vk" className="auth__social-list-item">
              <Icon glyph="social-vk" />
            </a>
            <a href="/social/ok" className="auth__social-list-item">
              <Icon glyph="social-ok" />
            </a>
            <a href="/social/mm" className="auth__social-list-item">
              <Icon glyph="social-mm" />
            </a>
          </div>
          <div className="auth__social-tip">Мы&nbsp;не&nbsp;делаем публикации в&nbsp;ваш аккаунт</div>
        </div>*/}
        <ModalPasswordForgot isOpen={ modalOpen === MODAL_FORGOT } handleClose={ this.closeModal } onSubmit={ authPasswordForgot } actionState={ actionStates.forgot } />
        <ModalPasswordReset isOpen={ modalOpen === MODAL_RESET } handleClose={ this.closeModal } email={ forgottenPasswordEmail } />
        <ModalPasswordUpdate isOpen={ modalOpen === MODAL_UPDATE } handleClose={ this.closeModal } onSubmit={ authPasswordChange } actionState={ actionStates.change } recoveryCode={ recoveryCode } />
        <ModalSuccess isOpen={ modalOpen === MODAL_SUCCESS } handleClose={ this.closeModal } headline="Пароль изменён!" />
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    session: state.session,
    actionStates: {
      [VIEW_SIGNIN]: state.states.signIn,
      [VIEW_SIGNUP]: state.states.signUp,
      forgot: state.states.authPasswordForgot || {},
      change: state.states.authPasswordChange || {},
    },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    authPasswordChange,
    authPasswordForgot,
    closeModal,
    signIn,
    signUp,
  }, dispatch);
}

const Consumer = props => (
  <LayoutContext.Consumer>
    { layoutContext => <Auth { ...props } layoutContext={ layoutContext || {} } /> }
  </LayoutContext.Consumer>
);
export default connect(mapStateToProps, mapDispatchToProps)(Consumer);

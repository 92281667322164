import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Priceline = ({ data, selected, selectOption }) => {
  const ids = Object.keys(data).map(i => parseInt(i, 10));
  const progress = 100 / (ids.length - 1) * ids.indexOf(selected);

  return (
    <div className="priceline">
      <div className="priceline__progress">
        <div className="priceline__progress-bar" style={{ width: `${progress}%`, height: `${progress}%` }} />
      </div>
      <div className="priceline__options">
        {
          ids.map(id => (
            <label className="priceline__item" key={ id }>
              <input
                type="radio"
                name="entityId"
                value={ id }
                checked={ id === selected }
                onChange={ selectOption }
              />
              <div className="priceline__item-control" />
              <div className="priceline__item-title">
                <div className="priceline__item-option">{ data[id].option }</div>
                <div className="priceline__item-price">{ data[id].price }</div>
              </div>
            </label>
          ))
        }
      </div>
    </div>
  );
};

Priceline.propTypes = {
  data: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired,
  selectOption: PropTypes.func.isRequired,
};

export default Priceline;

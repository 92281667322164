import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

try {
  const files = require.context('!svg-sprite-loader!svgo-loader?{"plugins":[{"removeTitle":true},{"cleanupAttrs":true},{"removeAttrs":{"attrs":"(fill|stroke|opacity)"}}]}!./images', false, /.*\.svg$/);
  files.keys().forEach(files);
} catch (err) {}

const Icon = ({ glyph, className }) => (
  <span className={ `ico -${glyph}` }>
    <svg>
      <use xlinkHref={ `#${ glyph }` }></use>
    </svg>
  </span>
);

Icon.propTypes = {
  glyph: PropTypes.string.isRequired,
};

export default Icon;

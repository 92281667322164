import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUser } from 'common/helpers';
import { authEmailConfirm } from 'common/actions';

import Form from 'components/Form';
import FormButton from 'components/FormButton';
import FormError from 'components/FormError';
import FormInput from 'components/FormInput';
import ModalSimple from 'components/ModalSimple';

class ModalEmail extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    allowChange: PropTypes.bool,
    emailOverwrite: PropTypes.string,
  }

  static defaultProps = {
    allowChange: false,
  }

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = {
      isEmailChange: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { actionState } = this.props;

    if (actionState.success && !prevProps.actionState.success) {
      this.setState({
        isEmailChange: false,
      });
    }
  }

  handleEmailChange() {
    this.setState({
      isEmailChange: true,
    });
  }

  handleClose() {
    this.setState({
      isEmailChange: false,
    }, this.props.handleClose);
  }

  handleFormSubmit(body) {
    const { currentUser, authEmailConfirm } = this.props;

    authEmailConfirm(body, currentUser.id);
  }

  render() {
    const { isOpen, currentUser, actionState, allowChange, emailOverwrite } = this.props;
    const { isEmailChange } = this.state;

    if (!isOpen || !currentUser.id) {
      return null;
    }

    return (
      <ModalSimple isOpen={ isOpen } handleClose={ this.props.handleClose ? this.handleClose : null }>
        { isEmailChange && allowChange
          ? <React.Fragment>
              <h2>Подтверждение регистрации</h2>
              <p>Укажите Ваш E-mail для подтверждения регистрации</p>
              <Form
                id="form-email-change"
                action={ this.handleFormSubmit }
                actionState={ actionState }
              >
                <FormInput
                  placeholder="Введите ваш Email"
                  type="email"
                  name="email"
                  autofocus
                />
                <FormButton type="submit" skin={ ['darkblue', 'size-m'] }>
                  Сохранить
                </FormButton>
                { !!actionState.error && !!actionState.error.message &&
                  <FormError>
                    { actionState.error.message }
                  </FormError>
                }
              </Form>
            </React.Fragment>
          : <React.Fragment>
              <h2>Подтвердите ваш Email</h2>
              <p>Подтвердите регистрацию, нажав кнопку в письме, которое мы отправили вам на почту <b>{ emailOverwrite || currentUser.email }</b></p>
              { !!this.props.handleClose &&
                <FormButton type="submit" skin={ ['darkblue', 'size-m'] } onClick={ this.handleClose }>
                  OK
                </FormButton>
              }
              { allowChange &&
                <footer>
                  <button type="button" onClick={ this.handleEmailChange }>← Ввести другой E-mail</button>
                </footer>
              }
            </React.Fragment>
        }
      </ModalSimple>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: getUser(state, (state.session || {}).user),
    actionState: state.states.authEmailConfirm || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    authEmailConfirm,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEmail);

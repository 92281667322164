import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';

import Link from 'components/Link';
import Notification from 'components/Notification';
import Scrollable from 'components/Scrollable';

import './style.css';

const FADE_OFFSET = 50;

class Tabs extends Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    selected: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      notification: PropTypes.number,
      to: PropTypes.string,
    })).isRequired,
  }

  static defaultProps = {
    onClick: noop,
  }

  constructor(props) {
    super(props);
    this.createRootRef = this.createRootRef.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.state = {
      faderLeftOpacity: 0,
      faderRightOpacity: 1,
      initialScrollOffset: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.items.length && this.props.items.length) {
      this.scrollToSelected();
    }
  }

  createRootRef(el) {
    this.rootRef = el;
  }

  scrollToSelected() {
    if (!this.rootRef) {
      return;
    }

    const corrector = parseInt(window.getComputedStyle(this.rootRef.firstElementChild).paddingLeft, 10);
    const selectedTab = this.rootRef.querySelector('.tabs__item.-active');
    const selectedTabOffset = selectedTab.offsetLeft - corrector;
    const gutterWidth = (this.rootRef.clientWidth - selectedTab.clientWidth) / 2;
    const initialScrollOffset = selectedTabOffset - gutterWidth;

    this.setState({
      initialScrollOffset,
    });
  }

  onScroll(params) {
    const faderLeftOpacity = Math.min(params.x.scrolled, FADE_OFFSET) / FADE_OFFSET;
    const faderRightOpacity = Math.min(params.x.remaining, FADE_OFFSET) / FADE_OFFSET;

    this.setState({
      faderLeftOpacity,
      faderRightOpacity,
    });
  }

  renderNotification(item) {
    if ('notification' in item) {
      return <Notification value={ item.notification } />;
    }

    return null;
  }

  render() {
    const { items, selected, onChange, className, onClick } = this.props;
    const { faderLeftOpacity, faderRightOpacity, initialScrollOffset } = this.state;

    return (
      <div className={ classNames('tabs', className ) } ref={ this.createRootRef }>
        <Scrollable x={ true } y={ false } initialScroll={{ x: initialScrollOffset }} onScroll={ this.onScroll }>
          {
            items.map((item, key) => (
              <div
                className={classNames(
                  'tabs__item',
                  { '-active': selected === item.value },
                )}
                key={ key }
              >
                { onChange
                  ? <button
                      type="button"
                      className="tabs__item-button"
                      onClick={ () => onChange(item.value) && onClick() }
                    >
                      { item.label }
                      { this.renderNotification(item) }
                    </button>
                  : <Link
                      to={ item.to }
                      className="tabs__item-button"
                      onClick={ onClick }
                    >
                      { item.label }
                      { this.renderNotification(item) }
                    </Link>
                }
              </div>
            ))
          }
          <div className="flex-spacer" />
        </Scrollable>
        <div className="tabs__fader -left" style={{ opacity: faderLeftOpacity }} />
        <div className="tabs__fader -right" style={{ opacity: faderRightOpacity }} />
      </div>
    );
  }
};

export default Tabs;

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openModalCooperation, openModalSupport } from 'common/actions';

import Share from 'components/Share';
import Link from 'components/Link';

import './style.css';

const Footer = ({
  openModalCooperation,
  openModalSupport,
  isLoggedIn,
  isAMP,
}) => (
  <footer className="footer">
    <div className="footer__item">
      © {new Date().getFullYear()} <Link to="/welcome">LinkYou</Link>
    </div>
    <div className="footer__item">
      <a href="https://www.admitad.ru/store/offers/linkyou/" target="_blank">
        Партнерская программа
      </a>
    </div>
    <div className="footer__item">
      <Link to="/rules">Правила</Link>
    </div>
    {isLoggedIn && (
      <div className="footer__item">
        <button type="button" onClick={() => openModalSupport()}>
          Техподдержка
        </button>
      </div>
    )}
    <div className="footer__item">
      <Link to="/privacy">Конфиденциальность</Link>
    </div>
    <div className="footer__item">
      <button type="button" onClick={openModalCooperation}>
        Сотрудничество
      </button>
    </div>
    <div className="footer__item">
      <Link to="/articles">Статьи</Link>
    </div>
    {!isAMP && (
      <div className="footer__share">
        <Share skin="footer" />
      </div>
    )}
  </footer>
);

function mapStateToProps(state) {
  return {
    isLoggedIn: !!state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModalCooperation,
    openModalSupport,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Footer);

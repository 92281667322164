import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import moment from 'moment';
import qs from 'query-string';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import { camelizeKeys } from 'humps';

import { selectSympathiesNew, selectGiftsPaid } from './selectors';
import { isSSR, getUser, getUserScore } from 'common/helpers';
import {
  authConfirm,
  authEmailCode,
  clearSympathiesNew,
  earnFreeGifts,
  getBadges,
  loadCurrentUser,
  loadGiftsPaid,
  loadNewGifts,
  loadSympathiesNew,
  loadUserCompact,
  paymentBurndown,
  paymentList,
  paymentListFee,
} from 'common/actions';

import ModalAlert from 'components/ModalAlert';
import ModalEmail from 'components/ModalEmail';
import ModalEntryFee from 'components/ModalEntryFee';
import ModalGift from 'components/ModalGift';
import ModalGiftSend from 'components/ModalGiftSend';
import ModalSuccess from 'components/ModalSuccess';
import ModalSympathies from 'components/ModalSympathies';

const MODAL_ALERT = 'MODAL_ALERT';
const MODAL_BANNED = 'MODAL_BANNED';
const MODAL_EMAIL = 'MODAL_EMAIL';
const MODAL_EMAIL_CONFIRM = 'MODAL_EMAIL_CONFIRM';
const MODAL_ENTRY_FEE = 'MODAL_ENTRY_FEE';
const MODAL_ERROR = 'MODAL_ERROR';
const MODAL_FREE_GIFTS = 'MODAL_FREE_GIFTS';
const MODAL_GIFT_NEW = 'MODAL_GIFT_NEW';
const MODAL_GIFT_SEND = 'MODAL_GIFT_SEND';
const MODAL_SYMPATHIES = 'MODAL_SYMPATHIES';

const ALLOWED_EMAIL_CODES = [
  { key: 'emailConfirmationCodeState', state: 'isEmailConfirmationRequested'},
  { key: 'emailChangeCodeState', state: 'isEmailChangeRequested'},
];

class Notifier extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  static defaultProps = {
    location: {},
  }

  get servicePaid() {
    const payments = this.props.payment.slice(0);
    const feePaymentIndex = payments.indexOf('fee');

    return payments[feePaymentIndex] || payments.shift();
  }

  constructor(props) {
    super(props);

    this.modalClose = this.modalClose.bind(this);
    this.modalCloseSympathies = this.modalCloseSympathies.bind(this);
    this.handleEmailConfirmed = this.handleEmailConfirmed.bind(this);

    this.isDataFetched = false;
    this.isEmailChangeRequested = false;
    this.isEmailConfirmationRequested = false;
    this.isFreeGiftsRequested = false;

    this.state = {
      modalOpen: '',
      errorText: '',
      emailConfirmationCode: '',
      emailChangeCode: '',
      gift: {
        id: 0,
        recipient: 0,
      },
    };
  }

  get shouldWork() {
    return !!this.props.currentUser.id && !this.props.isPaymentPage;
  }

  static getDerivedStateFromProps({ currentUser, location }, state) {
    // Работаем только если есть код подтверждения почты.
    // Лёгкая проверка, дабы не гонять qs.parse при каждом рендере.
    if (!location.search || location.search.search('email_') === -1) {
      return null;
    }

    const search = camelizeKeys(qs.parse(location.search));
    const searchKeys = Object.keys(search);
    const keysToDerive = ['emailConfirmationCode', 'emailChangeCode'];

    for (const key of searchKeys) {
      if (keysToDerive.indexOf(key) === -1) {
        continue;
      }

      if (state[key] === '') {
        return {
          [key]: search[key],
        };
      }
    }

    return null;
  }

  componentDidMount() {
    if (!this.shouldWork) {
      return;
    }

    this.runNotifier();
  }

  async componentDidUpdate(prevProps) {
    if (this.isDataFetched && isEmpty(this.props.currentUser)) {
      this.isDataFetched = false;
    }

    if (!this.shouldWork) {
      return;
    }

    const {
      currentUser,
      location,
      payment,
      userSympathiesNew,
      requestStateGiftsPaid,
      requestStateNewGifts,
      newGift,
      newGiftsCount,
      loadNewGifts,
    } = this.props;
    const {
      modalOpen,
    } = this.state;

    const isLocationChanged = location.key !== prevProps.location.key;
    const isRoleChanged = currentUser.roleStatus !== prevProps.currentUser.roleStatus;
    const isPaidGiftsFetched = requestStateGiftsPaid.success && prevProps.requestStateGiftsPaid.request;
    const isGiftsNewGiven = newGift.timestamp !== prevProps.newGift.timestamp;
    const isGiftsNewReceived = requestStateNewGifts.success && prevProps.requestStateNewGifts.request && newGiftsCount > 0;

    if (
      isLocationChanged &&
      !modalOpen &&
      !payment.length &&
      currentUser.role >= 10 &&
      currentUser.gifts && currentUser.gifts.gotFreeGifts === false &&
      !this.isFreeGiftsRequested &&
      getUserScore(currentUser) === 100
    ) {
      return this.earnFreeGifts();
    }

    if (isLocationChanged || isRoleChanged) {
      this.runNotifier();
    }

    ALLOWED_EMAIL_CODES.forEach(({ key, state }) => {
      if (prevProps[key].request) {
        if (this.props[key].success) {
          return this.modalOpenEmailConfirm();
        }
        if (this.props[key].error) {
          this[state] = false;
          return this.setState({
            errorText: `Не удалось подтвердить почту. ${this.props[key].error.message}`,
          }, this.modalOpenError);
        }
      }
    });

    if (payment.length && (!prevProps.payment.length || isPaidGiftsFetched || isLocationChanged)) {
      return this.handlePayment();
    }

    if (isGiftsNewGiven) {
      return loadNewGifts();
    }

    if (isGiftsNewReceived) {
      return this.modalOpenGiftNew();
    }

    if (userSympathiesNew.length && !prevProps.userSympathiesNew.length) {
      return this.modalOpenSympathies();
    }
  }

  async runNotifier() {
    const {
      authConfirm,
      authEmailCode,
      getBadges,
      loadCurrentUser,
      loadGiftsPaid,
      loadNewGifts,
      loadSympathiesNew,
      paymentList,
    } = this.props;
    const {
      isEditPage,
      currentUser,
      location,
    } = this.props;
    const {
      emailChangeCode,
      emailConfirmationCode,
    } = this.state;

    // Работаем только для авторизованных юзеров
    if (isEmpty(currentUser)) {
      return;
    }

    // Забаненные юзеры не могут пользоваться сайтом
    if (currentUser.role === 3) {
      return this.modalOpenBanned();
    }

    // Юзер не может пользоваться сайтом до подтверждения почты.
    if (currentUser.roleStatus === 'email_confirm' && !this.isEmailConfirmationRequested) {
      // Активируем юзера, если он перешёл по ссылке из почты с кодом.
      if (emailConfirmationCode) {
        this.isEmailConfirmationRequested = true;
        return authConfirm(emailConfirmationCode).catch(noop); // Ошибки обрабатываются в componentDidUpdate
      }

      // В пртивном случае говорим о необходимости подтверждения.
      return this.modalOpenEmail();
    }

    // Если почта подтверждена, проверяем оплату взноса.
    // Если оплаты нет, просим оплатить.
    // Если уже есть, перегружаем данные юзера (чтобы обновилась роль)
    // и блокируем загрузку остальных данных, т.к. сразу после активации
    // у него гарантированно нет подарков, симпатий и т.п.
    // На странице редактирования про оплату ничего не говорим.
    if (currentUser.roleStatus === 'fee') {
      if (isEditPage) {
        return;
      }

      const payments = await paymentList();

      if (payments.indexOf('fee') === -1) {
        return this.modalOpenEntryFee();
      } else {
        this.isDataFetched = true;
        this.runAdmitad();
        return loadCurrentUser();
      }
    }

    // Подтверждение нового email зарегистрированным пользователем (через настройки)
    if (emailChangeCode && !this.isEmailChangeRequested) {
      this.isEmailChangeRequested = true;
      return authEmailCode(emailChangeCode).catch(noop); // Ошибки обрабатываются в componentDidUpdate
    }

    // Грузим данные только если это необходимо (по сути, componentDidMount)
    // и если юзер активен
    if (this.isDataFetched || currentUser.role < 10) {
      return;
    }

    this.isDataFetched = true;

    // Загружаем все уведомления для активных юзеров
    Promise.all([
      getBadges(),          // Уведомления
      loadGiftsPaid(),      // Оплаченные, но не подаренные подарки
      loadNewGifts(),       // Полученные подарки
      loadSympathiesNew(),  // Новые симпатии
      paymentList(),        // Список оплаченных сущностей
    ]).then(() => {
      // "Гашение" оплаты происходит после запроса на paymentList.
      // Этот запрос, в свою очередь, изменяет данные пользователя
      // в рамках выполненных действий,
      // поэтому после гашения нужно получить обновлённые данные пользователя.
      loadCurrentUser();
    }).catch(err => new Error(err));
  }

  handlePayment() {
    switch (this.servicePaid) {
      case 'gift':
        return this.modalOpenGiftSend();
      default:
        return this.modalOpenAlert();
    }
  }

  handleEmailConfirmed() {
    const { loadCurrentUser } = this.props;

    this.modalClose();
    loadCurrentUser();
  }

  async earnFreeGifts() {
    const { earnFreeGifts, loadCurrentUser } = this.props;

    this.isFreeGiftsRequested = true;

    try {
      const gotFreeGifts = await earnFreeGifts();

      if (gotFreeGifts) {
        this.modalOpenFreeGifts();
        loadCurrentUser();
      }

      return gotFreeGifts;
    } catch (err) {
      this.isFreeGiftsRequested = false;
      return false;
    }
  }

  async modalOpenGiftSend() {
    const { location, giftsPaid, loadUserCompact } = this.props;
    const userCheckResult = location.pathname.match(/^\/user\/(\d+)$/);
    const openedUserId = userCheckResult ? parseInt(userCheckResult[1], 10) : 0;

    let gift;

    if (openedUserId) {
      for (const giftPaid of giftsPaid) {
        if (giftPaid.toUserId === openedUserId) {
          gift = giftPaid;
          break;
        }
      }
    }

    if (!gift) {
      gift = giftsPaid[0];

      if (gift) {
        await loadUserCompact(gift.toUserId);
      } else {
        return;
      }
    }

    this.setState({
      modalOpen: MODAL_GIFT_SEND,
      gift: {
        id: gift.giftId,
        recipient: gift.toUserId,
      },
    });
  }

  modalOpenFreeGifts() {
    this.modalOpen(MODAL_FREE_GIFTS);
  }

  modalOpenAlert() {
    this.modalOpen(MODAL_ALERT);
  }

  modalOpenGiftNew() {
    this.modalOpen(MODAL_GIFT_NEW);
  }

  modalOpenSympathies() {
    this.modalOpen(MODAL_SYMPATHIES);
  }

  modalOpenEmail() {
    this.modalOpen(MODAL_EMAIL);
  }

  modalOpenEmailConfirm() {
    this.modalOpen(MODAL_EMAIL_CONFIRM);
  }

  modalOpenEntryFee() {
    this.modalOpen(MODAL_ENTRY_FEE);
  }

  modalOpenError() {
    this.modalOpen(MODAL_ERROR);
  }

  modalOpenBanned() {
    this.modalOpen(MODAL_BANNED);
  }

  modalOpen(type) {
    this.setState({
      modalOpen: type,
    });
  }

  modalClose(callback) {
    this.setState({
      modalOpen: '',
    }, () => {
      if (typeof callback === 'function') {
        callback();
      }

      this.props.paymentBurndown();
    });
  }

  modalCloseSympathies() {
    this.modalClose(this.props.clearSympathiesNew);
  }

  async runAdmitad() {
    const feePaymentInfo = await this.props.paymentListFee().catch(err => new Error(err));

    if (isSSR() || isEmpty(feePaymentInfo)) {
      return;
    }

    ADMITAD = window.ADMITAD || {};
    ADMITAD.Invoice = ADMITAD.Invoice || {};
    ADMITAD.Invoice.broker = 'adm';          // параметр дедупликации (по умолчанию для admitad)
    ADMITAD.Invoice.category = '2';          // код целевого действия (определяется при интеграции)
    ADMITAD.Invoice.referencesOrder = ADMITAD.Invoice.referencesOrder || [];
    ADMITAD.Invoice.referencesOrder.push({
      orderNumber: feePaymentInfo.id,        // внутренний номер заказа (не более 100 символов)
      orderedItem: [{
        Product: {
          productID: '7',                    // внутренний код продукта (не более 100 символов, соответствует ID из товарного фида). 7 - это регистрация
          category: feePaymentInfo.price === 490 || feePaymentInfo.price === 1 ? '1' : '2',  // код тарифа (определяется при интеграции)
          price: feePaymentInfo.price,       // цена товара
          priceCurrency: 'RUB',              // код валюты ISO-4217 alfa-3
        },
        orderQuantity: '1',                  // количество товара
        additionalType: 'lead',
      }],
    });

    ADMITAD.Tracking.processPositions();
  }

  renderAlertContent() {
    const { currentUser, endInteresting } = this.props;

    // Available keys list:
    // https://trello.com/c/TCoLmRjS/#comment-5c8654f44a528114d37f743a

    switch (this.servicePaid) {
      case 'interesting_page':
        return (
          <React.Fragment>
            <h2>Поздравляем!</h2>
            <p>Ваша анкета размещена в&nbsp;интересных анкетах { !!endInteresting && <span className="nobr">на { moment().to(endInteresting, true) }</span> }</p>
          </React.Fragment>
        );
      case 'invisible':
        return (
          <React.Fragment>
            <h2>Поздравляем!</h2>
            <p>Теперь Ваша анкета будет видна только тем, с&nbsp;кем вы&nbsp;захотите познакомиться.</p>
            <p>Чтобы показать себя понравившемуся вам человеку, вам достаточно написать ему или поставить лайк.</p>
            { !!currentUser.endInvisible &&
              <p>Режим невидимости будет активен <span className="nobr">{ moment().to(currentUser.endInvisible, true) }</span></p>
            }
          </React.Fragment>
        );
      case 'main_page':
        return (
          <React.Fragment>
            <h2>Поздравляем!</h2>
            <p>Ваша анкета размещена на&nbsp;главной странице { !!currentUser.endVip && <span className="nobr">на { moment().to(currentUser.endVip, true) }</span> }</p>
          </React.Fragment>
        );
      case 'premium':
        return (
          <React.Fragment>
            <h2>Поздравляем!</h2>
            <p>Ваш Premium-аккаунт теперь действует { !!currentUser.endPremium && <span className="nobr">до { moment(currentUser.endPremium).format('D MMMM YYYY') }</span> }</p>
          </React.Fragment>
        );
      case 'raise':
        return (
          <React.Fragment>
            <p>Ваша страница поднята в поиске</p>
          </React.Fragment>
        );
      case 'fee':
        return (
          <React.Fragment>
            <p>
              <b>{ currentUser.name }, мы&nbsp;рады что&nbsp;Вы с&nbsp;нами!</b>
              <br />
              <br />
              Искренне желаем Вам приятных знакомств и&nbsp;напоминаем, что&nbsp;Вы в&nbsp;любой момент можете обратиться в&nbsp;службу поддержки, которая ответит на&nbsp;все ваши вопросы.
              <br />
              Команда LinkYou
            </p>
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      gift,
      modalOpen,
      errorText,
    } = this.state;
    const {
      currentUser,
      isEditPage,
      userSympathiesNew,
      requestStateAuthEmailConfirm,
    } = this.props;

    return (
      <React.Fragment>
        <ModalAlert
          isOpen={ modalOpen === MODAL_ALERT }
          handleClose={ this.modalClose }
          withLogo
        >
          { this.renderAlertContent() }
        </ModalAlert>
        <ModalAlert
          isOpen={ modalOpen === MODAL_FREE_GIFTS }
          handleClose={ this.modalClose }
        >
          <h2>Поздравляем!</h2>
          <p>Вы получили 3 бесплатных подарка!</p>
          <br />
          <figure>
            <img src="/images/presents/flowers.jpg" width="150" height="150" alt="Подарок" />
          </figure>
        </ModalAlert>
        <ModalAlert
          isOpen={ modalOpen === MODAL_BANNED }
          withLogo
        >
          <p>Ваша анкета заблокирована без возможности восстановления за&nbsp;нарушение правил сервиса LinkYou.</p>
        </ModalAlert>
        { modalOpen === MODAL_GIFT_SEND &&
          <ModalGiftSend
            recipientId={ gift.recipient }
            giftId={ gift.id }
            handleClose={ this.modalClose }
            handleCloseAll={ this.modalClose }
            isOpen
          />
        }
        <ModalGift
          isOpen={ modalOpen === MODAL_GIFT_NEW }
          handleClose={ this.modalClose }
          handleCloseAll={ this.modalClose }
        />
        <ModalSympathies
          isOpen={ modalOpen === MODAL_SYMPATHIES }
          handleClose={ this.modalCloseSympathies }
          data={ userSympathiesNew }
          currentUser={ currentUser }
        />
        <ModalAlert
          isOpen={ modalOpen === MODAL_ERROR }
          handleClose={ this.modalClose }
        >
          <h2>Ошибка!</h2>
          <p>{ errorText }</p>
        </ModalAlert>
        <ModalEmail
          isOpen={ modalOpen === MODAL_EMAIL }
          handleClose={ isEditPage ? this.modalClose : null }
          allowChange
        />
        <ModalSuccess
          isOpen={ modalOpen === MODAL_EMAIL_CONFIRM }
          handleClose={ this.handleEmailConfirmed }
          headline="Почта подтверждена!"
        />
        <ModalEntryFee
          isOpen={ modalOpen === MODAL_ENTRY_FEE }
          handleClose={ this.modalClose }
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const currentUser = getUser(state, (state.session || {}).user);
  const path = ownProps.location.pathname;

  return {
    currentUser,
    emailConfirmationCodeState: state.states.authConfirm || {},
    emailChangeCodeState: state.states.authEmailCode || {},
    endInteresting: ((state.data.usersInteresting || {}).userPaidData || {}).endDate,
    giftsPaid: selectGiftsPaid(state, currentUser),
    isEditPage: path === '/edit',
    isPaymentPage: path.search('/payment/') === 0,
    payment: state.payment,
    requestStatePaymentList: state.states.paymentList || {},
    requestStateGiftsPaid: state.states.giftsPaid || {},
    requestStateNewGifts: state.states.newGifts || {},
    userSympathiesNew: selectSympathiesNew(state),
    newGift: state.wsUpdates.giftNew,
    newGiftsCount: (state.data.newGifts || []).length,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    authConfirm,
    authEmailCode,
    clearSympathiesNew,
    earnFreeGifts,
    getBadges,
    loadCurrentUser,
    loadGiftsPaid,
    loadNewGifts,
    loadSympathiesNew,
    loadUserCompact,
    paymentBurndown,
    paymentList,
    paymentListFee,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifier);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';

import { openModalPay, updateModalProps, closeModal } from 'common/actions';
import { declOfNum } from 'common/helpers';

import Preloader from 'components/Preloader';
import Priceline from 'components/Priceline';
import FormButton from 'components/FormButton';
import Modal from 'components/Modal';

import nationalityImage from './images/nationality.svg';
import upImage from './images/up.svg';
import invisibleImage from './images/invisible.svg';
import giftsImage from './images/gifts.svg';
import './style.css';

class ModalGoPremium extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.pay = this.pay.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.updateEntityId = this.updateEntityId.bind(this);
    this.state = {
      entityId: -1,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.entityId > -1 || isEmpty(props.mode)) {
      return null;
    }

    return {
      entityId: props.mode.price.defaultValue,
    };
  }

  pay() {
    const { entityId } = this.state;
    const { openModalPay, closeModal } = this.props;

    openModalPay({
      handleClose: this.closeAll,
      goBack: closeModal,
      entityId: entityId,
      updateEntityId: this.updateEntityId,
      mode: 'premium',
    });
  }

  closeAll() {
    this.props.closeModal();
    this.props.handleClose();
  }

  updateEntityId(e) {
    const update = {
      entityId: parseInt(e.target.value, 10),
    };

    this.props.updateModalProps(update);
    this.setState(update);
  }

  makeData() {
    const { entityId } = this.state;
    const complimentsList = {
      1: 3,
      2: 5,
      3: 20,
      4: 50,
    };
    const complimentValue = complimentsList[entityId];

    return [
      {
        image: nationalityImage,
        value: 'Поиск по&nbsp;национальности и&nbsp;вероисповеданию',
      },
      {
        image: upImage,
        value: `${complimentValue} ${declOfNum(complimentValue, ['поднятие', 'поднятия', 'поднятий'])} анкеты в&nbsp;результатах поиска`,
        badge: complimentValue,
      },
      {
        image: invisibleImage,
        value: 'Режим невидимости на&nbsp;сайте',
      },
      {
        image: giftsImage,
        value: `${complimentValue} ${declOfNum(complimentValue, ['бесплатный подарок', 'бесплатных подарка', 'бесплатных подарков'])}`,
        badge: complimentValue,
      },
    ];
  }

  render() {
    const { entityId } = this.state;
    const { isOpen, isPayment, handleClose, mode } = this.props;
    const data = this.makeData();

    return (
      <Modal isOpen={ !isPayment && isOpen } handleClose={ handleClose }>
        <div className="modal-go-premium">
          { isEmpty(mode)
            ? <Preloader isPageLoader />
            : <React.Fragment>
                <h2>Что дает Premium-аккаунт?</h2>
                <Priceline
                  data={ mode.price.data }
                  selected={ entityId }
                  selectOption={ this.updateEntityId }
                />
                <div className="modal-go-premium__body">
                  <div className="modal-go-premium__benefits">
                    <div className="modal-go-premium__benefits-heading">
                      Premium-аккаунт дает Вам максимальные преимущества на LinkYou:
                    </div>
                    <div className="modal-go-premium__benefits-list">
                      {
                        data.map((item, key) => (
                          <div className="modal-go-premium__benefits-item" key={ key }>
                            <div className="modal-go-premium__benefits-item-preview">
                              <img src={ item.image } alt="" />
                              { !!item.badge &&
                                <div className="modal-go-premium__benefits-item-preview-badge">
                                  { item.badge }
                                </div>
                              }
                            </div>
                            <div className="modal-go-premium__benefits-item-text" dangerouslySetInnerHTML={{ __html: item.value }}>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="modal-go-premium__submit">
                    <FormButton skin={ ['darkblue', 'size-m'] } onClick={ this.pay }>
                      Стать Premuim
                    </FormButton>
                  </div>
                </div>
              </React.Fragment>
          }
        </div>
      </Modal>
    );
  }
};

function mapStateToProps(state) {
  return {
    mode: state.prices.premium || {},
    isPayment: state.modal.type === 'MODAL_PAY',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModalPay,
    updateModalProps,
    closeModal,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalGoPremium);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadUserPhotos } from 'common/actions';

import FormButton from 'components/FormButton';
import FormUploader from 'components/FormUploader';
import Scrollable from 'components/Scrollable';

import './style.css';

class WritePaneAlbum extends Component {
  static propTypes = {
    userId: PropTypes.number,
    onClose: PropTypes.func,
    onPhotoAdd: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.choosePhoto = this.choosePhoto.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  componentDidMount() {
    const { loadUserPhotos, userId } = this.props;

    loadUserPhotos(userId);
  }

  choosePhoto(e) {
    const { photoId, photoSrc } = e.target.dataset;
    const { onPhotoAdd, onClose } = this.props;

    onPhotoAdd([{ id: photoId, src: photoSrc }]);
    onClose();
  }

  handleUpload(...args) {
    const { onPhotoAdd, onClose } = this.props;

    onPhotoAdd(...args);
    onClose();
  }

  render() {
    const { data, onClose, onPhotoAdd } = this.props;

    if (!data.length) {
      return null;
    }

    return (
      <div className="write-pane-album">
        <Scrollable>
          <div className="write-pane-album__photos">
            <div className="write-pane-album__photo">
              <FormUploader
                mode="album"
                onComplete={ this.handleUpload }
                multiple
              />
            </div>
            {
              data.map(photo =>
                <button
                  type="button"
                  className="write-pane-album__photo"
                  style={{ backgroundImage: `url("${photo.src.default}")` }}
                  onClick={ this.choosePhoto }
                  data-photo-id={ photo.id }
                  data-photo-src={ photo.src.default }
                  key={ photo.id }
                />
              )
            }
          </div>
        </Scrollable>
        <div className="write-pane-album__cancel">
          <FormButton type="submit" skin={ ['lightblue', 'size-s'] } onClick={ onClose }>
            Отмена
          </FormButton>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    data: ((state.data.userPhotos || {})[ownProps.userId] || []).map(id => state.entities.photos[id]),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadUserPhotos,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WritePaneAlbum);

import { generateTypes } from 'common/helpers';
import { CALL_API } from 'common/middleware/api';

export const uploadPhotos = (files, requestId) => {
  const formData = new FormData();

  if (typeof files === 'object' && files !== null) {
    for (let i = 0; i < files.length; i++) {
      formData.append('images[]', files[i]);
    }
  } else {
    formData.append('images[]', files);
  }

  return {
    [CALL_API]: {
      types: generateTypes('UPLOAD_PHOTOS'),
      method: 'post',
      endpoint: 'https://img.linkyou.ru/upload.php',
      body: formData,
      payload: {
        requestId,
      },
    }
  };
};

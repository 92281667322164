import { generateTypes } from 'common/helpers';
import { CALL_API, Schemas } from 'common/middleware/api';
import { decamelize } from 'humps';

export const switchFavorites = (id) => ({
  [CALL_API]: {
    body: {
      user_id: id,
    },
    payload: {
      userId: id,
    },
    types: generateTypes('SWITCH_FAVORITES'),
    method: 'post',
    endpoint: 'favorites/switch',
  }
});

export const switchBlackList = (id) => ({
  [CALL_API]: {
    body: {
      user_id: id,
    },
    payload: {
      userId: id,
    },
    types: generateTypes('SWITCH_BLACK_LIST'),
    method: 'post',
    endpoint: 'blacklist/switch',
  }
});

export const likeyouShift = () => ({
  type: 'LIKEYOU_SHIFT',
});

export const likeyouLike = (id) => ({
  [CALL_API]: {
    body: {
      user_id: id,
    },
    types: generateTypes('LIKEYOU_LIKE'),
    method: 'post',
    endpoint: 'likeyou/like',
    schema: Schemas.USER,
  }
});

export const likeyouSkip = (id) => ({
  [CALL_API]: {
    body: {
      user_id: id,
    },
    types: generateTypes('LIKEYOU_SKIP'),
    method: 'post',
    endpoint: 'likeyou/skip',
    schema: Schemas.USER,
  }
});

export const likeUser = (userId) => ({
  [CALL_API]: {
    payload: {
      userId,
    },
    types: generateTypes('LIKE_USER'),
    method: 'post',
    endpoint: `user/${userId}/like`,
  }
});

export const resetBadge = (badgeName) => ({
  type: 'BADGE_RESET',
  payload: {
    badgeName,
  },
});

export const updateUnreadMessagesCount = (dialogId, changeAmount) => ({
  type: 'UPDATE_UNREAD_MESSAGES_COUNT',
  payload: {
    dialogId,
    changeAmount,
  },
});

export const userUpdate = (body, payload = {}) => ({
  [CALL_API]: {
    body,
    payload,
    types: generateTypes('USER_UPDATE'),
    method: 'post',
    endpoint: 'user/update?return_user=1',
    schema: Schemas.USER_BOTH,
  }
});

export const userFieldUpdate = (body, payload = {}) => ({
  [CALL_API]: {
    body,
    payload,
    types: generateTypes('USER_UPDATE_FIELD'),
    method: 'post',
    endpoint: 'user/update',
  }
});

export const visibilitySwitch = (userId) => ({
  [CALL_API]: {
    payload: {
      userId,
    },
    types: generateTypes('VISIBILITY_SWITCH'),
    method: 'post',
    endpoint: 'user/visible/switch',
  }
});

export const userStateSwitch = (userId) => ({
  [CALL_API]: {
    payload: {
      userId,
    },
    types: generateTypes('USER_STATE_SWITCH'),
    method: 'post',
    endpoint: 'user/active/switch',
  }
});

export const userDelete = () => ({
  [CALL_API]: {
    types: generateTypes('USER_DELETE'),
    method: 'post',
    endpoint: 'user/delete/profile',
  }
});

export const notificationSettingsGet = () => ({
  [CALL_API]: {
    types: generateTypes('NOTIFICATION_SETTINGS_GET'),
    method: 'get',
    endpoint: 'user/notifications',
  }
});

export const notificationSettingsSwitch = (code) => ({
  [CALL_API]: {
    body: {
      code: decamelize(code),
    },
    payload: {
      code,
    },
    types: generateTypes('NOTIFICATION_SETTINGS_SWITCH'),
    method: 'post',
    endpoint: 'user/notification/switch',
  }
});

export const propertyDelete = (body) => ({
  [CALL_API]: {
    body,
    types: generateTypes('PROPERTY_DELETE'),
    method: 'post',
    endpoint: 'user/properties/delete',
  }
});

export const userRaise = (userId) => ({
  [CALL_API]: {
    payload: {
      userId,
    },
    types: generateTypes('USER_RAISE'),
    method: 'post',
    endpoint: 'user/raise',
  }
});

export const earnFreeGifts = () => ({
  [CALL_API]: {
    types: generateTypes('EARN_FREE_GIFTS'),
    method: 'post',
    endpoint: 'user/get-free-gift',
  }
});

export const checkStatusCode = (userId) => ({
  [CALL_API]: {
    types: generateTypes('CHECK_STATUS_CODE'),
    method: 'get',
    endpoint: `user/${userId}/status-code`,
  }
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import petrovich from 'petrovich';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { normalize } from 'normalizr';
import ImagesLoaded from 'react-images-loaded';

import { Schemas } from 'common/middleware/api';
import { formatDate, declOfNum } from 'common/helpers';
import { VIEW_SIGNUP } from 'components/Auth';
import {
  addPhotoComment,
  photoLike,
  photoCommentDelete,
  userUpdate,
  deletePhoto,
  openModalSupport,
  openModalAuth,
} from 'common/actions';

import withPhotoViewer from 'hocs/withPhotoViewer';

import Indicator from 'components/Indicator';
import Preloader from 'components/Preloader';
import UserCardMini from 'components/UserCardMini';
import Options from 'components/Options';
import Comments from 'components/Comments';
import Write from 'components/Write';
import Icon from 'components/Icon';
import Modal from 'components/Modal';

import './style.css';

class ModalPhoto extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleCloseAll: PropTypes.func,
    user: PropTypes.object,
    data: PropTypes.object.isRequired,
    isReversed: PropTypes.bool,
    isGuestMode: PropTypes.bool,
  }

  static defaultProps = {
    isReversed: false,
  }

  touchStartOffset = 0;
  isSwipe = false;

  constructor(props) {
    super(props);
    this.addComment = this.addComment.bind(this);
    this.deletePhoto = this.deletePhoto.bind(this);
    this.doLikePhoto = this.doLikePhoto.bind(this);
    this.makeCover = this.makeCover.bind(this);
    this.showImage = this.showImage.bind(this);
    this.toggleComments = this.toggleComments.bind(this);
    this.photoAbuse = this.photoAbuse.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.state = {
      isPhotoLoaded: false,
      isMobileCommentsShown: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      this.setState({
        isPhotoLoaded: false,
        isMobileCommentsShown: false,
      });
    }
  }

  showImage() {
    this.setState({
      isPhotoLoaded: true,
    });
  }

  doLikePhoto(e) {
    const { isGuest, openModalAuth } = this.props;
    const photoId = parseInt(e.currentTarget.dataset.id, 10);

    if (isGuest) {
      return openModalAuth(VIEW_SIGNUP);
    }

    if (!isNaN(photoId)) {
      this.props.photoLike(photoId);
    }
  }

  deletePhoto() {
    const { deletePhoto, activePhotoId } = this.props;

    deletePhoto(activePhotoId);
  }

  toggleComments() {
    this.setState(state => ({
      isMobileCommentsShown: !state.isMobileCommentsShown,
    }));
  }

  addComment(data, photoId) {
    const { addPhotoComment } = this.props;
    const { comment } = data;
    const localResponse = normalize(data, Schemas.PHOTO_COMMENT);

    addPhotoComment(photoId, comment, localResponse);
  }

  makeCover() {
    const { activePhotoId, userUpdate } = this.props;

    userUpdate({
      avatar: activePhotoId,
    }, {
      updateType: 'avatar',
    });
  }

  photoAbuse() {
    const { openModalSupport, activePhotoId, isGuest, isGuestMode, openModalAuth } = this.props;

    if (isGuestMode) {
      return;
    }
    if (isGuest) {
      return openModalAuth(VIEW_SIGNUP);
    }

    openModalSupport('photo', activePhotoId);
  }

  handleTouchStart(e) {
    const { touches } = e;

    if (touches.length !== 1 || this.isSwipe) {
      return;
    }

    this.touchStartOffset = touches[0].pageX;
    this.isSwipe = true;
  }

  handleTouchMove(e) {
    if (!this.isSwipe) {
      return;
    }

    const { goToPrevious, goToNext } = this.props;
    const movement = e.touches[0].pageX - this.touchStartOffset;

    if (Math.abs(movement) < 50) {
      return;
    }

    if (movement < 0) {
      goToNext();
    } else {
      goToPrevious();
    }

    this.handleTouchEnd();
  }

  handleTouchEnd() {
    if (!this.isSwipe) {
      return;
    }

    this.touchStartOffset = 0;
    this.isSwipe = false;
  }


  renderTitle() {
    const { isSelf, user } = this.props;

    if (isSelf) {
      return 'Ваши фотографии ';
    } else if (!!user) {
      return `Фотографии ${petrovich[user.gender.code].first.genitive(user.name)} `;
    } else {
      return 'Фото ';
    }
  }

  render() {
    const {
      isOpen,
      handleClose,
      handleCloseAll,
      isSelf,
      user,
      data,
      comments,
      activePhotoId,
      isReversed,
      photoCommentDelete,
      commentDeleteRequestState,
      commentAddRequestState,
      isAvatarChanging,
    } = this.props;
    const { isMobileCommentsShown, isPhotoLoaded } = this.state;
    const photo = data[activePhotoId];
    const ids = Object.keys(data).map(id => parseInt(id, 10));

    let src;

    if (isReversed) {
      ids.reverse();
    }

    if (photo) {
      src = typeof photo.src === 'object' ? photo.src.origin : photo.src;
    }

    return (
      <Modal isOpen={ isOpen } handleClose={ handleClose }>
        <div className={classNames(
          'modal-photo',
          { '-with-comments': isMobileCommentsShown },
        )}>
          { !!photo &&
            <React.Fragment>
              <ImagesLoaded className="modal-photo__main" background=".modal-photo__main-photo" done={ this.showImage } key={ photo.id }>
                <div className="modal-photo__main-fader" />
                <div className="modal-photo__main-title">
                  { this.renderTitle() }
                  <span className="modal-photo__main-title-count">{ ids.indexOf(activePhotoId) + 1 } из { ids.length }</span>
                  { photo.datetime &&
                    <div className="modal-photo__main-title-date">
                      Добавлена
                      <span
                        title={ formatDate(photo.datetime) }
                        dangerouslySetInnerHTML={{ __html: ` ${formatDate(photo.datetime, 'timeOrDay')}` }}
                      />
                    </div>
                  }
                </div>
                { ids.length > 1 &&
                  <button type="button" className="modal-photo__main-nav -prev" onClick={ this.props.goToPrevious }>
                    <Icon glyph="arrow-photo" />
                  </button>
                }
                <div
                  className={classNames(
                    'modal-photo__main-photo',
                    // { '-loaded': isPhotoLoaded },
                  )}
                  onTouchStart={ this.handleTouchStart }
                  onTouchMove={ this.handleTouchMove }
                  onTouchEnd={ this.handleTouchEnd }
                  style={{ backgroundImage: `url("${src}")` }}
                />
                { !isPhotoLoaded &&
                  <Preloader />
                }
                { ids.length > 1 &&
                  <button type="button" className="modal-photo__main-nav -next" onClick={ this.props.goToNext }>
                    <Icon glyph="arrow-photo" />
                  </button>
                }
                { 'likesCount' in photo &&
                  <React.Fragment>
                    <div className="modal-photo__main-indicators">
                      <Indicator icon={ photo.isLiked ? 'heart-filled' : 'heart' } value={ `${photo.likesCount} ${declOfNum(photo.likesCount, ['лайк', 'лайка', 'лайков'])}` } onClick={ this.doLikePhoto } data-id={ photo.id } />
                      { isSelf && user.avatar.id !== activePhotoId &&
                        <Indicator icon="photo" value={ isAvatarChanging ? 'Подождите...' : 'Сделать главным фото' } onClick={ this.makeCover } disabled={ isAvatarChanging } />
                      }
                    </div>
                    <div className="modal-photo__main-fader" />
                  </React.Fragment>
                }
              </ImagesLoaded>
              { user &&
                <div className="modal-photo__aside">
                  <UserCardMini user={ user } size="large" onClick={ handleCloseAll || handleClose }>
                    <span className="hidden-b-t">Добавлена</span>
                    <span
                      title={ formatDate(photo.datetime) }
                      dangerouslySetInnerHTML={{ __html: ` ${formatDate(photo.datetime, 'timeOrDay')}` }}
                    />
                  </UserCardMini>
                  <div className="modal-photo__options">
                    <Indicator className="hidden-a-m -likes" icon={ photo.isLiked ? 'heart-filled' : 'heart' } value={ photo.likesCount } onClick={ this.doLikePhoto } data-id={ photo.id } />
                    <Indicator className="hidden-b-m -likes" icon={ photo.isLiked ? 'heart-filled' : 'heart' } value={ `${photo.likesCount} ${declOfNum(photo.likesCount, ['лайк', 'лайка', 'лайков'])}` } onClick={ this.doLikePhoto } data-id={ photo.id } />
                    <Indicator className="hidden-a-m -comments" icon="chat" value={ comments.length } onClick={ this.toggleComments } />
                    <Options tooltipAt="top">
                      <a href={ src } target="_blank" rel="noopener noreferrer">Открыть в полный размер</a>
                      { isSelf && user.avatar.id !== activePhotoId &&
                        <button type="button" className="hidden-a-m" onClick={ this.makeCover } disabled={ isAvatarChanging }>{ isAvatarChanging ? 'Подождите...' : 'Сделать главным фото' }</button>
                      }
                      <hr />
                      { isSelf
                        ? <button type="button" className="text-red" onClick={ this.deletePhoto }>Удалить фотографию</button>
                        : <button type="button" className="text-red" onClick={ this.photoAbuse }>Пожаловаться на фото</button>
                      }
                    </Options>
                  </div>
                  <Comments
                    data={ comments }
                    entityId={ photo.id }
                    entityUserId={ user.id }
                    onDelete={ photoCommentDelete }
                    deleteCommentState={ commentDeleteRequestState }
                    key={ photo.id }
                  />
                  <Write
                    mode="gallery"
                    placeholder="Напишите комментарий…"
                    entityId={ photo.id }
                    onSubmit={ this.addComment }
                    autoFocus={ false }
                  />
                </div>
              }
            </React.Fragment>
          }
        </div>
      </Modal>
    );
  }
};

function mapStateToProps(state, ownProps) {
  const photoId = ownProps.activePhotoId;
  const userUpdate = state.states.userUpdate || {};

  return {
    comments: ((state.data.photoComments || {})[photoId] || []).map(id => state.entities.photoComments[id]),
    commentDeleteRequestState: state.states.photoCommentDelete || {},
    commentAddRequestState: state.states.addRemotePhotoComment || {},
    isSelf: !ownProps.isGuestMode && (state.session || {}).user === (ownProps.data[photoId] || {}).userId,
    isGuest: !state.session,
    isAvatarChanging: userUpdate.request && (userUpdate.payload || {}).updateType === 'avatar',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addPhotoComment,
    photoLike,
    photoCommentDelete,
    userUpdate,
    deletePhoto,
    openModalSupport,
    openModalAuth,
  }, dispatch);
}

export default withPhotoViewer(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalPhoto));

import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import UserAvatar from 'components/UserAvatar';
import UserName from 'components/UserName';

import './style.css';

const UserCardMini = ({ user, size, anonymous, onClick, children, isAMP }) => {
  const isDeleted = user.roleStatus === 'deleted';
  const isClickable = !(anonymous || isDeleted);
  const TagName = isClickable ? Link : 'span';

  return (
    <TagName
      to={ anonymous || isDeleted ? null : `/user/${user.id}` }
      onClick={ typeof onClick === 'function' && isClickable ? onClick : null }
      className="user-card-mini"
    >
      <UserAvatar user={user} skin="circle" size={size} anonymous={anonymous} isAMP={isAMP} />
      <span className="user-card-mini__info">
        <UserName user={ user } anonymous={ anonymous } withAge />
        { !isDeleted &&
          <span className="user-card-mini__caption">
            { children }
          </span>
        }
      </span>
    </TagName>
  );
};

UserCardMini.propTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.string,
  anonymous: PropTypes.bool,
  onClick: PropTypes.func,
};

UserCardMini.defaultProps = {
  size: 'large',
  anonymous: false,
};

export default UserCardMini;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import FormButton from 'components/FormButton';

import './style.css';
import desktopImage from './images/desktop.jpg';
import mobileImage from './images/mobile.jpg';

class ModalYouTube extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  }

  render() {
    const { isOpen, handleClose } = this.props;

    return (
      <Modal isOpen={ isOpen } handleClose={ handleClose }>
        <div className="modal-yandex-music-help">
          <h2>Как добавить альбом <br />или плейлист с&nbsp;<a href="https://music.yandex.ru/" target="_blank" rel="noopener noreferrer">Яндекс Музыки</a></h2>
          <p>Зайдите на&nbsp;Яндекс Музыка и&nbsp;возле названия альбома или трека нажмите на&nbsp;кнопку &laquo;Поделиться&raquo;</p>
          <figure>
            <img src={ desktopImage } alt="" className="hidden-b-m" />
            <img src={ mobileImage } alt="" className="hidden-a-m" />
          </figure>
          <p>В&nbsp;появившемся окне скопируйте ссылку и&nbsp;вставьте её&nbsp;в&nbsp;поле для ввода выше.</p>
          <FormButton skin={ ['darkblue', 'size-m'] } onClick={ handleClose }>
            OK
          </FormButton>
        </div>
      </Modal>
    );
  }
};

export default ModalYouTube;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  OKShareButton,
  OKShareCount,
  OKIcon,
  FacebookShareButton,
  FacebookShareCount,
  FacebookIcon,
  VKShareButton,
  VKShareCount,
  VKIcon,
} from 'react-share';

import jsonp from 'jsonp';

import './style.css';

const SHARE_URL = 'https://linkyou.ru'

const buttonProps = {
  url: SHARE_URL,
  className: 'share__btn'
}

const shareCountProps = {
  url: SHARE_URL,
  className: 'share__counter'
}

const iconProps = {
  round: true,
  size: 34
}

class Share extends Component {
  static propTypes = {
    skin: PropTypes.oneOf(['footer', 'landing']),
  }

  state = {
    okCount: 0
  }

  componentDidMount() {

    //TODO: удалить, когда будет смержен PR в репозитории react-share https://github.com/nygardk/react-share/pull/289
    //проблема в том, что если на странице несколько виджетов для Одноклассники, то счетчик работает только для последнего
    //это временный фикс
    if (!window.ODKL) {
      window.ODKL = {
        updateCount: (a, b) => {
          if (window.ODKL.callbacks[parseInt(a)]) {
            window.ODKL.callbacks[parseInt(a)](b)
          }
        },
        callbacks: []
      };
    }
    const index = window.ODKL.callbacks.length;
    window.ODKL.callbacks.push((count) => {
      this.setState({ okCount: count })
    });
    jsonp(
      `https://connect.ok.ru/dk?st.cmd=extLike&uid=${index}&ref=https%3A%2F%2Flinkyou.ru`
    );
  }


  render() {
    const { skin } = this.props;
    const { okCount } = this.state;
    const isFooter = skin === 'footer';

    return (
      <div className={`share -skin-${skin}`}>
        <ul className='share__list'>
          <li className='share__item share__item-fb'>
            <FacebookShareButton {...buttonProps}>
              {isFooter ?
                <div className='share__icon' />
                :
                <FacebookIcon {...iconProps} />
              }
              <FacebookShareCount {...shareCountProps}>
                {count => count}
              </FacebookShareCount>
            </FacebookShareButton>
          </li>
          <li className='share__item share__item-vk'>
            <VKShareButton {...buttonProps}>
              {isFooter ?
                <div className='share__icon' />
                :
                <VKIcon {...iconProps} />
              }
              <VKShareCount {...shareCountProps}>
                {count => count}
              </VKShareCount>
            </VKShareButton>
          </li>
          <li className='share__item share__item-ok'>
            <OKShareButton {...buttonProps}>
              {isFooter ?
                <div className='share__icon' />
                :
                <OKIcon {...iconProps} />
              }
              <span className='share__counter'>{okCount}</span>
              {/* <OKShareCount {...shareCountProps}>
                {count => count}
              </OKShareCount> */}
            </OKShareButton>
          </li>
        </ul>
      </div>
    );
  }
};

export default Share;

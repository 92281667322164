import React from 'react';
import PropTypes from 'prop-types';

import './style.css';
import Icon from 'components/Icon';

const ModalClose = ({ onClick }) => (
  <button type="button" className="modal-close" onClick={ onClick }>
    <Icon glyph="cross-thin" />
  </button>
);

ModalClose.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ModalClose;

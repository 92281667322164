import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';

import { openModalPay, closeModal, userDelete, logout, payment } from 'common/actions';

import { LayoutContext } from 'document/Layout';

import Modal from 'components/Modal';
import FormButton from 'components/FormButton';
import Preloader from 'components/Preloader';

import './style.css';
import giftImage from './images/gift.svg';

class ModalEntryFee extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.pay = this.pay.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { userDeleteRequestState } = this.props;

    if (prevProps.userDeleteRequestState.request && userDeleteRequestState.success) {
      this.logout();
    }
  }

  pay() {
    const { openModalPay, closeModal, recipientId } = this.props;
    const { prices, payment } = this.props;

    const backUrl = `${window.location.pathname}${window.location.search}`;
    const body = {
      payment_system_id: 4, //банковская карта
      payment_type_id: prices.fee.typeId,
      entity_id: prices.fee.price.defaultValue,
      to_user_id: recipientId || 0,
    };

    payment(body, backUrl);

    // второй экран с выбором способа оплаты
    // сейчас не актуален
    // openModalPay({
    //   recipientId,
    //   handleClose: closeModal,
    //   goBack: closeModal,
    //   mode: 'fee',
    // });
  }

  logout() {
    const { history, logout, recipientId, handleClose } = this.props;

    handleClose();
    logout(recipientId, true);
    history.push('/');
  }

  render() {
    const { isOpen, isPayment, prices, recipientId, userDeleteRequestState, userDelete } = this.props;

    return (
      <Modal isOpen={ !isPayment && isOpen }>
        <div className="modal-entry-fee">
          { isEmpty(prices) || !recipientId
            ? <Preloader isPageLoader />
            : <React.Fragment>
                <h2>Финальный шаг</h2>
                <div className="modal-entry-fee__image">
                  <img src={ giftImage } width="167" height="95" alt="" />
                </div>
                <div className="text">
                  <p>Как и&nbsp;на&nbsp;любом другом сайте знакомств, служба поддержки LinkYou ежедневно реагирует на&nbsp;сотни жалоб, связанных с&nbsp;оскорблениями, предложениями интима, разными мошенническими схемами и&nbsp;спамом. И&nbsp;сегодня мы&nbsp;с&nbsp;уверенностью можем заявить&nbsp;&mdash; на&nbsp;LinkYou эта проблема решена. Мы&nbsp;оградили Вас от&nbsp;всего негатива, создав комфортные условия для уважающих себя людей, которые достойны лучшего. </p>
                  <p>Кого Вы&nbsp;не&nbsp;найдете на&nbsp;LinkYou: Мошенников, проституции, интернет-троллей, спаммеров и&nbsp;ботов</p>
                  <p>За&nbsp;счет чего это стало возможным? Все просто. На&nbsp;LinkYou действует разовый членский взнос ({ prices.fee.price.amount }&nbsp;рублей), который позволяет нам отсеивать весь негатив и&nbsp;оставлять для Вас общение только с&nbsp;живыми людьми, с&nbsp;серьезными намерениями.</p>
                  <p>
                  Членский взнос включает в&nbsp;себя отличный подарок&nbsp;&mdash; стартовый пакет продвижения вашей анкеты: <br />
                  <span className="text-green">4&nbsp;бесплатных поднятия анкеты в&nbsp;поиске (<s>{ prices.up.price.amount * 4 }&nbsp;руб.</s> &mdash;&nbsp;бесплатно).</span> <br />
                  <span className="text-green">10&nbsp;виртуальных подарков (<s>{ prices.gift.price.amount * 10 }&nbsp;руб.</s> &mdash;&nbsp;бесплатно).</span>
                  </p>
                  <p>Если Вы&nbsp;настроены серьезно&nbsp;&mdash; добро пожаловать! Начните реальные знакомства прямо сейчас.</p>
                </div>
                <div className="modal-entry-fee__options">
                  <div className="modal-entry-fee__options-item">
                    <FormButton skin={ ['size-m', 'green'] } onClick={ this.pay }>
                      Да, согласен
                    </FormButton>
                    <p className="text-green">{ prices.fee.price.amount } рублей (пожизненный <br />разовый членский взнос)</p>
                  </div>
                  <div className="modal-entry-fee__options-item">
                    <FormButton skin={ ['size-m', 'red'] } onClick={ userDelete } isRequest={ userDeleteRequestState.request }>
                      Н<span className="hidden-b-m">ет, н</span>е интересно
                    </FormButton>
                    <p className="text-red">Уйти с сайта и удалить анкету</p>
                  </div>
                </div>
              </React.Fragment>
          }
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    prices: state.prices,
    isPayment: state.modal.type === 'MODAL_PAY',
    recipientId: (state.session || {}).user || 0,
    userDeleteRequestState: state.states.userDelete || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    logout,
    openModalPay,
    closeModal,
    userDelete,
    payment,
  }, dispatch);
}

const Consumer = props => (
  <LayoutContext.Consumer>
    { layoutContext => <ModalEntryFee { ...props } history={ layoutContext.history } /> }
  </LayoutContext.Consumer>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Consumer);

import moment from 'moment';
require('moment/locale/ru');
import qs from 'query-string';
import unionWith from 'lodash/unionWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import pull from 'lodash/pull';
import { matchPath } from "react-router-dom"

moment.locale('ru');
moment.updateLocale('ru', {
  monthsShort : 'янв_фев_мар_апр_мая_июн_июл_авг_сен_окт_ноя_дек'.split('_'),
});

const MOBILE_OFFSET = 767;
const TABLET_OFFSET = 1023;
const DESKTOP_OFFSET = 1199;

export const ANIMATION_SPEED = 150;

export const LIST_LIMIT = 24;

export const ACTIVE_USER_SCORE = 76;

export const COOKIE_PARAMS = { path: '/', maxAge: 60 * 60 * 24 * 30 * 365 };

export const REGEX = {
  YOUTUBE: /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\/?\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/i,
  YANDEX_MUSIC: /(?:music\.yandex\.ru.*\/(track|album|artist|.+(?=\/playlists)).*\/(\d+))/i,
};

export const LS_KEYS = {
  SESSION: 'SESSION',
  AUTOSAVE: 'AUTOSAVE',
  CITY: 'CITY',
  TIPS: 'TIPS',
  BACK_URL: 'BACK_URL',
  EDIT: 'EDIT',
  MIGRATED: 'MIGRATED',
};

export const isSSR = () => {
  return typeof window === 'undefined';
};

// Because https://github.com/jaredpalmer/razzle/pull/1052
export const shouldUseDevAPI = () => {
  return process.env.API === 'development' ||
    (
      !isSSR() &&
      window.location.hostname !== 'linkyou.ru' &&
      window.location.hostname !== 'node.linkyou.ru'
    );
};

export const isObject = instance => {
  return Object.prototype.toString.call(instance) === '[object Object]';
};

export const getLSKey = (key, ...params) => {
  const parts = ['linkyou'];
  const keyLowerCase = key.toLowerCase();

  switch (key) {
    case LS_KEYS.SESSION:
    case LS_KEYS.CITY:
    case LS_KEYS.TIPS:
    case LS_KEYS.BACK_URL:
    case LS_KEYS.EDIT:
    case LS_KEYS.MIGRATED:
      parts.push(keyLowerCase);
      break;
    case LS_KEYS.AUTOSAVE:
      parts.push(keyLowerCase, ...params);
      break;
    default:
      return '';
  }

  return parts.join('_');
};

export const generateTypes = (action) => {
  return ['REQUEST', 'SUCCESS', 'FAILURE'].map(type => `${action}_${type}`);
};

export const resolution = {
  isMobile: () => window.innerWidth <= MOBILE_OFFSET,
  isTablet: () => window.innerWidth > MOBILE_OFFSET && window.innerWidth <= TABLET_OFFSET,
  isDesktop: () => window.innerWidth > TABLET_OFFSET && window.innerWidth <= DESKTOP_OFFSET,
  isHD: () => window.innerWidth > DESKTOP_OFFSET,
};

export const declOfNum = (number, titles) => {  
  const cases = [2, 0, 1, 1, 1, 2];  
  return titles[ (number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5] ];  
};

// 1234567 => 1 234 567
export const formatNumber = int => int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const prettyCount = number => {
  if (number < 1000) {
    return number;
  } else if (number >= 1000 && number < 10000) {
    return Math.max(parseInt(number / 1000, 10), (number / 1000).toFixed(1)) + 'k';
  } else if (number >= 10000 && number < 1000000) {
    return parseInt(number / 1000, 10) + 'k';
  } else {
    return parseInt(number / 1000000, 10) + 'm';
  }
};

export const formatDate = (datetime, view) => {
  const today = moment();
  const parsedDate = moment(datetime);
  const isCurrentYear = today.isSame(parsedDate, 'year');
  let template;

  switch (view) {
    case 'day':
      if (today.isSame(parsedDate, 'day')) {
        template = 'Сегодня';
      } else if (today.subtract(1, 'day').isSame(parsedDate, 'day')) {
        template = 'Вчера';
      } else {
        template = `D MMMM${isCurrentYear ? '' : ' YYYY'}`;
      }
      break;
    case 'time':
      template = 'H:mm';
      break;
    case 'timeOrDay':
      if (today.isSame(parsedDate, 'day')) {
        template = 'H:mm';
      } else if (today.subtract(1, 'day').isSame(parsedDate, 'day')) {
        template = 'Вчера';
      } else if (today.subtract(2, 'day').isSame(parsedDate, 'day')) {
        template = 'Позавчера';
      } else {
        template = `D[&nbsp;]MMM${isCurrentYear ? '' : ', YYYY'}`;
      }
      break;
    case 'shortDate':
      template = `D MMM${isCurrentYear ? '' : ' YYYY'}, H:mm`;
      break;
    default:
      template = `D MMMM${isCurrentYear ? '' : ' YYYY'}, H:mm`;
      break;
  };

  return parsedDate.format(template);
};

export const datetime = () => moment().format('YYYY-MM-DD HH:mm:ss');

export const parsePagination = (query = '') => {
  const defaults = {
    page: 1,
    infinite: false,
  };
  const parsedParams = typeof query === 'string'
    ? qs.parse(query)
    : query;
  const data = isObject(parsedParams)
    ? parsedParams
    : defaults;
  let {
    infinite,
    page,
  } = data;

  page = parseInt(page, 10);

  if (isNaN(page) || page < 1) {
    page = 1;
  }

  return {
    page,
    pageIndex: page - 1,
    infinite: Boolean(parseInt(infinite, 10)),
  };
};

export const withParams = (endpoint, params = {}) => {
  const result = [endpoint];
  const stringParams = qs.stringify(params);

  if (stringParams) {
    result.push(stringParams);
  }

  return result.join('?');
};

export const getEntitiesFromPagination = (paginationData = [], location = {}) => {
  const {
    search,
    state,
  } = location;
  const {
    pageIndex,
    page,
  } = parsePagination(search);

  const result = state && state.infinite
    ? unionWith(...paginationData.slice(state.startIndex, page), isEqual)
    : paginationData[pageIndex];

  return result || [];
};

export const getUser = (state = {}, userId = 0) => {
  if (!state.entities || !userId) {
    return {};
  }

  const {
    entities: {
      users,
      usersCompact,
    },
  } = state;

  return (((users || {})[userId]) || ((usersCompact || {})[userId])) || {};
};

export const numberify = object => Object.keys(object).reduce((result, param) => {
  result[param] = parseInt(object[param], 10);
  return result;
}, {});

export const normalizeValue = value => /^[0-9]+$/.test(value) ? parseInt(value, 10) : value;

export const getUserName = (user, isAnonymous, isSelf) => {
  const { roleStatus } = user;
  let name = user.name;

  if (!name && isSelf) {
    name = 'Имя';
  }

  if (isAnonymous) {
    name = 'Аноним';
  } else if (roleStatus === 'deleted') {
    name = 'Анкета удалена';
  }

  return name;
};

export const makeSearchLink = (currentUser) => {
  const ageFrom = (currentUser.age && currentUser.age.from) || 18;
  const ageTo = (currentUser.age && currentUser.age.to) || 75;
  const lookingFor = (currentUser.lookingFor && currentUser.lookingFor.id) || 2;
  const city = (currentUser.location && currentUser.location.cityId) || 0;

  return `/search?age_from=${ageFrom}&age_to=${ageTo}&city=${city}&looking_for=${lookingFor}`;
};

export const makeHomeLink = currentUser => isEmpty(currentUser) ? '/' : '/welcome';

export const getUserScore = (user) => {
  // Find formula here: https://trello.com/c/QB5uplJN
  const completedFields = {};
  const missedFields = ['avatar', 'name', 'birthday', 'location', 'gender', 'job', 'lookingFor', 'age', 'goal', 'educationType', 'institution', 'height', 'weight', 'relationship', 'orientation', 'children', 'smoking', 'alcohol', 'music', 'interests', 'books'];
  let score = 0;

  function increase(value, key) {
    score += value;
    completedFields[key] = value;
    pull(missedFields, key);
  }

  function isEducationTypeFilled(education) {
    let isFilled = false;

    education.forEach(entry => {
      if (isFilled) return;
      if (entry.educationType && entry.educationType.id) {
        isFilled = true;
      }
    });

    return isFilled;
  }

  function isInstitutionFilled(education) {
    let isFilled = false;

    education.forEach(entry => {
      if (isFilled) return;
      if (entry.institution && entry.institution.name) {
        isFilled = true;
      }
    });

    return isFilled;
  }

  if (!user || isEmpty(user)) {
    return 0;
  }

  if (user.avatar.id) {
    increase(5, 'avatar');
  }
  if (user.name) {
    increase(5, 'name');
  }
  if (!isEmpty(user.birthday)) {
    increase(15, 'birthday');
  }
  if (user.location.cityId) {
    increase(10, 'location');
  }
  if (user.gender && user.gender.id) {
    increase(5, 'gender');
  }
  if (user.job && user.job.professionId) {
    increase(25, 'job');
  }
  if (user.lookingFor) {
    increase(3, 'lookingFor');
  }
  if (user.age && user.age.from && user.age.to) {
    increase(4, 'age');
  }
  if (user.goal) {
    increase(4, 'goal');
  }
  if (user.education && user.education.length) {
    if (isEducationTypeFilled(user.education)) {
      increase(2, 'educationType');
    }
    if (isInstitutionFilled(user.education)) {
      increase(5, 'institution');
    }
  }
  if (user.height) {
    increase(2, 'height');
  }
  if (user.weight) {
    increase(2, 'weight');
  }
  if (user.relationship && user.relationship.id) {
    increase(3, 'relationship');
  }
  if (user.orientation && user.orientation.id) {
    increase(1, 'orientation');
  }
  if (user.children && user.children.id) {
    increase(1, 'children');
  }
  if (user.smoking && user.smoking.id) {
    increase(1, 'smoking');
  }
  if (user.alcohol && user.alcohol.id) {
    increase(1, 'alcohol');
  }
  if (user.music && user.music.length) {
    increase(1, 'music');
  }
  if (user.interests && user.interests.interests && user.interests.interests.length) {
    increase(3, 'interests');
  }
  if (user.books && user.books.length && user.books[0].name && user.books[0].author) {
    increase(2, 'books');
  }

  // wanna some debug?
  // console.log(score, completedFields, missedFields);

  return score;
};

export const isUserDisabled = (user = {}) => {
  return user.role === 1 || user.roleStatus === 'disabled';
};

export const playSound = (file) => {
  const availableFiles = ['sendMessage', 'newMessage'];

  if (!file || availableFiles.indexOf(file) === -1) {
    return;
  }

  const player = new Audio();
  player.src = `/sounds/${file}.mp3`;
  player.volume = 1;
  player.play();
};

export const getBirthdayObj = (date) => {
  const dateParsed = moment(date);

  if (!date || !dateParsed.isValid()) {
    return {};
  }

  return {
    day: dateParsed.date(),
    month: dateParsed.month() + 1,
    year: dateParsed.year(),
  };
}

export const getAssests = ({ req, routes, manifest }) => {
  let scripts = [];
  let styles = [];
  const startQueryIndex = req.url.indexOf('?');
  const pathname = startQueryIndex !== -1 ? req.url.slice(0, startQueryIndex) : req.url;
  const match = routes.find(route => {
    return !!matchPath(pathname, route)
  })
  
  if (match) {
    const route = routes.find(item => item.path === match.path);
    
    if (!route) return { scripts, styles };

    if (!route.name && process.env.NODE_ENV !== "production") {
      throw new Error(
        `routes must have a "name" key with value of chunk name ${JSON.stringify(
          { name: "ChunkName", ...route },
          null,
          2
        )}`
      )
    }

    if (route.name) {
      const { name: chunkName } = route;

      if (manifest[chunkName] && manifest[chunkName].js) {
        scripts = manifest[chunkName].js.filter(path => path.endsWith('.js'));
      }

      if (manifest[chunkName] && manifest[chunkName].css) {
        styles = manifest[chunkName].css.filter(path => path.endsWith('.css'))
      }
    }
  }

  return { scripts, styles }
}

//polyfill
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i);
    while (i--) {
      resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }

    return resArray;
  };
}
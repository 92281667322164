import { LIST_LIMIT, generateTypes, withParams } from 'common/helpers';
import { CALL_API, Schemas } from 'common/middleware/api';

export const imDialogsLoad = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('IM_DIALOGS'),
    method: 'get',
    endpoint: withParams('dialogs', {
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.IM_DIALOGS,
  }
});

export const imChatLoad = (id, params = {}) => ({
  [CALL_API]: {
    types: generateTypes('IM_MESSAGES'),
    method: 'get',
    endpoint: withParams(`dialog/${id}`, {
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.IM_MESSAGES,
  }
});

export const imDialogCreate = (userId) => ({
  [CALL_API]: {
    body: {
      user_id: userId,
    },
    types: generateTypes('IM_DIALOG_CREATE'),
    method: 'post',
    endpoint: 'dialog/new',
    schema: Schemas.IM_DIALOG,
  }
});

export const imDialogDetect = (userId) => ({
  [CALL_API]: {
    body: {
      user_id: userId,
    },
    payload: {
      userId,
    },
    types: generateTypes('IM_DIALOG_DETECT'),
    method: 'post',
    endpoint: 'dialog/detect',
  }
});

export const imDialogDelete = (dialogId) => ({
  [CALL_API]: {
    payload: {
      dialogId,
    },
    types: generateTypes('IM_DIALOG_DELETE'),
    method: 'post',
    endpoint: `dialog/${dialogId}/delete`,
  }
});

export const imDialogRead = (dialogId) => ({
  [CALL_API]: {
    types: generateTypes('IM_DIALOG_READ'),
    method: 'post',
    endpoint: `dialog/${dialogId}/read`,
  }
});

export const imMessagesDelete = (id = []) => ({
  [CALL_API]: {
    body: {
      'id[]': id,
    },
    payload: {
      id,
    },
    types: generateTypes('IM_MESSAGES_DELETE'),
    method: 'post',
    endpoint: 'message/delete',
  }
});

export const imMessageGet = ({ messageId, dialogId }) => ({
  [CALL_API]: {
    payload: {
      messageId,
      dialogId,
    },
    types: generateTypes('IM_MESSAGE_GET'),
    method: 'get',
    endpoint: `message/${messageId}`,
    schema: Schemas.IM_MESSAGE,
  }
});

const addRemoteMessage = (body, tempId) => ({
  [CALL_API]: {
    body,
    payload: {
      tempId,
    },
    types: generateTypes('IM_MESSAGE_ADD_REMOTE'),
    method: 'post',
    endpoint: 'message/send',
    schema: Schemas.IM_MESSAGE,
  }
});

const addLocalMessage = (response) => ({
  response,
  type: 'IM_MESSAGE_ADD_LOCAL',
});

export const imMessageAdd = (userId, message, localResponse) => dispatch => {
  dispatch(addLocalMessage(localResponse));
  return dispatch(addRemoteMessage({
    user_id: userId,
    content: message.comment,
    'pictures[]': message.photos,
  }, localResponse.result));
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { payment } from 'common/actions';
import { normalizeValue } from 'common/helpers';

import Modal from 'components/Modal';
import FormButton from 'components/FormButton';
import Preloader from 'components/Preloader';
import Priceline from 'components/Priceline';
import FormRadio from 'components/FormRadio';
import Link from 'components/Link';

import './style.css';
import alfabankImage from './images/alfabank.svg';
import beelineImage from './images/beeline.svg';
import cardImage from './images/card.svg';
import psbImage from './images/psb.svg';
import mtsImage from './images/mts.svg';
import robokassaImage from './images/robokassa.svg';
import sberbankImage from './images/sberbank.svg';
import tele2Image from './images/tele2.svg';
import webmoneyImage from './images/webmoney.svg';
import yandexImage from './images/yandex.svg';

const METHODS = {
  name: 'paymentSystemName',
  mobile: [
    { value: 'MixplatMTSRIBR', icon: { src: mtsImage, width: 37, height: 18 }, label: 'МТС' },
    { value: 'MixplatBeelineRIBR', icon: { src: beelineImage, width: 39, height: 28 }, label: 'Beeline' },
    { value: 'MixplatTele2RIBR', icon: { src: tele2Image, width: 37, height: 14 }, label: 'Tele2' },
  ],
  robokassa: [
    { value: 'BankCard', icon: { src: cardImage, width: 40, height: 40 }, label: 'Банковские карты' },
    { value: 'YandexMoney', icon: { src: yandexImage, width: 29, height: 38 }, label: 'Яндекс.Деньги' },
    { value: 'WMR', icon: { src: webmoneyImage, width: 39, height: 40 }, label: 'Webmoney (WMR)' },
    { value: 'BankSberBank', icon: { src: sberbankImage, width: 39, height: 37 }, label: 'Сбербанк: оплата по SMS или Сбербанк Онлайн' },
    { value: 'AlfaBank', icon: { src: alfabankImage, width: 23, height: 36 }, label: 'Альфа-клик' },
    { value: 'W1RIBPSBR', icon: { src: psbImage, width: 39, height: 39 }, label: 'Интернет-банк Промсвязьбанка PSB-Retail' },
  ],
};

class ModalPay extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    goBack: PropTypes.func,
    updateEntityId: PropTypes.func,
    recipientId: PropTypes.number,
    entityId: PropTypes.number,
    mode: PropTypes.oneOf([
      'vip',
      'premium',
      'interesting',
      'invisible',
      'up',
      'gift',
      'fee',
    ]).isRequired,
  }

  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.proceed = this.proceed.bind(this);
    this.renderMethod = this.renderMethod.bind(this);
    this.updateModel = this.updateModel.bind(this);

    this.state = {
      isSent: false,
      model: {
        entityId: props.entityId || get(props, 'data.price.defaultValue') || 0,
        toUserId: props.recipientId || 0,
        paymentSystemName: '',
        paymentSystemId: 0,
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.entityId) {
      return null;
    }

    return {
      model: {
        ...state.model,
        entityId: props.entityId,
        toUserId: props.recipientId || 0,
      },
    };
  }

  updateModel(e) {
    const { updateEntityId } = this.props;
    const input = e.target;
    const { name } = input;
    const value = normalizeValue(input.value);

    if (name === 'entityId' && typeof updateEntityId === 'function') {
      updateEntityId(e);
    }

    this.setState(state => {
      const model = {
        ...state.model,
        [name]: value,
      };

      if (name === 'paymentSystemName') {
        model.paymentSystemId = parseInt(input.dataset.paymentSystemId, 10);
      }

      return {
        model,
      };
    });
  }

  proceed() {
    const {
      paymentSystemId,
      entityId,
      toUserId,
    } = this.state.model;
    const {
      payment,
      data,
    } = this.props;

    const backUrl = `${window.location.pathname}${window.location.search}`;
    const body = {
      payment_system_id: paymentSystemId || 4,
      payment_type_id: data.typeId,
    };

    if (entityId) {
      body.entity_id = entityId;
    }
    if (toUserId > 0) {
      body.to_user_id = toUserId;
    }

    this.setState({
      isSent: true,
    });

    payment(body, backUrl);
  }

  close() {
    this.setState(state => ({
      isSent: false,
      model: {
        ...state.model,
        toUserId: 0,
        paymentSystemName: '',
        paymentSystemId: 0,
      }
    }), this.props.handleClose);
  }

  renderMethod(method, key) {
    const {
      model: {
        paymentSystemName,
      },
    } = this.state;

    return (
      <div className="modal-pay__option" key={ key }>
        <label className="modal-pay__label">
          <FormRadio
            name={ METHODS.name }
            value={ method.value }
            checked={ paymentSystemName === method.value }
            changeValue={ this.updateModel }
            data-payment-system-id="4"
          />
          <span className="modal-pay__label-icon">
            <img src={ method.icon.src } width={ method.icon.width } height={ method.icon.height } alt={ method.label } />
          </span>
          <span className="modal-pay__label-value">
            { method.label }
          </span>
        </label>
      </div>
    );
  }

  render() {
    const { goBack, isOpen, requestState, data } = this.props;
    const {
      isSent,
      model: {
        entityId,
        paymentSystemName,
      },
    } = this.state;

    const enableFakeBank = process.env.PORT === '4000' || process.env.NODE_ENV !== 'production';

    return (
      <Modal isOpen={ isOpen } handleClose={ this.close }>
        <div className="modal-pay">
          { isEmpty(data)
            ? <Preloader isPageLoader />
            : <React.Fragment>
                <div className="modal-pay__heading">
                  <h2>{ data.heading }</h2>
                  { !!data.subheading &&
                    <p>{ data.subheading }</p>
                  }
                  { !!goBack && !!data.backlink &&
                    <button type="button" onClick={ goBack }>{ data.backlink }</button>
                  }
                </div>
                { !!data.price.data &&
                  <Priceline
                    data={ data.price.data }
                    selected={ entityId }
                    selectOption={ this.updateModel }
                  />
                }
                {/* <div className="modal-pay__mobile">
                  <div className="modal-pay__mobile-legend">Выберите удобный способ оплаты:</div>
                  { enableFakeBank &&
                    <div className="modal-pay__mobile-fieldset">
                      <div className="modal-pay__option">
                        <label className="modal-pay__label">
                          <FormRadio
                            name="paymentSystemName"
                            value="FakeBank"
                            checked={ paymentSystemName === 'FakeBank' }
                            changeValue={ this.updateModel }
                            data-payment-system-id="1"
                          />
                          <span className="modal-pay__label-value">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fake Bank
                          </span>
                        </label>
                      </div>
                    </div>
                  }
                  <div className="modal-pay__mobile-fieldset">
                    { METHODS.mobile.map(this.renderMethod) }
                  </div>
                </div>
                <div className="modal-pay__robokassa">
                  <div className="modal-pay__robokassa-logo">
                    <img src={ robokassaImage } width="154" height="18" alt="Robokassa" />
                  </div>
                  <div className="modal-pay__robokassa-fieldset">
                    { METHODS.robokassa.map(this.renderMethod) }
                  </div>
                </div> */}
                <div className="modal-pay__submit">
                  <FormButton skin={ ['darkblue', 'size-m'] } onClick={ this.proceed } isRequest={ requestState.request }>
                    <span className="hidden-b-m">Перейти к оплате</span>
                    <span className="hidden-a-m">К оплате</span>
                  </FormButton>
                  { !!requestState.error && isSent &&
                    <p className="text-red">
                      <b>{ requestState.error.message }</b>
                    </p>
                  }
                  <p>
                    Переходя к оплате вы соглашаетесь <br className="hidden-a-m" />с <Link to="/rules" onClick={ this.close }>правилами оказания услуги</Link>
                  </p>
                </div>
              </React.Fragment>
          }
        </div>
      </Modal>
    );
  }
};

function mapStateToProps(state, ownProps) {
  return {
    data: state.prices[ownProps.mode] || {},
    requestState: state.states.payment || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    payment,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalPay);

import Cookies from 'js-cookie';

import { isSSR, generateTypes, getLSKey, LS_KEYS } from 'common/helpers';
import { CALL_API, Schemas, revokeToken } from 'common/middleware/api';
import { disconnectSockets, sendPacket, USER_OFFLINE } from 'common/middleware/socketio';

export const logout = (userId, completely = false) => {
  const cookieParams = { path: '/' };

  sendPacket(USER_OFFLINE, {
    userId,
  });

  revokeToken();
  disconnectSockets();
  Cookies.remove(getLSKey(LS_KEYS.SESSION), cookieParams);

  if (completely && !isSSR()) {
    if ('localStorage' in window) {
      localStorage.clear();
    }

    if (navigator.cookieEnabled) {
      Object.keys(Cookies.get()).forEach(cookieName => {
        Cookies.remove(cookieName, cookieParams);
      });
    }
  }

  return {
    type: 'LOGOUT',
    payload: {
      userId,
    },
  };
};

export const resumeSession = (session) => ({
  type: 'RESUME_SESSION',
  payload: session,
});

export const loadCurrentUser = () => ({
  [CALL_API]: {
    types: generateTypes('CURRENT_USER'),
    method: 'get',
    endpoint: 'user/current',
    schema: Schemas.USER,
  }
});

export const signIn = (body) => ({
  [CALL_API]: {
    body,
    types: generateTypes('SIGN_IN'),
    method: 'post',
    endpoint: 'auth/signin',
    schema: Schemas.AUTH,
  }
});

export const signUp = (body) => ({
  [CALL_API]: {
    body,
    types: generateTypes('SIGN_UP'),
    method: 'post',
    endpoint: 'auth/signup',
    schema: Schemas.AUTH,
  }
});

// Метод смены почты юзера с ролью >= 10
// Для смены почты надо перейти по ссылке
export const authEmailChange = (body) => ({
  [CALL_API]: {
    body,
    payload: {
      email: body.email,
    },
    types: generateTypes('AUTH_EMAIL_CHANGE'),
    method: 'post',
    endpoint: 'auth/email',
  }
});

// Подтверждение почты юзера с ролью >= 10 по коду из ссылки
export const authEmailCode = (code) => ({
  [CALL_API]: {
    body: {
      code,
    },
    types: generateTypes('AUTH_EMAIL_CODE'),
    method: 'post',
    endpoint: 'auth/email/code',
  }
});

// Метод смены почты юзера с ролью < 10
// Неподтвержденная почта меняется без ссылки
export const authEmailConfirm = (body, userId) => ({
  [CALL_API]: {
    body,
    payload: {
      userId,
      email: body.email,
    },
    types: generateTypes('AUTH_EMAIL_CONFIRM'),
    method: 'post',
    endpoint: 'auth/confirm/email',
  }
});

// Подтверждение почты юзера с ролью < 10 по коду из ссылки
export const authConfirm = (code) => ({
  [CALL_API]: {
    body: {
      code,
    },
    types: generateTypes('AUTH_CONFIRM'),
    method: 'post',
    endpoint: 'auth/confirm/code',
  }
});

// Смена пароля в настройках
export const authPasswordUpdate = (body) => ({
  [CALL_API]: {
    body,
    types: generateTypes('AUTH_PASSWORD_UPDATE'),
    method: 'post',
    endpoint: 'auth/password',
  }
});

// Смена пароля по коду
export const authPasswordChange = (body) => ({
  [CALL_API]: {
    body,
    types: generateTypes('AUTH_PASSWORD_CHANGE'),
    method: 'post',
    endpoint: 'auth/password/forgot',
  }
});

// Восстановление пароля
export const authPasswordForgot = (body) => ({
  [CALL_API]: {
    body,
    types: generateTypes('AUTH_PASSWORD_FORGOT'),
    method: 'post',
    endpoint: 'auth/forgot',
  }
});

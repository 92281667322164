import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Meta = ({ title, description, keywords, image }) => {
  const config = {
    meta: [],
  };

  if (title) {
    config.title = title;
    config.meta.push({
      property: 'og:title',
      content: title,
    });
  }

  if (description) {
    config.meta.push({
      name: 'description',
      content: description,
    }, {
      property: 'og:description',
      content: description,
    });
  }

  if (keywords) {
    config.meta.push({
      name: 'keywords',
      content: keywords,
    });
  }

  if (image) {
    config.meta.push({
      property: 'og:image',
      content: image,
    });
  }

  return <Helmet { ...config } />;
};

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
};

export default Meta;

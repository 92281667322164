const modals = [];

export default {
  modals: () => modals,
  add: modal => {
    if (modals.indexOf(modal) === -1) {
      modals.push(modal);
    }
  },
  remove: modal => {
    const index = modals.indexOf(modal);
    if (index !== -1) {
      modals.splice(index, 1);
    }
  },
  isTopModal: modal => !!modals.length && modals[modals.length - 1] === modal,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class SectionHeader extends Component {
  static propTypes = {
    className: PropTypes.string,
  }

  render() {
    return (
      <div className={classNames('section__header', this.props.className )}>
        { this.props.children }
      </div>
    );
  }
};

export default SectionHeader;

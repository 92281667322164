import { createSelectorCreator } from 'reselect'
import isEqual from 'lodash/isEqual';
import memoize from 'memoize-one';

const createDataSelector = createSelectorCreator(memoize, (a, b) => {
  if (Array.isArray(a)) {
    return isEqual(a, b);
  }

  return true;
});

export const selectSympathiesNew = createDataSelector(
  (state) => state.data.userSympathiesNew,
  (state) => state.entities.usersCompact,
  (userSympathiesNew, usersCompact) => (userSympathiesNew || []).map(id => usersCompact[id]),
);

export const selectGiftsPaid = createDataSelector(
  (state) => state.data.giftsPaid,
  (state, currentUser) => currentUser,
  (data, currentUser) => (data || []).filter(gift => parseInt(gift.toUserId, 10) !== currentUser.id),
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { formatDate } from 'common/helpers';

import Icon from 'components/Icon';
import Link from 'components/Link';
import PhotoGrid from 'components/PhotoGrid';
import UserAvatar from 'components/UserAvatar';

import './style.css';

class CommentsItem extends Component {
  static propTypes = {
    allowSelect: PropTypes.bool,
    selected: PropTypes.bool,
    isFailed: PropTypes.bool,
    appearance: PropTypes.string,
    data: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    entityId: PropTypes.number.isRequired,
    entityUser: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    deleteCommentState: PropTypes.object,
  }

  static defaultProps = {
    data: {},
    user: {},
    entityUser: {},
    currentUser: {},
  }

  constructor(props) {
    super(props);
    this.select = this.select.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
  }

  select(e) {
    const { onSelect, data } = this.props;

    if (e.target.closest('.comments-item') && e.target.tagName.toUpperCase() !== 'BUTTON') {
      onSelect(data.id);
    }
  }

  deleteComment(e) {
    const { onDelete, entityId, data } = this.props;

    e.stopPropagation();
    onDelete(entityId, data.id);
  }

  renderBody() {
    const { data, user } = this.props;
    const text = data.comment;

    if (data.pictures && data.pictures[0] && data.pictures[0].search('heart_smile') !== -1) {
      return (
        <div className="comments-item__sticker">
          <img src={ data.pictures[0] } alt="" />
        </div>
      );
    }

    const photos = (data.pictures || []).reduce((result, src, index) => {
      const directory = src.match(/\/([0-9]+)\//);

      if (!directory) {
        return result;
      }

      const id = parseInt(directory[1] + index, 10);

      result.push({ src, id });
      return result;
    }, []);

    return (
      <>
        {Boolean(user && user.name) &&
          <Link className="comments-item__user-name" to={`/user/${user.id}`}>
            {user.name}
          </Link>
        }
        <div className="comments-item__text">
          <span dangerouslySetInnerHTML={{ __html: text }} />
          { !!photos.length &&
            <PhotoGrid photos={ photos } />
          }
        </div>
      </>
    );
  }

  render() {
    const {
      appearance,
      data,
      user,
      entityUser,
      currentUser,
      deleteCommentState,
      selected,
      isFailed,
      hasSendStatus,
    } = this.props;

    const isDeleting =
      deleteCommentState &&
      deleteCommentState.request &&
      deleteCommentState.payload &&
      deleteCommentState.payload.commentId === data.id;

    const isReadOnly = (currentUser.role || 0) < 10;
    const isSelf = currentUser.id === user.id;
    const allowDelete = !isReadOnly && (isSelf || entityUser.id === currentUser.id);

    return (
      <div
        className={classNames(
          'comments-item',
          `-appearance-${appearance}`,
          { '-self': isSelf },
          { '-selected': selected },
          { '-deleting': isDeleting },
          { '-failed': isFailed },
        )}
        onClick={ isReadOnly ? noop : this.select }
      >
        <UserAvatar skin="circle" size="medium" user={ user } isSelf={ isSelf } withLink />
        <div className="comments-item__body">
          { this.renderBody() }
          { isFailed &&
            <div className="comments-item__status -error">Ошибка отправки</div>
          }
          { hasSendStatus && !isFailed && !data.read &&
            <div className="comments-item__status -sent">Доставлено</div>
          }
          { hasSendStatus && !isFailed && data.read &&
            <div className="comments-item__status -read">Прочитано</div>
          }
        </div>
        <div className="comments-item__date">
          <span
            title={ formatDate(data.datetime) }
            dangerouslySetInnerHTML={{ __html: formatDate(data.datetime, appearance === 'chat' ? 'time' : 'timeOrDay') }}
          />
        </div>
        { allowDelete &&
          <button type="button" className="comments-item__delete" onClick={ this.deleteComment }>
            <Icon glyph="cross-thin" />
          </button>
        }
      </div>
    );
  }
}

export default CommentsItem;

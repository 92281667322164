import React from 'react';
import classNames from 'classnames';

import './style.css';

const Preloader = ({ isPageLoader }) => (
  <span className={classNames(
    'preloader',
    { '-page-loader': isPageLoader }
  )}>
    <i /><i /><i />
  </span>
);

export default Preloader;

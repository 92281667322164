import React from 'react';
import PropTypes from 'prop-types';

import { getUserName, isUserDisabled } from 'common/helpers';

import Link from 'components/Link';

import './style.css';

const UserName = ({ anonymous, user, withAge, withLink, isSelf }) => {
  const TagName = withLink ? Link : 'span';
  let age = '';

  if (
    withAge &&
    !anonymous &&
    user.roleStatus !== 'deleted' &&
    user.birthday &&
    user.birthday.age
  ) {
    age = `, ${user.birthday.age}`;
  }

  if (!age && isSelf && isUserDisabled(user)) {
    age = ', Возраст'
  }

  return (
    <TagName className="user-name" to={ withLink ? `/user/${user.id}` : null }>
      { getUserName(user, anonymous, isSelf) }{ age }
    </TagName>
  );
};

UserName.propTypes = {
  user: PropTypes.object.isRequired,
  isSelf: PropTypes.bool,
  withAge: PropTypes.bool,
  withLink: PropTypes.bool,
  anonymous: PropTypes.bool,
};

export default UserName;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openModalPay, closeModal } from 'common/actions';

import FormButton from 'components/FormButton';
import Modal from 'components/Modal';

import selectionImage from './images/selection.svg';
import showUpImage from './images/show-up.svg';
import './style.css';

const DATA = [
  { image: selectionImage, value: 'Ваша анкета будет видна только тем, с кем вы захотите познакомиться.' },
  { image: showUpImage, value: 'Чтобы показать себя понравившемуся вам человеку, вам достаточно будет написать ему или поставить лайк.' },
];

class ModalGoInvisible extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.pay = this.pay.bind(this);
    this.closeAll = this.closeAll.bind(this);
  }

  pay() {
    const { openModalPay, closeModal } = this.props;

    openModalPay({
      handleClose: this.closeAll,
      goBack: closeModal,
      mode: 'invisible',
    });
  }

  closeAll() {
    this.props.closeModal();
    this.props.handleClose();
  }

  render() {
    const { isOpen, handleClose, isPayment } = this.props;

    return (
      <Modal isOpen={ !isPayment && isOpen } handleClose={ handleClose }>
        <div className="modal-go-invisible">
          <h2>Хотите пользоваться LinkYou анонимно?</h2>
          <div className="modal-go-invisible__about">
            Режим невидимости позволит вам оставаться инкогнито и&nbsp;знакомиться приватно.
          </div>
          <div className="modal-go-invisible__benefits">
            {
              DATA.map((item, key) => (
                <div className="modal-go-invisible__benefits-item" key={ key }>
                  <div className="modal-go-invisible__benefits-item-preview">
                    <img src={ item.image } alt="" />
                  </div>
                  <div className="modal-go-invisible__benefits-item-text">
                    { item.value }
                  </div>
                </div>
              ))
            }
          </div>
          <div className="modal-go-invisible__pay">
            <FormButton skin={ ['darkblue', 'size-m'] } onClick={ this.pay }>
              Активировать <span className="hidden-b-m"> невидимость</span>
            </FormButton>
          </div>
        </div>
      </Modal>
    );
  }
};

function mapStateToProps(state) {
  return {
    isPayment: state.modal.type === 'MODAL_PAY',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModalPay,
    closeModal,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalGoInvisible);

import { createSelectorCreator } from 'reselect';
import isEqual from 'lodash/isEqual';
import memoize from 'memoize-one';

const createDataSelector = createSelectorCreator(memoize, (a, b) => {
  if (Array.isArray(a) || typeof a === 'number') {
    return isEqual(a, b);
  }

  return true;
});

export const selectData = createDataSelector(
  (state) => (state.session || {}).user || 0,
  (state) => (state.data.usersInteresting || {}).items || [],
  (state) => state.entities.usersCompact || {},
  (currentUserId, data, entities) => data.map(id => entities[id]),
);

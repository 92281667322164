import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';

import './style.css';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Preloader from 'components/Preloader';
import { FormContext } from 'components/Form';

class FormButton extends Component {
  render() {
    let tagName;
    let buttonProps = {};

    const { children, to, href, type, disabled, skin, icon, formContext, isRequest, noop, ...rest } = this.props;
    const modifiers = Array.isArray(skin)
      ? skin.slice(0).map(m => `-${m}`).join(' ')
      : `-${skin}`;

    if (href) {
      tagName = 'a';
      buttonProps = { href };
    } else if (to) {
      tagName = to.search('#') >= 0 ? HashLink : Link;
      buttonProps = { to };
    } else {
      tagName = 'button';
      buttonProps = { type: type || 'button' };
    }

    if (disabled || formContext.isRequest || isRequest) {
      buttonProps.disabled = true;
    }

    buttonProps.className = classNames(
      'form-button',
      modifiers,
      { '-disabled': disabled },
    );

    Object.assign(buttonProps, rest);

    if (noop) {
      tagName = 'span';
      delete buttonProps.onClick;
      delete buttonProps.type;
    }

    return React.createElement(
      tagName,
      buttonProps,
      <React.Fragment>
        { !!icon &&
          <span className="form-button__icon">
            <Icon glyph={ icon } />
          </span>
        }
        { formContext.isRequest || isRequest
          ? <Preloader />
          : children && <span className="form-button__value">
              { children }
            </span>
        }
      </React.Fragment>
    );
  }
};

FormButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  skin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  formContext: PropTypes.object,
  isRequest: PropTypes.bool,
  noop: PropTypes.bool,
};

FormButton.defaultProps = {
  formContext: {},
};

export default props => (
  <FormContext.Consumer>
    { formContext => <FormButton { ...props } formContext={ formContext } /> }
  </FormContext.Consumer>
);

import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const UserJob = ({ job }) => {
  if (!job) {
    return null;
  }

  return (
    <span className="user-job">
      <span className="user-job__line" title={ job.profession }>{ job.profession }</span>
      <span className="user-job__line" title={ job.occupation }>{ job.occupation }</span>
    </span>
  );
};

UserJob.propTypes = {
  job: PropTypes.shape({
    profession: PropTypes.string,
    occupation: PropTypes.string,
  }),
};

export default UserJob;

import { asyncComponent } from '@jaredpalmer/after';
import NotFound from './scenes/NotFound';

//свойство name и динамический импорт webpackChunkName нужен для code-splitting
//name и webpackChunkName должны совпадать
export default [
  {
    name: 'Landing',
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Landing" */ './scenes/Landing'),
    }),
  },
  {
    name: 'PreviewAMP',
    path: '/amp/preview',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "PreviewAMP" */ './scenes/Landing/LandingAMP'),
    }),
  },
  {
    name: 'HomePage',
    path: '/welcome',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "HomePage" */ './scenes/Home'),
    }),
  },
  {
    name: 'Rules',
    path: '/rules',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Rules" */ './scenes/Rules'),
    }),
  },
  {
    name: 'Privacy',
    path: '/privacy',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Privacy" */ './scenes/Privacy'),
    }),
  },
  {
    name: 'Search',
    path: '/search',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Search" */ './scenes/Search'),
    }),
  },
  {
    name: 'About',
    path: '/about',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "About" */ './scenes/About'),
    }),
  },
  {
    name: 'Blog',
    path: '/blog',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Blog" */ './scenes/Blog'),
    }),
  },
  {
    name: 'BlogPost',
    path: '/blog/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "BlogPost" */ './scenes/BlogPost'),
    }),
  },
  {
    name: 'Blog',
    path: '/articles',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Blog" */ './scenes/Blog'),
    }),
  },
  {
    name: 'ArticlePost',
    path: '/articles/:name',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "ArticlePost" */ './scenes/ArticlePost'),
    }),
  },
  {
    name: 'ArticlePostAMP',
    path: '/amp/articles/:name',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "ArticlePost" */ './scenes/ArticlePost/AMP/ArticlePostAMP'),
    }),
  },
  {
    name: 'IM',
    path: '/im',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "IM" */ './scenes/IM'),
    }),
  },
  {
    name: 'Answers',
    path: '/answers',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Answers" */ './scenes/Activity'),
    }),
  },
  {
    name: 'Comments',
    path: '/comments',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Comments" */ './scenes/Activity'),
    }),
  },
  {
    name: 'Likes',
    path: '/likes',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Likes" */ './scenes/Activity'),
    }),
  },
  {
    name: 'LikesMatched',
    path: '/likes-matched',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "LikesMatched" */ './scenes/Activity'),
    }),
  },
  {
    name: 'Favorites',
    path: '/favorites',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Favorites" */ './scenes/Preferences'),
    }),
  },
  {
    name: 'Blacklist',
    path: '/blacklist',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Blacklist" */ './scenes/Preferences'),
    }),
  },
  {
    name: 'Top',
    path: '/top100',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Top" */ './scenes/Top'),
    }),
  },
  {
    name: 'Guests',
    path: '/guests',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Guests" */ './scenes/Guests'),
    }),
  },
  {
    name: 'Recommended',
    path: '/matched',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Recommended" */ './scenes/Recommended'),
    }),
  },
  {
    name: 'Settings',
    path: '/settings',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Settings" */ './scenes/Settings'),
    }),
  },
  {
    name: 'Edit',
    path: '/edit', 
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Edit" */ './scenes/Edit'),
    }),
  },
  {
    name: 'User',
    path: '/user/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "User" */ './scenes/User'),
    }),
  },
  {
    name: 'UserAMP',
    path: '/amp/user/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "UserAMP" */ './scenes/User/AMP/UserAMP'),
    }),
  },
  {
    name: 'UserBlogs',
    path: '/ublogs(/popular)?',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "UserBlogs" */ './scenes/UserBlogs'),
    }),
  },
  {
    name: 'UserBlogsTags',
    path: '/tags',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "UserBlogsTags" */ './scenes/UserBlogsTags'),
    }),
  },
  {
    name: 'UserBlogsByTag',
    path: '/tags/:tag',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "UserBlogsByTag" */ './scenes/UserBlogsByTag'),
    }),
  },
  {
    name: 'UserBlog',
    path: '/user/:userId/posts',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "UserBlog" */ './scenes/UserBlog'),
    }),
  },
  {
    name: 'UserBlogPost',
    path: '/user/:userId/posts/:postId',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "UserBlogPost" */ './scenes/UserBlogPost'),
    }),
  },
  {
    name: 'Likeyou',
    path: '/like-you',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Likeyou" */ './scenes/Likeyou'),
    }),
  },
  {
    name: 'Payment',
    path: '/payment/(success|fail)',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Payment" */ './scenes/Payment'),
    }),
  },
  {
    name: 'NotFound',
    path: '*',
    component: NotFound
  },
];

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'perfect-scrollbar';
import ReactResizeDetector from 'react-resize-detector';
import LazyLoad from 'vanilla-lazyload';

import { isSSR } from 'common/helpers';

import './style.css';

export const TRIGGER_UPDATE = 'TRIGGER_UPDATE';
export const TRIGGER_SCROLL_DOWN = 'TRIGGER_SCROLL_DOWN';
export const TRIGGER_KEEP_SCROLL_DOWN = 'TRIGGER_KEEP_SCROLL_DOWN';

class Scrollable extends Component {
  static propTypes = {
    x: PropTypes.bool,
    y: PropTypes.bool,
    onScroll: PropTypes.func,
    initialScroll: PropTypes.object,
    scrollTrigger: PropTypes.object,
  }

  static defaultProps = {
    x: false,
    y: true,
  }

  constructor(props) {
    super(props);

    this.lazyLoad = null;
    this.PS = null;
    this.scrollPosition = null;

    this.rootRef = React.createRef();

    this.updateScroll = this.updateScroll.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      initialScrollApplied: false,
    };
  }

  componentDidMount() {
    const { x, y, initialScroll, onScroll } = this.props;

    this.PS = new PerfectScrollbar(this.rootRef.current, {
      suppressScrollX: !x,
      suppressScrollY: !y,
      wheelPropagation: true,
      minScrollbarLength: 40,
    });

    if (initialScroll) {
      this.scrollToInitial();
    }

    if (!isSSR()) {
      this.lazyLoad = new LazyLoad({
        elements_selector: 'img',
        container: this.rootRef.current,
        threshold: 50,
      });
    }

    if (onScroll) {
      this.rootRef.current.addEventListener('scroll', this.handleScroll, false)
    }

    window.addEventListener('resize', this.updateScroll, false);
  }

  componentDidUpdate(prevProps) {
    const { scrollTrigger } = this.props;

    if (!this.state.initialScrollApplied) {
      this.scrollToInitial();
    }

    if (scrollTrigger && scrollTrigger.timestamp !== prevProps.scrollTrigger.timestamp) {
      switch (scrollTrigger.action) {
        case TRIGGER_UPDATE:
          return this.updateScroll();
        case TRIGGER_SCROLL_DOWN:
          return this.scrollDown();
        default:
          break;
      }
    }
  }

  componentWillUnmount() {
    if (this.props.onScroll) {
      this.rootRef.current.removeEventListener('scroll', this.handleScroll, false)
    }

    if (!isSSR() && this.lazyLoad) {
      this.lazyLoad.destroy();
    }

    window.removeEventListener('resize', this.updateScroll, false);
  }

  scrollToInitial() {
    const { initialScroll } = this.props;
    const { initialScrollApplied } = this.state;

    if (!initialScroll || initialScrollApplied) {
      return;
    }

    if (initialScroll.x && this.rootRef.current.clientWidth) {
      this.rootRef.current.scrollLeft = initialScroll.x;

      this.setState({
        initialScrollApplied: true,
      });
    }
  }

  updateScroll() {
    if (this.PS) {
      this.PS.update();
    }
  }

  handleScroll() {
    const el = this.rootRef.current;

    this.scrollPosition = {
      x: {
        scrolled: el.scrollLeft,
        remaining: el.scrollWidth - el.clientWidth - el.scrollLeft,
      },
      y: {
        scrolled: el.scrollTop,
        remaining: el.scrollHeight - el.clientHeight - el.scrollTop,
      },
    };

    this.props.onScroll(this.scrollPosition);
  }

  scrollDown() {
    if (!this.rootRef.current) {
      return;
    }

    const diff = this.rootRef.current.scrollHeight - this.rootRef.current.clientHeight;

    if (diff > 0) {
      this.rootRef.current.scrollTop = diff;
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div className="scrollable" ref={ this.rootRef }>
        <div className="scrollable__container">
          { children }
        </div>
        <ReactResizeDetector handleHeight skipOnMount onResize={ this.updateScroll } />
      </div>
    );
  }
};

export default Scrollable;

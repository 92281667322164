import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Auth from 'components/Auth';
import Modal from 'components/Modal';

import './style.css';

class ModalAuth extends Component {
  static propTypes = {
    view: PropTypes.string,
  }

  render() {
    const { isOpen, handleClose, view } = this.props;

    return (
      <Modal isOpen={ isOpen } handleClose={ handleClose }>
        <div className="modal-auth">
          <div className="modal-auth__container">
            <div className="modal-auth__logo">
              <div className="modal-auth__logo-icon">
                <img src="/images/logo.svg" alt="LinkYou" />
              </div>
              <div className="modal-auth__logo-slogan">
                Знакомьтесь <br /> по профессии
              </div>
            </div>
            <Auth defaultView={ view } huge />
          </div>
        </div>
      </Modal>
    );
  }
};

export default ModalAuth;

import { createSelectorCreator } from 'reselect'
import isEqual from 'lodash/isEqual';
import memoize from 'memoize-one';

const createDataSelector = createSelectorCreator(memoize, isEqual);

export const selectUploadedPhotos = createDataSelector(
  (state) => state.states.uploadPhotos,
  (uploadPhotos) => {
    const result = [];

    Object.keys(uploadPhotos || {}).forEach(photoId => {
      if (uploadPhotos[photoId].success) {
        result.push(photoId);
      }
    });

    return result;
  },
);

import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const FormRadio = ({ changeValue, ...rest }) => (
  <span className="form-radio">
    <input type="radio" onChange={ changeValue } { ...rest } />
    <span className="form-radio__pip" />
  </span>
);

FormRadio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormRadio;

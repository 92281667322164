import { generateTypes } from 'common/helpers';
import { CALL_API } from 'common/middleware/api';

export const payment = (body, backUrl) => ({
  [CALL_API]: {
    body,
    payload: {
      backUrl,
    },
    types: generateTypes('PAYMENT'),
    method: 'post',
    endpoint: 'payment',
  }
});

export const paymentList = () => ({
  [CALL_API]: {
    types: generateTypes('PAYMENT_LIST'),
    method: 'post',
    endpoint: 'payment/list',
  }
});

export const paymentListFee = () => ({
  [CALL_API]: {
    types: generateTypes('PAYMENT_LIST_FEE'),
    method: 'get',
    endpoint: 'payment/list/fee',
  }
});

export const loadPaymentPrices = () => ({
  [CALL_API]: {
    types: generateTypes('LOAD_PAYMENT_PRICES'),
    method: 'get',
    endpoint: 'payment/prices',
  }
});

export const paymentBurndown = () => ({
  type: 'PAYMENT_BURNDOWN',
});

import { combineReducers } from 'redux';
import { camelize } from 'humps';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import concat from 'lodash/concat';
import chunk from 'lodash/chunk';
import clone from 'lodash/clone';
import without from 'lodash/without';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { LIST_LIMIT, LS_KEYS, getLSKey, getUser, datetime } from 'common/helpers';

// Helpers

// SOME_ACTION_SUCCESS → someAction
const getTypeName = action => camelize(action.type.toLowerCase().substr(0, action.type.lastIndexOf('_')));

const paginate = (state, action, resultKey, deepKey) => {
  const resultInstance = deepKey ? {} : [];
  const { result } = action.response;
  const pagination = action.response.pagination || {};
  const pageIndex = pagination.pageIndex || 0;
  const limit = pagination.limit || LIST_LIMIT;

  let newState = clone(state[resultKey] || resultInstance);

  if (deepKey && !newState[deepKey]) {
    newState[deepKey] = [];
  }

  if (result.length > limit) {
    let data = chunk(result, limit);

    if (deepKey) {
      newState[deepKey] = data;
    } else {
      newState = data;
    }
  } else {
    if (deepKey) {
      newState[deepKey][pageIndex] = result;
    } else {
      newState[pageIndex] = result;
    }
  }

  return newState;
};

const addToPagination = (state, action, resultKey, deepKey) => {
  const extendedAction = clone(action);
  const currentState = deepKey ? state[resultKey][deepKey] : state[resultKey];

  extendedAction.response.result = concat([action.response.result], flatten(currentState));

  return paginate(state, extendedAction, resultKey, deepKey);
};

const removeFromPagination = (state, action, resultKey, entityName, deepKey) => {
  const extendedAction = clone(action);
  const currentState = deepKey ? state[resultKey][deepKey] : state[resultKey];

  if (!extendedAction.response) {
    extendedAction.response = {};
  }

  extendedAction.response.result = without(flatten(currentState), action.payload[entityName]);

  return paginate(state, extendedAction, resultKey, deepKey);
};

const updateUser = (state, userId, data = {}) => {
  const update = {};

  if (state.users && state.users[userId]) {
    update.users = {
      [userId]: data,
    };
  }
  if (state.usersCompact && state.usersCompact[userId]) {
    update.usersCompact = {
      [userId]: data,
    };
  }

  if (isEmpty(update)) {
    return state;
  }

  return merge(
    {},
    state,
    update,
  );
};

const incrementUblogsCount = (state, action) => {
  const { userId } = action.payload;
  const update = {};

  if (userId in state.users) {
    update.users = {
      [userId]: {
        ublogsCount: state.usersCompact[userId].ublogsCount,
      },
    };
  }

  return merge(
    {},
    state,
    update,
  );
};

const decrementUblogsCount = (state, action) => {
  const { userId } = action.payload;
  const user = getUser({ entities: state }, userId);

  return updateUser(state, userId, {
    ublogsCount: user.ublogsCount - 1,
  });
};

const addLocalComment = (state, action) => {
  const resultKey = camelize(action.type.replace(/ADD_LOCAL_|_ADD_LOCAL/gi, '').toLowerCase()) + 's';

  if (resultKey === 'photoComments') {
    return {
      ...state,
      [resultKey]: {
        ...state[resultKey],
        [action.payload.photoId]: concat([action.response.result], state[resultKey][action.payload.photoId] || []),
      },
    };
  }

  return {
    ...state,
    [resultKey]: concat([action.response.result], state[resultKey]),
  };
};

const getAddCommentResultKey = (action) => {
  return camelize(action.type.replace(/^ADD_REMOTE_|(_ADD_REMOTE)?_SUCCESS$/gi, '').toLowerCase()) + 's';
}

const addRemoteComment = (state, action) => {
  const resultKey = getAddCommentResultKey(action);
  const comments = resultKey === 'photoComments'
    ? state[resultKey][action.payload.photoId].slice(0)
    : state[resultKey].slice(0);
  const tempEntryIndex = comments.indexOf(action.payload.tempId);

  if (tempEntryIndex === -1) {
    return state;
  }

  comments[tempEntryIndex] = action.response.result;

  if (resultKey === 'photoComments') {
    return {
      ...state,
      [resultKey]: {
        ...state[resultKey],
        [action.payload.photoId]: comments,
      },
    };
  }

  return {
    ...state,
    [resultKey]: comments,
  };
};

const deleteComment = (state, action) => {
  const resultKey = camelize(action.type.replace(/^DELETE_|(_DELETE)?_SUCCESS$/gi, '').toLowerCase()) + 's';

  if (resultKey === 'photoComments') {
    return {
      ...state,
      [resultKey]: {
        ...state.resultKey,
        [action.payload.photoId]: without(state[resultKey][action.payload.photoId], action.payload.commentId),
      },
    };
  }

  return {
    ...state,
    [resultKey]: without(state[resultKey], action.payload.commentId),
  };
};

const addRemoteMessage = (state, action) => {
  const messages = state.imMessages.messages.slice(0);
  const tempMessageIndex = messages.indexOf(action.payload.tempId);

  if (tempMessageIndex === -1) {
    return state;
  }

  messages[tempMessageIndex] = action.response.result;

  return {
    ...state,
    imMessages: {
      ...state.imMessages,
      messages,
    },
  };
};

const clearDialogue = (state, action) => {
  const { dialogId } = action.payload;

  if (!state.imMessages) {
    return {};
  }
  if (state.imMessages.dialog !== dialogId) {
    return state.imMessages;
  }

  return {
    ...state.imMessages,
    messages: [],
  };
};

const handleNewMessageData = (state, action) => {
  const { dialogId, messageId } = action.payload;

  if (!state.imMessages || state.imMessages.dialog !== dialogId) {
    return state;
  }

  const messages = state.imMessages.messages.slice(0);
  messages.unshift(messageId);

  return {
    ...state,
    imMessages: {
      messages,
      dialog: dialogId,
    },
  };
};

const handleNewMessageEntity = (state, action) => {
  const { dialogId, messageId } = action.payload;

  if (!state.imDialogs || !state.imDialogs[dialogId]) {
    return state;
  }

  state.imDialogs[dialogId].unreadMessagesCount++;
  state.imDialogs[dialogId].lastMessage = messageId;
  state.imDialogs[dialogId].lastUpdate = state.imMessages[messageId].datetime;

  return state;
};

const handleUblogLike = (state, action) => {
  const {
    payload: {
      postId,
    },
    response: {
      isLiked,
    },
  } = action;

  const post = (state.ublogs || {})[postId];
  const prevLikesCount = post.likesCount;
  const nextLikesCount = isLiked ? prevLikesCount + 1 : prevLikesCount - 1;

  return merge(
    {},
    state,
    {
      ublogs: {
        [postId]: {
          isLiked,
          likesCount: nextLikesCount,
        },
      },
    },
  );
};

const handleArticleLike = (state, action) => {
  const {
    payload: {
      postId,
    },
    response: {
      isLiked,
    },
  } = action;

  const post = (state.articles || {})[postId];
  const prevLikesCount = post.likesCount;
  const nextLikesCount = isLiked ? prevLikesCount + 1 : prevLikesCount - 1;

  return merge(
    {},
    state,
    {
      articles: {
        [postId]: {
          isLiked,
          likesCount: nextLikesCount,
        },
      },
    },
  );
};

const handleBlogLike = (state, action) => {
  const {
    payload: {
      postId,
    },
    response: {
      isLiked,
    },
  } = action;

  const post = (state.blog || {})[postId];
  const prevLikesCount = post.likesCount;
  const nextLikesCount = isLiked ? prevLikesCount + 1 : prevLikesCount - 1;

  return merge(
    {},
    state,
    {
      blog: {
        [postId]: {
          isLiked,
          likesCount: nextLikesCount,
        },
      },
    },
  );
};

const handlePhotoLike = (state, action) => {
  const {
    payload: {
      photoId,
    },
    response: {
      isLiked,
    },
  } = action;

  const photo = (state.photos || {})[photoId];
  const prevLikesCount = photo.likesCount;
  const nextLikesCount = isLiked ? prevLikesCount + 1 : prevLikesCount - 1;

  return merge(
    {},
    state,
    {
      photos: {
        [photoId]: {
          isLiked,
          likesCount: nextLikesCount,
        },
      },
    },
  );
};

const handlePhotoAdd = (state, action) => {
  const resultKey = 'userPhotos';
  const { entities, result } = action.response;
  const userId = entities.photos[result[0]].userId;

  return merge(
    {},
    state,
    {
      [resultKey]: {
        [userId]: concat(result, state[resultKey][userId] || []),
      },
    },
  );

  // return {
  //   ...state,
  //   [resultKey]: {
  //     ...state[resultKey],
  //     [userId]: concat(result, state[resultKey][userId]),
  //   },
  // };
};

const handlePhotoIncrement = (state, action) => {
  const { entities, result } = action.response;
  const userId = entities.photos[result[0]].userId;
  const user = (state.users || {})[userId];
  const userCompact = (state.usersCompact || {})[userId];
  const prevPhotosCount = (user || userCompact).photosCount;
  const nextPhotosCount = prevPhotosCount + result.length;
  const nextUserFields = {
    [userId]: {
      photosCount: nextPhotosCount,
    },
  };

  const update = {
    photos: merge(
      {},
      state.photos,
      entities.photos,
    ),
  };

  if (user) {
    update.users = nextUserFields;
  }
  if (userCompact) {
    update.usersCompact = nextUserFields;
  }

  return merge(
    {},
    state,
    update,
  );
};

const handleDeleteAndRestorePhoto = (state, action) => {
  const deleted = action.type.search('DELETE_') === 0;
  const resultKey = 'photos';
  const { photoId } = action.payload;
  const { userId } = state[resultKey][photoId];
  const user = (state.users || {})[userId];
  const userCompact = (state.usersCompact || {})[userId];
  const prevPhotosCount = (user || userCompact).photosCount;
  const nextPhotosCount = deleted ? prevPhotosCount - 1 : prevPhotosCount + 1;
  const nextUserFields = {
    [userId]: {
      photosCount: nextPhotosCount,
    },
  };
  const result = {
    [resultKey]: {
      [photoId]: {
        deleted,
      },
    },
  };

  if (user) {
    result.users = nextUserFields;
  }
  if (userCompact) {
    result.usersCompact = nextUserFields;
  }

  return merge(
    {},
    state,
    result,
  );
};

const handlePreferencesUpdate = (state, action) => {
  const isBlackListUpdate = action.type.search('BLACK_LIST') !== -1;
  const key = isBlackListUpdate ? 'isBlacklisted' : 'isFavorited';
  const keyInverted = isBlackListUpdate ? 'isFavorited' : 'isBlacklisted';
  const value = isBlackListUpdate ? action.response.blacklisted : action.response.favorited;

  return updateUser(state, action.payload.userId, {
    [key]: value,
    [keyInverted]: false, // Юзер не может быть одновременно в избранном и чёрном списке
  });
};

const storeLists = (state, action) => {
  const { query } = action.payload;
  const response = action.response.result;
  const resultKey = getTypeName(action);

  if (query) {
    return {
      ...state,
      [resultKey]: {
        ...state[resultKey],
        autocomplete: {
          ...(state[resultKey] || {}).autocomplete,
          [query]: response,
        },
      }
    };
  }

  return {
    ...state,
    [resultKey]: {
      ...state[resultKey],
      default: response,
    }
  };
};

const imMessagesDelete = (state, action) => {
  return {
    ...state,
    imMessages: {
      ...state.imMessages,
      messages: without(state.imMessages.messages, ...action.payload.id),
    }
  }
};

const handleUserRaise = (state, action) => {
  const { userId } = action.payload;
  const user = getUser({ entities: state }, userId);

  return updateUser(state, userId, {
    countRaising: Math.max(0, user.countRaising - 1),
  });
};

// WS

const switchUserStatus = (state, action) => {
  const { userId } = action.payload;
  const isOnline = action.type.split('_').pop() === 'ONLINE';
  const user = getUser({ entities: state }, userId);
  const update = {};

  if (isEmpty(user)) {
    return state;
  }

  update.isOnline = isOnline;
  if (isOnline) {
    update.lastAuth = datetime();
  }

  return updateUser(state, userId, update);
};

// Reducers

const badges = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'BADGES_SUCCESS':
      return action.response;
    case 'BADGE_RESET':
      if (payload.badgeName in state) {
        return {
          ...state,
          [payload.badgeName]: 0,
        };
      } else {
        return state;
      }
    case 'WS_VISIT_NEW':
      return {
        ...state,
        guests: state.guests + 1,
      };
    case 'WS_LIKE_PROFILE':
    case 'WS_LIKE_PHOTO':
    case 'WS_LIKE_UBLOG':
    case 'WS_USER_GIFT':
      return {
        ...state,
        likes: state.likes + 1,
      };
    case 'UPDATE_UNREAD_MESSAGES_COUNT':
      return {
        ...state,
        messages: state.messages + payload.changeAmount,
      };
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
};

const entities = (state = {}, action) => {
  const { type } = action;
  let newState;

  if (action.response && action.response.entities) {
    const { users, usersCompact } = action.response.entities;

    newState = mergeWith(
      {},
      state,
      action.response.entities,
      (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
          return srcValue;
        }
      },
    );

    if (users || usersCompact) {
      if (!newState.users) {
        newState.users = {};
      }
      if (!newState.usersCompact) {
        newState.usersCompact = {};
      }
      
      merge(newState.users, users);
      Object.assign(newState.usersCompact, usersCompact);
    }
  }

  switch (type) {
    case 'UBLOG_ADD_SUCCESS':
      newState = incrementUblogsCount(newState, action);
      break;
    case 'IM_MESSAGE_ADD_REMOTE_SUCCESS':
    case 'ARTICLE_COMMENT_ADD_REMOTE_SUCCESS':
    case 'BLOG_COMMENT_ADD_REMOTE_SUCCESS':
    case 'UBLOG_COMMENT_ADD_REMOTE_SUCCESS':
    case 'ADD_REMOTE_PHOTO_COMMENT_SUCCESS':
      newState[getAddCommentResultKey(action)][action.response.result].tempId = action.payload.tempId;
      break;
    case 'IM_MESSAGE_GET_SUCCESS':
      newState = handleNewMessageEntity(newState, action);
      break;
    case 'UBLOG_DELETE_SUCCESS':
      return decrementUblogsCount(state, action);
    case 'UBLOG_LIKE_SUCCESS':
      return handleUblogLike(state, action);
    case 'BLOG_LIKE_SUCCESS':
      return handleBlogLike(state, action);
    case 'ARTICLE_LIKE_SUCCESS':
      return handleArticleLike(state, action);
    case 'LIKE_USER_SUCCESS':
      return updateUser(state, action.payload.userId, {
        likes: {
          count: action.response.count,
          isLiked: action.response.isLiked,
        },
      });
    case 'DELETE_PHOTO_REQUEST':
    case 'RESTORE_PHOTO_REQUEST':
      return handleDeleteAndRestorePhoto(state, action);
    case 'PHOTO_ADD_SUCCESS':
      return handlePhotoIncrement(state, action);
    case 'PHOTO_LIKE_SUCCESS':
      return handlePhotoLike(state, action);
    case 'WS_MESSAGE_READ':
      const { dialogId } = action.payload;
      return {
        ...state,
        imMessages: {
          ...Object.keys(state.imMessages).reduce((result, messageId) => {
            let message = Object.assign({}, state.imMessages[messageId]);
            if (message.dialogId === +dialogId) {
              message.read = true;
            }
            result[messageId] = message;
            return result;
          }, {}),
        }
      }
    case 'WS_USER_ONLINE':
    case 'WS_USER_OFFLINE':
      return switchUserStatus(state, action);
    case 'VISIBILITY_SWITCH_SUCCESS':
      return updateUser(state, action.payload.userId, {
        isInvisible: action.response.visible,
      });
    case 'SWITCH_FAVORITES_SUCCESS':
    case 'SWITCH_BLACK_LIST_SUCCESS':
      return handlePreferencesUpdate(state, action);
    case 'AUTH_EMAIL_CONFIRM_SUCCESS':
      return updateUser(state, action.payload.userId, {
        email: action.payload.email,
      });
    case 'USER_STATE_SWITCH_SUCCESS':
      return updateUser(state, action.payload.userId, {
        isActive: action.response.active,
      });
    case 'IM_DIALOG_DETECT_SUCCESS':
      return merge(
        {},
        state,
        {
          imMatching: {
            [action.payload.userId]: action.response.id,
          }
        },
      );
    case 'USER_RAISE_SUCCESS':
      return handleUserRaise(state, action);
    case 'LOGOUT':
      if (action.payload.userId) {
        return updateUser(state, action.payload.userId, {
          email: '',
        });
      }
      break;
    default:
      break;
  }

  return newState || state;
};

const data = (state = {}, action) => {
  const { type } = action;
  const resultKey = getTypeName(action);

  switch (type) {
    case 'IM_MESSAGE_ADD_REMOTE_FAILURE':
      return {
        ...state,
        imMessagesFailure: [
          ...(state.imMessagesFailure || []),
          action.payload.tempId
        ]
      }
    case 'IM_DIALOGS_SUCCESS':
    case 'USER_ANSWERS_SUCCESS':
    case 'USER_COMMENTS_SUCCESS':
    case 'USER_LIKES_SUCCESS':
    case 'USER_SYMPATHIES_SUCCESS':
    case 'USERS_RECOMMENDED_SUCCESS':
    case 'USERS_GUESTS_SUCCESS':
    case 'USERS_DAILY_SUCCESS':
    case 'SEARCH_SUCCESS':
    case 'USERS_FAVORITES_SUCCESS':
    case 'USERS_BLACK_LIST_SUCCESS':
    case 'USERS_TOP_SUCCESS':
    case 'UBLOGS_BY_TAG_SUCCESS':
    case 'UBLOGS_TAGS_SUCCESS':
      return {
        ...state,
        [resultKey]: paginate(state, action, resultKey),
      };
    case 'UBLOGS_SUCCESS':
      return {
        ...state,
        [resultKey]: paginate(state, action, resultKey, action.payload.folder),
      };
    case 'UBLOG_ADD_SUCCESS':
      return {
        ...state,
        ublogs: addToPagination(state, action, 'ublogs', action.payload.userId),
      };
    case 'UBLOG_DELETE_SUCCESS':
      return {
        ...state,
        ublogs: removeFromPagination(state, action, 'ublogs', 'postId', action.payload.userId),
      };
    case 'IM_DIALOG_DELETE_SUCCESS':
    case 'WS_IM_DIALOGUE_DELETE':
      return {
        ...state,
        imDialogs: removeFromPagination(state, action, 'imDialogs', 'dialogId'),
        imMessages: clearDialogue(state, action),
      };
    case 'IM_MESSAGE_GET_SUCCESS':
      return handleNewMessageData(state, action);
    case 'PHOTO_COMMENTS_SUCCESS':
      return merge(
        {},
        state,
        {
          [resultKey]: {
            [action.payload.photoId]: action.response.result,
          },
        },
      );
    case 'USER_GIFTS_SUCCESS':
    case 'USER_PHOTOS_SUCCESS':
      return {
        ...state,
        [resultKey]: {
          ...state[resultKey],
          [action.payload.userId]: action.response.result,
        },
      };
    case 'PHOTO_ADD_SUCCESS':
      return handlePhotoAdd(state, action);
    case 'CITIES_SUCCESS':
    case 'AUTOCOMPLETE_CITIES_SUCCESS':
    case 'AUTOCOMPLETE_OCCUPATIONS_SUCCESS':
    case 'AUTOCOMPLETE_PROFESSIONS_SUCCESS':
    case 'AUTOCOMPLETE_NATIONALITY_SUCCESS':
    case 'AUTOCOMPLETE_RELIGIONS_SUCCESS':
    case 'AUTOCOMPLETE_LANGUAGES_SUCCESS':
      return storeLists(state, action);
    case 'USERS_NEW_SUCCESS':
    case 'USERS_INTERESTING_SUCCESS':
    case 'LIKEYOU_SUCCESS':
    case 'BLOG_SUCCESS':
    case 'ARTICLES_SUCCESS':
    case 'ARTICLE_COMMENTS_SUCCESS':
    case 'BLOG_COMMENTS_SUCCESS':
    case 'UBLOG_COMMENTS_SUCCESS':
    case 'GIFTS_STORE_SUCCESS':
    case 'GIFTS_RECOMMENDED_SUCCESS':
    case 'USER_SYMPATHIES_NEW_SUCCESS':
    case 'NEW_GIFTS_SUCCESS':
      return {
        ...state,
        [resultKey]: action.response.result,
      };
    case 'SEARCH_RESULTS_COUNT_SUCCESS':
    case 'SEARCH_RESULTS_COUNT_NEXT_SUCCESS':
    case 'GIFTS_PAID_SUCCESS':
    case 'PAYMENT_LIST_FEE':
      return {
        ...state,
        [resultKey]: action.response,
      };
    case 'ARTICLE_COMMENT_ADD_LOCAL':
    case 'BLOG_COMMENT_ADD_LOCAL':
    case 'UBLOG_COMMENT_ADD_LOCAL':
    case 'ADD_LOCAL_PHOTO_COMMENT':
      return addLocalComment(state, action);
    case 'ARTICLE_COMMENT_ADD_REMOTE_SUCCESS':
    case 'BLOG_COMMENT_ADD_REMOTE_SUCCESS':
    case 'UBLOG_COMMENT_ADD_REMOTE_SUCCESS':
    case 'ADD_REMOTE_PHOTO_COMMENT_SUCCESS':
      return addRemoteComment(state, action);
    case 'ARTICLE_COMMENT_DELETE_SUCCESS':
    case 'BLOG_COMMENT_DELETE_SUCCESS':
    case 'UBLOG_COMMENT_DELETE_SUCCESS':
    case 'DELETE_PHOTO_COMMENT_SUCCESS':
    case 'PHOTO_COMMENT_DELETE_SUCCESS':
      return deleteComment(state, action);
    case 'LIKEYOU_SHIFT':
      return {
        ...state,
        likeyou: state.likeyou.slice(1),
      };
    case 'LIKEYOU_LIKE_SUCCESS':
    case 'LIKEYOU_SKIP_SUCCESS':
      return {
        ...state,
        likeyou: concat(state.likeyou, [action.response.result]),
      };
    case 'IM_MESSAGES_SUCCESS':
      // add messages to existing chat if they're belong to it
      // otherwise work as a new chat
      if (state.imMessages && state.imMessages.dialog === action.response.result.dialog) {
        return {
          ...state,
          imMessages: {
            ...state.imMessages,
            messages: uniq(concat(state.imMessages.messages, action.response.result.messages)),
          },
        };
      } else {
        return {
          ...state,
          imMessages: action.response.result,
        };
      }
    case 'IM_MESSAGE_ADD_LOCAL':
      return {
        ...state,
        imMessages: {
          ...state.imMessages,
          messages: concat([action.response.result], state.imMessages.messages),
        },
      };
    case 'IM_MESSAGE_ADD_REMOTE_SUCCESS':
      return addRemoteMessage(state, action);
    case 'IM_MESSAGES_DELETE_SUCCESS':
    case 'WS_IM_MESSAGES_DELETE':
      return imMessagesDelete(state, action);
    case 'USER_SYMPATHIES_NEW_CLEAR':
      return {
        ...state,
        userSympathiesNew: [],
      };
    case 'USERS_FAVORITES_COUNT_SUCCESS':
    case 'USERS_BLACK_LIST_COUNT_SUCCESS':
      return {
        ...state,
        [resultKey]: action.response,
      };
    case 'LOGOUT':
      return {
        ...state,
        giftsPaid: [],
        userSympathiesNew: [],
      };
    default:
      return state;
  }
};

const pagination = (state = {}, action) => {
  const pagination = action.response && action.response.pagination;
  const resultKey = getTypeName(action);
  let pagesCount;

  switch (action.type) {
    case 'UBLOG_ADD_SUCCESS':
      pagesCount = Math.ceil(action.response.result.length / state.ublogs.limit);

      if (pagesCount !== state.ublogs.total) {
        return {
          ...state,
          ublogs: Object.assign({}, state.ublogs, {
            total: pagesCount,
            isEnd: state.page === pagesCount,
          }),
        };
      }

      return state;
    default:
      if (pagination) {
        return {
          ...state,
          [resultKey]: pagination,
        };
      }

      return state;
  }
};

const session = (state = null, action) => {
  const { type } = action;

  let session;

  switch (type) {
    case 'SIGN_IN_SUCCESS':
    case 'SIGN_UP_SUCCESS':
      session = action.response.result;
      break;
    case 'RESUME_SESSION':
      session = action.payload;
      break;
    case 'AUTH_PASSWORD_UPDATE_SUCCESS':
      session = { token: action.response.token }
      break;
    case 'LOGOUT':
      return null;
    default:
      return state;
  }

  return merge({}, state, session);
};

const layoutSettings = (
  state = {
    nav: false,
    recommended: false,
    footer: true,
    shortAsideFooter: false,
  },
  action,
) => {
  switch (action.type) {
    case 'HIDE_NAV':
      return Object.assign({}, state, { nav: false });
    case 'SHOW_NAV':
      return Object.assign({}, state, { nav: true });
    case 'COLLAPSE_ASIDE_FOOTER':
      return Object.assign({}, state, { shortAsideFooter: true });
    case 'EXPAND_ASIDE_FOOTER':
      return Object.assign({}, state, { shortAsideFooter: false });
    case 'UPDATE_LAYOUT_SETTINGS':
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

// Store current state of each API call to let the Forms function properly
const states = (state = {}, action) => {
  const { type } = action;
  const status = type.split('_').pop();
  const payload = action.payload || null;
  const resultKey = (payload || {}).rewriteStateKey || getTypeName(action);

  let deepKey = '';
  let request = false;
  let success = false;
  let error = null;

  if (type.search('UPLOAD_PHOTOS_') === 0) {
    deepKey = payload.requestId;
  }

  switch (status) {
    case 'REQUEST':
      request = true;
      break;
    case 'SUCCESS':
      success = true;
      break;
    case 'FAILURE':
      error = action.error;
      break;
    default:
      return state;
  }

  const dataObject = { request, success, error, payload };
  const result = !!deepKey
    ? { [deepKey]: dataObject }
    : dataObject;

  return Object.assign(
    {},
    state,
    {
      [resultKey]: result,
    },
  );
};

// Store count of currently working forms
// whose requests are done but final job isn't finished yet
const workingForms = (state = [], action) => {
  const newState = state.slice(0);

  if (action.type === 'START_FORM_JOB') {
    newState.push(action.id);
  }
  if (action.type === 'FINISH_FORM_JOB') {
    newState.splice(newState.indexOf(action.id), 1);
  }

  return newState;
};

const modal = (state = {}, action) => {
  const { type, payload } = action;

  if (type === 'MODAL_CLOSE') {
    return {
      type: '',
      payload: {},
    };
  }

  if (type === 'MODAL_UPDATE_PROPS') {
    return merge(
      {},
      state,
      { payload },
    );
  }

  if (type.search('MODAL_') === 0) {
    return {
      type,
      payload,
    };
  }

  return state;
};

const uploads = (state = {}, action) => {
  const { type, payload, response } = action;

  if (type === 'UPLOAD_PHOTOS_SUCCESS') {
    return merge(
      {},
      state,
      { [payload.requestId]: response },
    );
  }

  return state;
};

const payment = (state = [], action) => {
  const { type, response, payload } = action;

  switch (type) {
    case 'PAYMENT_LIST_SUCCESS':
      return merge(
        [],
        state,
        response,
      );
    case 'PAYMENT_BURNDOWN':
      const payments = state.slice(0);
      const feePaymentIndex = payments.indexOf('fee');

      if (feePaymentIndex !== -1) {
        payments.splice(feePaymentIndex, 1);
      } else {
        payments.shift();
      }

      return payments;
    case 'LOGOUT':
      return [];
    case 'PAYMENT_SUCCESS':
      if ('localStorage' in window) {
        localStorage.setItem(getLSKey(LS_KEYS.BACK_URL), payload.backUrl);
      }

      window.location.href = response.url;
    default:
      return state;
  }
};

const notificationSettings = (state = {}, action) => {
  const { type, response, payload } = action;

  switch (type) {
    case 'NOTIFICATION_SETTINGS_GET_SUCCESS':
      return response;
    case 'NOTIFICATION_SETTINGS_SWITCH_SUCCESS':
      return merge(
        {},
        state,
        {
          [payload.code]: Number(response.status),
        },
      );
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
};

const prices = (state = {}, action) => {
  if (action.type === 'LOAD_PAYMENT_PRICES_SUCCESS') {
    const grabData = raw => {
      return raw.slice(0).reduce((obj, opt) => {
        obj[opt.entityId] = {
          option: opt.name,
          price: `${opt.price} руб.`,
        };

        return obj;
      }, {})
    };

    const {
      fee,
      gift,
      interestingPage,
      invisible,
      mainPage,
      premium,
      searchRaise,
    } = action.response;

    return {
      vip: {
        typeId: mainPage[0].paymentTypeId,
        heading: 'Попасть на главную',
        price: {
          data: grabData(mainPage),
          defaultValue: mainPage[1].entityId,
        },
      },
      premium: {
        typeId: premium[0].paymentTypeId,
        heading: 'Стать Premium',
        backlink: 'Что дает Premium-аккаунт?',
        price: {
          data: grabData(premium),
          defaultValue: premium[1].entityId,
        },
      },
      interesting: {
        typeId: interestingPage[0].paymentTypeId,
        heading: 'Разместить анкету в боковую панель',
        price: {
          data: grabData(interestingPage),
          defaultValue: interestingPage[0].entityId,
        },
      },
      invisible: {
        typeId: invisible[0].paymentTypeId,
        heading: 'Оплатить режим невидимости',
        backlink: '← Назад',
        price: {
          data: grabData(invisible),
          defaultValue: invisible[1].entityId,
        },
      },
      up: {
        typeId: searchRaise[0].paymentTypeId,
        heading: 'Поднять анкету в поиске',
        subheading: `Сумма к оплате ${searchRaise[0].price} рублей`,
        price: {
          amount: searchRaise[0].price,
        },
      },
      gift: {
        typeId: gift[0].paymentTypeId,
        heading: 'Сделать подарок',
        subheading: `Сумма к оплате ${gift[0].price} рублей`,
        backlink: 'К выбору подарка',
        price: {
          amount: gift[0].price,
        },
      },
      fee: {
        typeId: fee[0].paymentTypeId,
        heading: 'Оплатить единоразовый взнос',
        subheading: `${fee[0].price} рублей`,
        backlink: '← Назад',
        price: {
          amount: fee[0].price,
          defaultValue: fee[0].entityId,
        },
      },
    };
  }

  return state;
};

const wsUpdates = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'WS_START_TYPING':
      return merge({}, state, {
        imTyping: {
          [payload.chatId]: true,
        },
      });
    case 'WS_STOP_TYPING':
      return merge({}, state, {
        imTyping: {
          [payload.chatId]: false,
        },
      });
    case 'WS_IM_DIALOGUE_DELETE':
      return {
        ...state,
        imDeletedDialogue: {
          id: payload.dialogId,
          timestamp: Date.now(),
        },
      };
    case 'WS_MESSAGE_NEW':
      return {
        ...state,
        imNewMessage: {
          ...payload,
        },
      };
    case 'WS_GIFT_NEW':
      return {
        ...state,
        giftNew: {
          ...payload,
          timestamp: Date.now(),
        },
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  badges,
  entities,
  data,
  pagination,
  session,
  layoutSettings,
  states,
  workingForms,
  modal,
  payment,
  uploads,
  notificationSettings,
  prices,
  wsUpdates,
});

export default rootReducer;

import React from 'react';
import PropTypes from 'prop-types';

import FormButton from 'components/FormButton';
import ModalSimple from 'components/ModalSimple';

const ModalPasswordReset = ({ isOpen, handleClose, email }) => {
  return (
    <ModalSimple isOpen={ isOpen } handleClose={ handleClose }>
      <h2>Ссылка для восстановления пароля отправлена</h2>
      <p>Проверьте ваш почтовый ящик { email }</p>
      <FormButton type="submit" skin={ ['darkblue', 'size-m'] } onClick={ handleClose }>
        OK
      </FormButton>
    </ModalSimple>
  );
};

ModalPasswordReset.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  email: PropTypes.string,
};


export default ModalPasswordReset;

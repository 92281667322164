import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openModalCooperation } from 'common/actions';

import Link from 'components/Link';

import './style.css';

const AsideFooter = ({isShort, openModalCooperation}) => (
  <div className={`aside-footer${isShort ? " -short" : ""}`}>
    <div className="aside-footer__entry">
      <a href="https://www.admitad.ru/store/offers/linkyou/" target="_blank">
        Партнерская программа
      </a>
    </div>
    <div className="aside-footer__entry -visible">
      © {new Date().getFullYear()} LinkYou
    </div>
    <div className="aside-footer__entry">
      <Link to="/rules">Правила</Link>
    </div>
    <div className="aside-footer__entry">
      <Link to="/privacy">Конфиденциальность</Link>
    </div>
    <div className="aside-footer__entry">
      <button type="button" onClick={openModalCooperation}>
        Сотрудничество
      </button>
    </div>
    <div className="aside-footer__entry -visible">18+</div>
  </div>
);

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModalCooperation,
  }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps,
)(AsideFooter);

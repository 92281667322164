import React from 'react';
import { PropTypes } from 'prop-types';

import { LazyLoadComponent } from 'react-lazy-load-image-component';

//Для lazy-loading используется плагин react-lazy-load-image-component
//Текущий компонент взят из оригинального плагина и немного изменен, так как в исходниках была проблема в том, что картинки загружались два раза

class LazyLoadImage extends React.Component {

  getImg() {
    const { afterLoad, beforeLoad, delayMethod, delayTime, effect,
      placeholder, placeholderSrc, scrollPosition, threshold,
      useIntersectionObserver, visibleByDefault, wrapperClassName,
      ...imgProps } = this.props;

    return <img {...imgProps} />;
  }

  getLazyLoadImage(image) {
    const { beforeLoad, className, delayMethod, delayTime,
      height, placeholder, scrollPosition, style, threshold,
      useIntersectionObserver, visibleByDefault, width } = this.props;

    return (
      <LazyLoadComponent
        beforeLoad={beforeLoad}
        className={className}
        delayMethod={delayMethod}
        delayTime={delayTime}
        height={height}
        placeholder={placeholder}
        scrollPosition={scrollPosition}
        style={style}
        threshold={threshold}
        useIntersectionObserver={useIntersectionObserver}
        visibleByDefault={visibleByDefault}
        width={width}>
        {image}
      </LazyLoadComponent>
    );
  }

  render() {
    const image = this.getImg();

    return this.getLazyLoadImage(image);;
  }
}

LazyLoadImage.propTypes = {
  afterLoad: PropTypes.func,
  beforeLoad: PropTypes.func,
  delayMethod: PropTypes.string,
  delayTime: PropTypes.number,
  effect: PropTypes.string,
  placeholderSrc: PropTypes.string,
  threshold: PropTypes.number,
  useIntersectionObserver: PropTypes.bool,
  visibleByDefault: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

LazyLoadImage.defaultProps = {
  afterLoad: () => ({}),
  beforeLoad: () => ({}),
  delayMethod: 'throttle',
  delayTime: 300,
  effect: '',
  placeholderSrc: '',
  threshold: 100,
  useIntersectionObserver: true,
  visibleByDefault: false,
  wrapperClassName: '',
};

export default LazyLoadImage;
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { sendTicketSupport } from 'common/actions';

import ModalTicket from 'components/ModalTicket';
import FormInput from 'components/FormInput';

const ModalTicketSupport = ({
  isOpen,
  handleClose,
  sendTicketSupport,
  actionState,
  params,
}) => (
  <ModalTicket
    isOpen={ isOpen }
    handleClose={ handleClose }
    title="Связаться с техподдержкой"
    formId="form-ticket-support"
    action={ sendTicketSupport }
    actionState={ actionState }
  >
    <FormInput
      placeholder="Суть проблемы"
      type="text"
      name="title"
      validate="required"
      autofocus
    />
    <FormInput
      isTextarea
      placeholder="Опишите, пожалуйста, вашу проблему"
      name="message"
      rows={ 3 }
      validate="required"
    />
    <input
      type="hidden"
      name="entity"
      value={ params.entity }
    />
    { !!params.entityId &&
      <input
        type="hidden"
        name="entityId"
        value={ params.entityId }
      />
    }
  </ModalTicket>
);

ModalTicketSupport.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    actionState: state.states.ticketSupport || {},
    params: state.modal.payload,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    sendTicketSupport,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTicketSupport);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { REGEX } from 'common/helpers';

import Form from 'components/Form';
import FormButton from 'components/FormButton';
import FormInput from 'components/FormInput';
import Iframe from 'components/Iframe';
import Modal from 'components/Modal';

import './style.css';

class ModalYouTube extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.addVideo = this.addVideo.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.state = {
      iframeLink: '',
    };
  }

  addVideo(model) {
    this.props.onAdd(model.link, this.state.iframeLink);
    this.setState({
      iframeLink: '',
    });
  }

  handleInput(e) {
    const { value } = e.target;
    const result = REGEX.YOUTUBE.exec(value);
    const iframeLink = result ? `https://www.youtube.com/embed/${result[1]}` : '';

    this.setState({
      iframeLink,
    });
  }

  render() {
    const { isOpen, handleClose } = this.props;
    const { iframeLink } = this.state;

    return (
      <Modal isOpen={ isOpen } handleClose={ handleClose }>
        <div className="modal-youtube">
          <h2>Вставьте ссылку на видео <br />с <a href="https://youtube.com/" target="_blank" rel="noopener noreferrer">YouTube.com</a></h2>
          <Form action={ this.addVideo }>
            <FormInput
              placeholder="Ссылка на видео"
              type="text"
              name="link"
              validate="youtubeVideo"
              onInput={ this.handleInput }
              autofocus
            />
            { iframeLink
              ? <div className="modal-youtube__preview">
                  <Iframe src={ iframeLink } title="YouTube video" />
                </div>
              : null
            }
            <FormButton type="submit" skin={ ['darkblue', 'size-m'] }>
              Добавить видео
            </FormButton>
          </Form>
        </div>
      </Modal>
    );
  }
};

export default ModalYouTube;

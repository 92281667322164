import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { loadPhotoComments } from 'common/actions';

import { isSSR } from 'common/helpers';

const PREVIOUS = 'PREVIOUS';
const NEXT = 'NEXT';

function withPhotoViewer(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.makeActionNext = this.makeActionNext.bind(this);
      this.makeActionPrevious = this.makeActionPrevious.bind(this);
      this.keyNav = this.keyNav.bind(this);

      this.preloadedImages = {};

      this.state = {
        activePhotoId: 0,
        viewingDirection: '',
      };
    }

    componentDidMount() {
      if (!isSSR()) {
        document.addEventListener('keydown', this.keyNav, false);
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const { isOpen, handleClose, data, initialPhotoId, photoDeleteRequestState } = this.props;
      const { activePhotoId } = this.state;

      if (!prevProps.isOpen && isOpen) {
        this.preloadedImages = {};

        this.setState({
          activePhotoId: initialPhotoId,
          isMobileCommentsShown: false,
        });
      }

      if (isOpen && prevState.activePhotoId !== activePhotoId) {
        this.preloadPhotos();
      }

      if (prevProps.photoDeleteRequestState.request && photoDeleteRequestState.success) {
        if (Object.keys(data).length > 0) {
          this.makeActionNext();
        } else {
          handleClose();
        }
      }
    }

    componentWillUnmount() {
      if (!isSSR()) {
        document.removeEventListener('keydown', this.keyNav, false);
      }
    }

    keyNav(e) {
      const keyCode = e.keyCode || e.charCode;

      if (
        this.props.isAnotherModalFocused ||
        document.activeElement.getAttribute('contenteditable') !== null ||
        ['INPUT', 'TEXTAREA'].indexOf(document.activeElement.tagName.toUpperCase()) !== -1
      ) {
        return;
      }

      if (keyCode === 37) {
        this.makeActionPrevious();
      }
      if (keyCode === 39) {
        this.makeActionNext();
      }
    }

    goToPrevious() {
      if (!this.props.isOpen) {
        return;
      }

      this.setState({
        isPhotoLoaded: false,
        viewingDirection: PREVIOUS,
        activePhotoId: this.getSiblingPhotoId(PREVIOUS),
      });
    }

    goToNext() {
      if (!this.props.isOpen) {
        return;
      }

      this.setState({
        isPhotoLoaded: false,
        viewingDirection: NEXT,
        activePhotoId: this.getSiblingPhotoId(NEXT),
      });
    }

    makeActionPrevious() {
      const { isReversed } = this.props;

      if (isReversed) {
        this.goToNext();
      } else {
        this.goToPrevious();
      }
    }

    makeActionNext() {
      const { isReversed } = this.props;

      if (isReversed) {
        this.goToPrevious();
      } else {
        this.goToNext();
      }
    }

    getSiblingPhotoId(
      direction,
      currentPhotoId = this.state.activePhotoId,
      data = this.props.data,
    ) {
      if ([PREVIOUS, NEXT].indexOf(direction) === -1) {
        return;
      }

      const ids = Object.keys(data).map(id => parseInt(id, 10));
      const maxIndex = ids.length - 1;
      const prevIndex = ids.indexOf(parseInt(currentPhotoId, 10));

      let nextIndex;

      if (direction === PREVIOUS) {
        nextIndex = prevIndex <= 0 ? maxIndex : prevIndex - 1;
      } else if (direction === NEXT) {
        nextIndex = prevIndex >= maxIndex ? 0 : prevIndex + 1;
      }

      return parseInt(ids[nextIndex], 10);
    }

    preloadPhotos() {
      const { activePhotoId, viewingDirection } = this.state;
      const ids = Object.keys(this.props.data).map(id => parseInt(id, 10));
      const keepNonQueued = i => i === activePhotoId || !this.preloadedImages[i];

      if (!activePhotoId || Object.keys(this.preloadedImages).length === ids.length) {
        return;
      }

      switch (viewingDirection) {
        case PREVIOUS:
          this.queueImage(this.getSiblingPhotoId(
            PREVIOUS,
            activePhotoId,
            ids.slice(0).reverse().filter(keepNonQueued),
          ));
          break;
        case NEXT:
          this.queueImage(this.getSiblingPhotoId(
            NEXT,
            activePhotoId,
            ids.slice(0).filter(keepNonQueued),
          ));
          break;
        default:
          this.queueImage(activePhotoId);
          if (ids.length > 1) {
            this.queueImage(this.getSiblingPhotoId(NEXT, activePhotoId));
          }
          if (ids.length > 2) {
            this.queueImage(this.getSiblingPhotoId(PREVIOUS, activePhotoId));
          }
          break;
      }
    }

    queueImage(photoId) {
      const { data, commentsLoaded, user } = this.props;
      const { loadPhotoComments } = this.props;
      const isCommentsLoaded = (photoId in commentsLoaded);
      const photo = data[photoId];

      if (!photoId || !photo || this.preloadedImages[photoId]) {
        return;
      }

      const img = new Image();

      if (user && !isCommentsLoaded) {
        loadPhotoComments(photoId);
      }

      this.preloadedImages = {
        ...this.preloadedImages,
        [photoId]: {
          queued: true,
          loaded: false,
        },
      };

      img.onload = () => {
        this.preloadedImages = {
          ...this.preloadedImages,
          [photoId]: {
            queued: true,
            loaded: true,
          },
        };
      };
      img.src = photo.src.origin;
    }

    render() {
      return (
        <WrappedComponent
          { ...this.props }
          activePhotoId={ this.state.activePhotoId }
          goToPrevious={ this.makeActionPrevious }
          goToNext={ this.makeActionNext }
        />
      );
    }
  };
};

function mapStateToProps(state) {
  return {
    commentsLoaded: state.data.photoComments || {},
    photoDeleteRequestState: state.states.deletePhoto || {},
    isAnotherModalFocused: state.modal.type === 'MODAL_SUPPORT',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadPhotoComments,
  }, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPhotoViewer,
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import noop from 'lodash/noop';

import UserCardMini from 'components/UserCardMini';
import FormButton from 'components/FormButton';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ModalGiftsStore from 'components/ModalGiftsStore';

import { isSSR, formatDate } from 'common/helpers';

import './style.css';

const MODAL_STORE = 'MODAL_STORE';

class ModalGift extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleCloseAll: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.prevGift = this.prevGift.bind(this);
    this.nextGift = this.nextGift.bind(this);
    this.keyNav = this.keyNav.bind(this);
    this.openModalStore = this.openModalStore.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalAll = this.closeModalAll.bind(this);
    this.state = {
      activeGift: 1,
      modalOpen: '',
    };
  }

  componentDidMount() {
    if (!isSSR()) {
      document.addEventListener('keydown', this.keyNav, false);
    }
  }

  componentWillUnmount() {
    if (!isSSR()) {
      document.removeEventListener('keydown', this.keyNav, false);
    }
  }

  keyNav(e) {
    const keyCode = e.keyCode || e.charCode;

    if (keyCode === 37) {
      this.prevGift();
    }
    if (keyCode === 39) {
      this.nextGift();
    }
  }

  prevGift() {
    this.setState(state => {
      if (state.modalOpen) {
        return null;
      }

      return {
        activeGift: Math.max(1, state.activeGift - 1),
      };
    });
  }

  nextGift() {
    const { data } = this.props;

    this.setState(state => {
      if (state.modalOpen) {
        return null;
      }

      return {
        activeGift: Math.min(state.activeGift + 1, data.length),
      };
    });
  }

  openModal(type) {
    this.setState({
      modalOpen: type,
    });
  }

  openModalStore() {
    this.openModal(MODAL_STORE);
  }

  closeModal(callback = noop) {
    this.setState({
      modalOpen: '',
    }, callback);
  }

  closeModalAll() {
    this.closeModal(this.props.handleCloseAll);
  }

  renderCounter() {
    const { activeGift } = this.state;
    const giftsCount = this.props.data.length;

    if (giftsCount <= 1) {
      return;
    }

    return <span className="text-gray">{ activeGift } из { giftsCount }</span>;
  }

  render() {
    const { isOpen, handleClose, data, gifts, usersCompact } = this.props;
    const { activeGift, modalOpen } = this.state;
    const giftsCount = data.length;
    const giftData = data[activeGift - 1];

    if (!giftsCount) {
      return null;
    }

    return (
      <React.Fragment>
        <Modal isOpen={ isOpen } handleClose={ handleClose } isHidden={ modalOpen === MODAL_STORE }>
          <div className="modal-gift">
            <h2>Вам подарок! { this.renderCounter() }</h2>
            <div className="modal-gift__sender">
              <UserCardMini user={ usersCompact[giftData.user] } size="medium">
                <span title={ formatDate(data.datetime) } dangerouslySetInnerHTML={{ __html: formatDate(giftData.datetime, 'timeOrDay') }} />
              </UserCardMini>
            </div>
            <div className="modal-gift__preview">
              <figure>
                <img src={ gifts[giftData.gift].picture } alt={ gifts[giftData.gift].name } />
              </figure>
              { giftsCount > 1 &&
                <React.Fragment>
                  { activeGift > 1 &&
                    <button type="button" className="modal-gift__control -prev" onClick={ this.prevGift }>
                      <Icon glyph="arrow-gift" />
                    </button>
                  }
                  { activeGift < giftsCount &&
                    <button type="button" className="modal-gift__control -next" onClick={ this.nextGift }>
                      <Icon glyph="arrow-gift" />
                    </button>
                  }
                </React.Fragment>
              }
            </div>
            { !!giftData.text &&
              <figcaption dangerouslySetInnerHTML={{ __html: giftData.text }} />
            }
            <div className="modal-gift__options">
              <FormButton skin={ ['darkblue', 'size-m'] } onClick={ handleClose }>
                OK
              </FormButton>
              <FormButton skin={ ['lightblue', 'size-m'] } onClick={ this.openModalStore }>
                Подарок в ответ
              </FormButton>
              <p>Сделайте подарок в ответ – взаимность всегда приятна</p>
            </div>
          </div>
        </Modal>
        <ModalGiftsStore recipientId={ giftData.user } isOpen={ modalOpen === MODAL_STORE } handleClose={ this.closeModal } handleCloseAll={ this.closeModalAll } />
      </React.Fragment>
    );
  }
};

function mapStateToProps(state) {
  const {
    data: {
      newGifts,
    },
    entities: {
      gifts,
      giftsGiven,
      usersCompact,
    },
  } = state;

  const data = (newGifts || []).map(id => giftsGiven[id]);

  return {
    data,
    gifts,
    usersCompact,
  };
}

export default connect(
  mapStateToProps,
)(ModalGift);

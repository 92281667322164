import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from 'components/Tooltip';

import './style.css';

class Options extends Component {
  static propTypes = {
    tooltipAt: PropTypes.oneOf(['top', 'bottom']),
  }

  static defaultProps = {
    tooltipAt: 'bottom',
  }

  constructor(props) {
    super(props);
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.state = {
      isTooltipShown: false,
    };
  }

  showTooltip() {
    this.setState({
      isTooltipShown: true,
    });
  }

  hideTooltip() {
    this.setState({
      isTooltipShown: false,
    });
  }

  toggleTooltip() {
    if (this.state.isTooltipShown) {
      this.hideTooltip();
    } else {
      this.showTooltip();
    }
  }

  render() {
    const { isTooltipShown } = this.state;
    const { tooltipAt, children } = this.props;

    return (
      <div
        className={classNames(
          'options',
          { '-active': isTooltipShown },
        )}
      >
        <button type="button" className="options__handler" onClick={ this.toggleTooltip }>•••</button>
        <Tooltip at={ tooltipAt } isActive={ isTooltipShown } onHide={ this.hideTooltip }>
          { children }
        </Tooltip>
      </div>
    );
  }
};

export default Options;

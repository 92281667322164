import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';

import { isSSR } from 'common/helpers';

import './style.css';

class Aside extends Component {
  static propTypes = {
    navIsVisible: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.rootRef = React.createRef();
    this.contentRef = React.createRef();

    this.scrollAside = this.scrollAside.bind(this);

    this.state = {
      maxScroll: 0,
      lastScroll: 0,
      scroll: 0,
      contentHeight: 0,
    };
  }

  componentDidMount() {
    if (!isSSR()) {
      window.addEventListener('resize', this.scrollAside, false);
      window.addEventListener('scroll', this.scrollAside, false);
      this.scrollAside();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.navIsVisible && this.props.navIsVisible && this.rootRef.current) {
      this.rootRef.current.scrollTop = 0;
      this.rootRef.current.scrollLeft = 0;
    }
  }

  componentWillUnmount() {
    if (!isSSR()) {
      window.removeEventListener('resize', this.scrollAside, false);
      window.removeEventListener('scroll', this.scrollAside, false);
    }
  }

  scrollAside() {
    if (!this.contentRef.current || isSSR()) {
      return;
    }

    const { lastScroll, scroll } = this.state;
    const contentOffsetTop = parseInt(window.getComputedStyle(this.contentRef.current, null).top, 10);
    const contentHeight = this.contentRef.current.clientHeight;
    const windowHeight = window.innerHeight;
    const maxScroll = contentHeight - windowHeight + contentOffsetTop;
    const scrollTop = window.pageYOffset;
    const newScroll = Math.max(0, Math.min(scroll + scrollTop - lastScroll, maxScroll));

    if (isNaN(contentOffsetTop) || isNaN(newScroll)) {
      return;
    }

    this.setState({
      contentHeight,
      lastScroll: scrollTop,
      scroll: newScroll,
    });
  }

  render() {
    const { navIsVisible, children } = this.props;
    const { scroll, contentHeight } = this.state;
    const layoutHasNav = 'navIsVisible' in this.props;

    return (
      <aside
        className={classNames(
          'aside',
          { '-next-to-nav': layoutHasNav },
          { '-without-nav': !layoutHasNav },
          { '-active': layoutHasNav && navIsVisible },
        )}
        style={{
          height: contentHeight,
        }}
        ref={ this.rootRef }
      >
        <div
          className="aside__content"
          style={{ transform: `translateY(-${scroll}px)` }}
          ref={ this.contentRef }
        >
          { children }
          <ReactResizeDetector handleHeight skipOnMount onResize={ this.scrollAside } />
        </div>
      </aside>
    );
  }
};

export default Aside;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import shortid from 'shortid';

import { uploadPhotos } from 'common/actions';
import { selectUploadedPhotos } from './selectors';

import FormButton from 'components/FormButton';
import Icon from 'components/Icon';
import ModalAlert from 'components/ModalAlert';
import Preloader from 'components/Preloader';

import './style.css';

class FormUploader extends Component {
  static propTypes = {
    mode: PropTypes.oneOf(['pet', 'photo', 'album', 'avatar']).isRequired,
    onComplete: PropTypes.func.isRequired,
    index: PropTypes.number,
    multiple: PropTypes.bool,
  }

  static defaultProps = {
    multiple: false,
  }

  constructor(props) {
    super(props);
    this.fileAdd = this.fileAdd.bind(this);
    this.hideError = this.hideError.bind(this);
    this.state = {
      requestId: 0,
      isError: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { uploads, onComplete, index } = this.props;
    const { requestId } = this.state;
    const upload = uploads[requestId];
    const requestState = this.props.requestState[requestId] || {};
    const prevRequestState = prevProps.requestState[requestId] || {};

    if (!prevProps.uploads[requestId] && upload) {
      onComplete(
        upload,
        index,
        requestId,
      );
    }

    if (requestState.error && !prevRequestState.error) {
      this.setState({
        isError: true,
      });
    }
  }

  hideError() {
    this.setState({
      isError: false,
    });
  }

  fileAdd(e) {
    const requestId = shortid.generate();

    this.setState({
      requestId,
      isError: false,
    });

    this.props.uploadPhotos(e.target.files, requestId);
  }

  render() {
    const { mode, multiple } = this.props;
    const { requestId, isError } = this.state;
    const requestState = this.props.requestState[requestId] || {};
    const isRequest = !!requestState.request;

    return (
      <React.Fragment>
        <label
          className={classNames(
            'form-uploader',
            `-mode-${mode}`,
            { '-is-loading': isRequest },
          )}
        >
          { isRequest
            ? <Preloader />
            : <React.Fragment>
                <input type="file" accept="image/png, image/jpeg" onChange={ this.fileAdd } multiple={ multiple } />
                { mode === 'pet' &&
                  <Icon glyph="photo-add" />
                }
                { mode === 'photo' &&
                  <FormButton skin={ ['circle', 'big'] } icon="option-add" noop />
                }
                { mode === 'album' &&
                  <FormButton skin={['size-m', 'darkblue']} icon="photo-add" noop>
                    Добавить фото
                  </FormButton>
                }
                { mode === 'avatar' &&
                  <FormButton skin={ ['darkblue', 'size-m'] } noop>
                    Главное фото
                  </FormButton>
                }
              </React.Fragment>
          }
        </label>
        { isError &&
          <ModalAlert handleClose={ this.hideError } isOpen>
            <h2>Ошибка</h2>
            <p>Не удалось загрузить фотографию. { requestState.error.message || '' }</p>
          </ModalAlert>
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    uploads: state.uploads,
    uploadedPhotos: selectUploadedPhotos(state),
    requestState: state.states.uploadPhotos || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    uploadPhotos,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormUploader);

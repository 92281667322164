import { generateTypes } from 'common/helpers';
import { CALL_API, Schemas } from 'common/middleware/api';

export const loadUserPhotos = (userId) => ({
  [CALL_API]: {
    types: generateTypes('USER_PHOTOS'),
    method: 'get',
    endpoint: `photos/${userId}`,
    schema: Schemas.USER_PHOTOS,
    payload: {
      userId,
    },
  }
});

export const loadPhotoComments = (photoId) => ({
  [CALL_API]: {
    types: generateTypes('PHOTO_COMMENTS'),
    method: 'get',
    endpoint: `photo/${photoId}/comments`,
    schema: Schemas.PHOTO_COMMENTS,
    payload: {
      photoId,
    },
  }
});

const addRemotePhotoComment = (photoId, tempId, comment) => ({
  [CALL_API]: {
    body: {
      comment,
    },
    payload: {
      tempId,
      photoId,
    },
    types: generateTypes('ADD_REMOTE_PHOTO_COMMENT'),
    method: 'post',
    endpoint: `photo/${photoId}/comment/add`,
    schema: Schemas.PHOTO_COMMENT,
  }
});

const addLocalPhotoComment = (photoId, response) => ({
  response,
  payload: {
    photoId,
  },
  type: 'ADD_LOCAL_PHOTO_COMMENT',
});

export const addPhotoComment = (photoId, comment, localResponse) => dispatch => {
  dispatch(addLocalPhotoComment(photoId, localResponse));
  dispatch(addRemotePhotoComment(photoId, localResponse.result, comment));
};

export const deletePhoto = (photoId, allowRecovery = false) => ({
  [CALL_API]: {
    types: generateTypes('DELETE_PHOTO'),
    method: 'post',
    endpoint: `photo/${photoId}/delete`,
    payload: {
      photoId,
      allowRecovery,
    },
  }
});

export const restorePhoto = (photoId) => ({
  [CALL_API]: {
    types: generateTypes('RESTORE_PHOTO'),
    method: 'get',
    endpoint: `photo/${photoId}/recovery`,
    payload: {
      photoId,
    },
  }
});

export const openModalAlbum = (userId, rest) => ({
  type: 'MODAL_ALBUM',
  payload: {
    userId,
    ...rest
  },
});

export const photoLike = (photoId) => ({
  [CALL_API]: {
    payload: {
      photoId,
    },
    types: generateTypes('PHOTO_LIKE'),
    method: 'post',
    endpoint: `photo/${photoId}/like`,
  }
});

export const photoAdd = (data) => ({
  [CALL_API]: {
    body: {
      'src[]': data.slice(0).map(photo => photo.src),
    },
    types: generateTypes('PHOTO_ADD'),
    method: 'post',
    endpoint: 'photos/add',
    schema: Schemas.USER_PHOTOS,
  }
});

export const photoCommentDelete = (photoId, commentId) => ({
  [CALL_API]: {
    payload: {
      photoId,
      commentId,
    },
    types: generateTypes('PHOTO_COMMENT_DELETE'),
    method: 'post',
    endpoint: `photo/${photoId}/comment/${commentId}/delete`,
  }
});


import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

import './style.css';

const ModalSimple = ({ children, isOpen, handleClose }) => {
  return (
    <Modal isOpen={ isOpen } handleClose={ handleClose }>
      <div className="modal-simple">
        { children }
      </div>
    </Modal>
  );
};

ModalSimple.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
};

export default ModalSimple;

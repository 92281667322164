import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import { FormContext } from 'components/Form';

import './style.css';

class FormInput extends Component {
  static propTypes = {
    autofocus: PropTypes.bool,
    formContext: PropTypes.object,
    error: PropTypes.object,
    icon: PropTypes.string,
    isTextarea: PropTypes.bool,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    type: PropTypes.string,
    validate: PropTypes.string,
  }

  static defaultProps = {
    type: 'text',
    rows: 5,
  }

  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.state = {
      isFocused: false,
    };
  }

  handleFocus(e) {
    this.setState({
      isFocused: true,
    }, this.props.onFocus || noop);
  }

  handleBlur(e) {
    this.setState({
      isFocused: false,
    }, this.props.onBlur || noop);
  }

  render() {
    const { placeholder, type, rows, name, icon, validate, autofocus, formContext, isTextarea, defaultValue, errorMessage, ...rest } = this.props;
    const { isFocused } = this.state;

    const error = formContext.showValidation ? formContext.errors[name] : '';
    const valid = formContext.showValidation ? !error : undefined;
    const value = formContext.model[name];
    const disabled = formContext.isRequest;
    const key = typeof value === 'undefined' ? 'empty' : 'not-empty'; // force update default value

    const TagName = isTextarea ? 'textarea' : 'input';

    return (
      <React.Fragment>
        <div
          className={classNames(
            'form-input',
            { '-textarea': isTextarea },
            { '-disabled': disabled },
            { '-error': !!errorMessage },
          )}
          key={ key }
        >
          { typeof valid !== 'undefined' &&
            <div className="form-input__validation">
              <Icon glyph={ valid ? 'input-valid' : 'input-invalid' } />
            </div>
          }
          { !!icon &&
            <div className="form-input__icon">
              <Icon glyph={ icon } />
            </div>
          }
          <TagName
            placeholder={ placeholder }
            type={ isTextarea ? null : type }
            rows={ isTextarea ? rows : null }
            name={ name }
            value={ value }
            data-default-value={ defaultValue }
            disabled={ disabled }
            onChange={ formContext.updateModel }
            onFocus={ this.handleFocus }
            onBlur={ this.handleBlur }
            aria-invalid={ !!error }
            data-validate={ validate }
            data-autofocus={ autofocus }
            { ...rest }
          />
          {!errorMessage && 
            <Tooltip isActive={ !!error && isFocused }>
              <b className="text-red">{ error }</b>
            </Tooltip>
          }
        </div>
        {errorMessage}
      </React.Fragment>
    );
  }
};

export default props => (
  <FormContext.Consumer>
    { formContext => <FormInput { ...props } formContext={ formContext } /> }
  </FormContext.Consumer>
);

import React from 'react';
import PropTypes from 'prop-types';

import './style.css';
import Icon from 'components/Icon';

const FormCheckbox = ({ changeValue, caption, ...rest }) => (
  <div className="form-checkbox">
    <label className="form-checkbox__label">
      <span className="form-checkbox__control">
        <input type="checkbox" onChange={ changeValue } { ...rest } />
        <Icon glyph="input-valid" />
      </span>
      { !!caption &&
        <span className="form-checkbox__caption">
          { caption }
        </span>
      }
    </label>
  </div>
);

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  changeValue: PropTypes.func.isRequired,
  caption: PropTypes.string,
};

export default FormCheckbox;
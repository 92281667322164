import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.css';

const FormError = ({ children, align }) => children ? (
  <div className={classNames(
    'form-error',
    { [`-align-${align}`]: !!align },
  )}>
    { children }
  </div>
) : null;

FormError.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

export default FormError;

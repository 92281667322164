import axios, { CancelToken } from 'axios';
import { normalize, schema } from 'normalizr';
import { camelizeKeys, camelize } from 'humps';
import get from 'lodash/get';
import qs from 'query-string';
import * as Promise from 'bluebird';
import FormDataServer from 'form-data';

//polyfill for edge
const promiseFinally = require('promise.prototype.finally');
promiseFinally.shim();

import { isSSR, shouldUseDevAPI } from 'common/helpers';

Promise.config({
  cancellation: true,
});

const axiosConfig = {
  baseURL: shouldUseDevAPI()
    ? 'https://linkyou.ru/api/v2'
    : 'https://linkyou.ru/api/v2',
  // headers: {
  //   'x-production': '1',
  // },
};

const SIGNIN_URL = 'auth/signin';
const SIGNUP_URL = 'auth/signup';
const UPDATE_USER_URL = 'user/update?return_user=1';
const FORGOT_URL = 'auth/forgot';

if (
  process.env.BUILD_TARGET === 'server' &&
  process.env.API === 'development' &&
  process.env.RAZZLE_SOCKS_ENABLED === 'true' &&
  process.env.RAZZLE_SOCKS_HOST &&
  process.env.RAZZLE_SOCKS_PORT &&
  process.env.RAZZLE_SOCKS_USER &&
  process.env.RAZZLE_SOCKS_PRIVATE_KEY
) {
  axiosConfig.httpAgent = new require('ssh2').HTTPAgent({
    host: process.env.RAZZLE_SOCKS_HOST,
    port: process.env.RAZZLE_SOCKS_PORT,
    username: process.env.RAZZLE_SOCKS_USER,
    privateKey: require('fs').readFileSync(process.env.RAZZLE_SOCKS_PRIVATE_KEY),
  });
}

export const api = axios.create(axiosConfig);

export const applyToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

export const revokeToken = () => {
  delete api.defaults.headers.common['Authorization'];
};

const getPagination = (headers = {}) => {
  let result;

  Object.keys(headers).forEach(key => {
    const parsedKey = key.replace('x-pagination-', '');

    // not a pagination key
    if (key === parsedKey) {
      return;
    }

    if (!result) {
      result = {};
    }

    const normalValue = headers[key];
    const parsedValue = parseInt(normalValue, 10);
    const camelizedKey = camelize(parsedKey);

    switch (camelizedKey) {
      case 'current':
        result.page = parsedValue;
        result.pageIndex = parsedValue - 1;
        break;
      case 'isEnd':
        result[camelizedKey] = Boolean(parsedValue);
        break;
      default:
        result[camelizedKey] = parsedValue;
    }
  });

  return result;
};

export const sendLogs = (body = []) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(body);
    return;
  }
  
  let form;
  if (isSSR()) {
    form = new FormDataServer();
  } else {
    form = new FormData();
  }
  form.append('error', body.join(' '));

  if (isSSR()) {
    axios.post('https://api.linkyou.ru/v2/debug/error', form, {
      headers: {
        ...form.getHeaders(),
        // 'x-production': '1',
      }
    }).catch(() => {})
  } else {
    api.post('debug/error', form);
  }
}

const callApi = (method, endpoint, body = {}, schema, payload, source) => {
  const params = !isSSR() && (body instanceof FormData)
    ? body
    : qs.stringify(body);

  return api[method](endpoint, params, {
    cancelToken: source.token,
  }).then(res => {
    const camelizedData = camelizeKeys(res.data);
    const pagination = getPagination(res.headers);

    if (schema) {
      return Object.assign({ pagination }, normalize(camelizedData, schema));
    }

    return camelizedData;
  });
};

const withTempId = {
  idAttribute: value => value.tempId || value.id,
};

const usersCompactSchema = new schema.Entity('usersCompact');
const photosSchema = new schema.Entity('photos');
const giftsSchema = new schema.Entity('gifts');
const giftsGivenSchema = new schema.Entity('giftsGiven', {
  user: usersCompactSchema,
  gift: giftsSchema,
});

const usersCompactDefinition = {
  user: usersCompactSchema,
};
const activityDefinition = {
  user: usersCompactSchema,
  gift: giftsSchema,
  photo: photosSchema,
};

const ublogsSchema = new schema.Entity('ublogs', usersCompactDefinition);
const ublogCommentsSchema = new schema.Entity('ublogComments', usersCompactDefinition, withTempId);

const usersSchema = new schema.Entity('users', {
  gifts: {
    items: [ giftsGivenSchema ],
  },
  ublogs: {
    last: ublogsSchema,
  },
});

const blogSchema = new schema.Entity('blog');
const blogCommentsSchema = new schema.Entity('blogComments', usersCompactDefinition, withTempId);
const articleCommentsSchema = new schema.Entity('articleComments', usersCompactDefinition, withTempId);
const photoCommentsSchema = new schema.Entity('photoComments', usersCompactDefinition, withTempId);
const articlesSchema = new schema.Entity('articles', {}, {
  idAttribute: 'code',
});
const citiesSchema = new schema.Entity('cities');
const autocompleteCitiesSchema = new schema.Entity('autocompleteCities');
const autocompleteProfessionsSchema = new schema.Entity('autocompleteProfessions');
const autocompleteOccupationsSchema = new schema.Entity('autocompleteOccupations');
const autocompleteNationalitySchema = new schema.Entity('autocompleteNationality');
const autocompleteReligionsSchema = new schema.Entity('autocompleteReligions');
const autocompleteLanguagesSchema = new schema.Entity('autocompleteLanguages');
const userAnswersSchema = new schema.Entity('userAnswers', activityDefinition);
const userCommentsSchema = new schema.Entity('userComments', activityDefinition);
const userLikesSchema = new schema.Entity('userLikes', activityDefinition);
const userSympathiesSchema = new schema.Entity('userSympathies', usersCompactDefinition);
const imMessageSchema = new schema.Entity('imMessages', usersCompactDefinition, withTempId);
const imDialogsSchema = new schema.Entity('imDialogs', {
  fromUser: usersCompactSchema,
  lastMessage: imMessageSchema,
});
const tagsSchema = new schema.Entity('tags', {}, {
  idAttribute: 'value',
});

export const Schemas = {
  AUTH: {
    user: usersSchema,
  },
  USERS_INTERESTING: {
    items: [ usersCompactSchema ],
  },
  USERS_GUESTS: [{
    user: usersCompactSchema,
  }],
  USER_GIFTS: [ giftsGivenSchema ],
  USER_GIFTS_RECOMMENDED: [ giftsSchema ],
  USER_PHOTO: photosSchema,
  USER_PHOTOS: [ photosSchema ],
  USER_ANSWERS: [ userAnswersSchema ],
  USER_COMMENTS: [ userCommentsSchema ],
  USER_LIKES: [ userLikesSchema ],
  USER_SYMPATHIES: [ userSympathiesSchema ],
  USER: usersSchema,
  USERS: [ usersSchema ],
  USER_COMPACT: usersCompactSchema,
  USERS_COMPACT: [ usersCompactSchema ],
  USER_BOTH: { full: usersSchema, short: usersCompactSchema },
  BLOG: blogSchema,
  BLOGS: [ blogSchema ],
  BLOG_COMMENT: blogCommentsSchema,
  BLOG_COMMENTS: [ blogCommentsSchema ],
  UBLOG: ublogsSchema,
  UBLOGS: [ ublogsSchema ],
  UBLOG_COMMENT: ublogCommentsSchema,
  UBLOG_COMMENTS: [ ublogCommentsSchema ],
  PHOTO_COMMENT: photoCommentsSchema,
  PHOTO_COMMENTS: [ photoCommentsSchema ],
  ARTICLE: articlesSchema,
  ARTICLES: [ articlesSchema ],
  ARTICLE_COMMENT: articleCommentsSchema,
  ARTICLE_COMMENTS: [ articleCommentsSchema ],
  CITIES: [ citiesSchema ],
  AUTOCOMPLETE_CITIES: [ autocompleteCitiesSchema ],
  AUTOCOMPLETE_PROFESSIONS: [ autocompleteProfessionsSchema ],
  AUTOCOMPLETE_OCCUPATIONS: [ autocompleteOccupationsSchema ],
  AUTOCOMPLETE_NATIONALITY: [ autocompleteNationalitySchema ],
  AUTOCOMPLETE_RELIGIONS: [ autocompleteReligionsSchema ],
  AUTOCOMPLETE_LANGUAGES: [ autocompleteLanguagesSchema ],
  GIFTS_STORE: [{
    items: [ giftsSchema ],
  }],
  IM_DIALOG: imDialogsSchema,
  IM_DIALOGS: [ imDialogsSchema ],
  IM_MESSAGES: {
    dialog: imDialogsSchema,
    messages: [ imMessageSchema ],
  },
  IM_MESSAGE: imMessageSchema,
  TAGS: [ tagsSchema ],
};

export const CALL_API = 'Call API';

export default store => next => action => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { method, endpoint, schema, body, payload, types } = callAPI;

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  }

  const [ requestType, successType, failureType ] = types;
  next(actionWith({ payload, type: requestType }));

  // return non-handled promise in order to make getInitialProps work properly
  return new Promise((resolve, reject, onCancel) => {
    const source = CancelToken.source();
    let res;
    let err;

    onCancel(source.cancel);

    return callApi(method, endpoint, body, schema, payload, source).then(
      response => {
        if (endpoint === SIGNIN_URL || endpoint === SIGNUP_URL) {
          applyToken(response.result && response.result.token)
        }
        res = response;
        return next(actionWith({
          response,
          payload,
          type: successType,
        }))
      },
      error => {
        err = error;
        if (error && error.response && error.response.status === 401 && 
          endpoint !== SIGNIN_URL && endpoint !== SIGNUP_URL) {
          if (!isSSR()) {
            document.location.reload();
          }
        } else {
          return next(actionWith({
            payload,
            type: failureType,
            error: error.response ? error.response.data : error.message,
          }))
        }
      },
    ).finally(() => {
      if (res !== undefined) {
        resolve(res);
      } else {
        reject(err);
      }
    })
  }).catch(err => {
    let invalidBirthdayRequest;
    if (endpoint === UPDATE_USER_URL) {
      const errMessages = get(err, 'response.data.message[0]')
      invalidBirthdayRequest = endpoint === UPDATE_USER_URL && errMessages && !!errMessages.birthday
    }
    
    let invalidForgotRequest;
    if (endpoint === FORGOT_URL) {
      const status = get(err, 'response.data.status')
      invalidForgotRequest = status === 400;
    }
    
    if (err && err.response && err.response.status !== 401 
      && !invalidBirthdayRequest
      && !invalidForgotRequest
    ) {
      let body = [
        err.toString(), 
        err.stack, 
        `api/v2/${endpoint}`,
      ]
      if (endpoint === UPDATE_USER_URL) {
        const errMessages = get(err, 'response.data.message')
        body.push(JSON.stringify(errMessages));
      }
      sendLogs(body)
    }
    return new Error(err)
  });
};

import React, { Component } from 'react';

class SectionBody extends Component {
  render() {
    return (
      <div className="section__body">
        { this.props.children }
      </div>
    );
  }
};

export default SectionBody;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';

import { isSSR, getLSKey, LS_KEYS, getUser } from 'common/helpers';
import { addGift, loadCurrentUser, loadUser, loadUserGifts } from 'common/actions';
import { sendPacket, GIFT_NEW } from 'common/middleware/socketio';

import Modal from 'components/Modal';
import ModalSuccess from 'components/ModalSuccess';
import UserCardMini from 'components/UserCardMini';
import UserJob from 'components/UserJob';
import Write from 'components/Write';

import './style.css';

class ModalGiftSend extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleCloseAll: PropTypes.func.isRequired,
    recipientId: PropTypes.number.isRequired,
    giftId: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      isSuccess: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { requestState, recipientId, loadCurrentUser, loadUser, loadUserGifts } = this.props;

    if (!isSSR() && requestState.success && prevProps.requestState.request) {
      if (recipientId) {
        loadUser(recipientId);
        loadUserGifts(recipientId);
        loadCurrentUser();
      }

      this.setState({
        isSuccess: true,
      });
    }
  }

  onSubmit(data) {
    const { currentUser, recipientId, giftId, addGift } = this.props;

    addGift(recipientId, {
      gift_id: giftId,
      text: data.comment,
      is_private: data.anonymous,
    }).then(() => {
      sendPacket(GIFT_NEW, { giftId, fromUserId: currentUser.id }, [ recipientId ]);
    });
  }

  onClose() {
    this.setState({
      isSuccess: false,
    }, this.props.handleCloseAll);
  }

  render() {
    const { isOpen, gift, user, currentUser, recipientId, giftId, requestState } = this.props;
    const { isSuccess } = this.state;

    if (!gift || !recipientId || !giftId || !user || isEmpty(user)) {
      return null;
    }

    return (
      <React.Fragment>
        <Modal isOpen={ isOpen && !isSuccess } handleClose={ this.onClose }>
          <div className="modal-gift-send">
            <h2>Отправить подарок</h2>
            <div className="modal-gift-send__recipient">
              <UserCardMini user={ user } size="medium">
                <UserJob job={ user.job } />
              </UserCardMini>
            </div>
            <figure>
              <img src={ gift.picture } alt={ gift.name } />
            </figure>
            <Write
              mode="gift"
              placeholder="Напишите комментарий…" 
              currentUser={ currentUser } 
              onSubmit={ this.onSubmit }
              requestState={ requestState }
              allowEmpty
            />
          </div>
        </Modal>
        <ModalSuccess isOpen={ isSuccess } handleClose={ this.onClose } headline="Подарок отправлен!" />
      </React.Fragment>
    );
  }
};

function mapStateToProps(state, ownProps) {
  return {
    gift: (state.entities.gifts || {})[ownProps.giftId],
    user: getUser(state, ownProps.recipientId),
    currentUser: getUser(state, (state.session || {}).user),
    requestState: state.states.addGift || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addGift,
    loadCurrentUser,
    loadUser,
    loadUserGifts,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalGiftSend);

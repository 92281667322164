import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ymaps from 'ymaps';
import axios from 'axios';
import noop from 'lodash/noop';

import Form from 'components/Form';
import FormButton from 'components/FormButton';
import FormInput from 'components/FormInput';
import Modal from 'components/Modal';
import Preloader from 'components/Preloader';

import { isSSR, withParams } from 'common/helpers';

import './style.css';
import moveImage from './images/move.png';

const API = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';

class ModalGeo extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onChoose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
    this.rootRef = React.createRef();

    this.emitAddress = this.emitAddress.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.loadYandexMaps = this.loadYandexMaps.bind(this);
    this.handlePlacemarkMove = this.handlePlacemarkMove.bind(this);

    this.state = {
      coords: [55.753215, 37.622504],
      address: 'Красная площадь, Москва, Россия',
      isError: false,
      mapsConstructor: null,
      map: null,
      placemark: null,
    };
  }

  componentDidMount() {
    this.loadYandexMaps();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;
    const { mapsConstructor } = this.state;

    if (
      (isOpen && !prevProps.isOpen) ||
      (isOpen && mapsConstructor && !prevState.mapsConstructor)
    ) {
      this.initYandexMaps();
    }
  }

  loadYandexMaps() {
    const existingAPI = document.querySelector(`script[src="${API}"]`);

    if (existingAPI) {
      existingAPI.parentNode.removeChild(existingAPI);
    }

    setTimeout(() => {
      this.setState({
        isError: false,
      });

      ymaps.load(API).then(maps => {
        this.setState({
          mapsConstructor: maps,
        });
      }).catch(() => {
        this.setState({
          isError: true,
        });
      });
    });
  }

  initYandexMaps() {
    const { mapsConstructor, coords } = this.state;

    if (!mapsConstructor) {
      return this.setState({
        isError: true,
      });
    }

    this.setState({
      map: new mapsConstructor.Map(this.mapRef.current, {
        center: coords,
        zoom: 14,
        controls: ['zoomControl', 'geolocationControl'],
      }),
      placemark: new mapsConstructor.Placemark(coords, {}, {
        iconLayout: 'default#image',
        iconImageHref: moveImage,
        iconImageSize: [33, 32],
        iconImageOffset: [-16, -16],
        draggable: true,
      }),
    }, () => {
      this.state.placemark.events.add('dragend', this.handlePlacemarkMove);
      this.state.map.geoObjects.add(this.state.placemark);
      this.dangerouslyUpdateInputValue();

      this.getUserLocation();
    });
  }

  dangerouslyUpdateInputValue() {
    // Really Dangerously...
    // https://github.com/facebook/react/issues/11488#issuecomment-347775628

    const { address } = this.state;
    const input = this.rootRef.current.querySelector('[name="address"]');

    if (!input) {
      return;
    }

    const lastValue = input.value;
    const event = new Event('change', { bubbles: true });
    const tracker = input._valueTracker;

    input.value = address;

    if (tracker) {
      tracker.setValue(lastValue);
    }

    input.dispatchEvent(event);
  }

  handlePlacemarkMove() {
    const coords = this.state.placemark.geometry.getCoordinates();

    this.geocode(coords);
    this.setState({
      coords,
    });
  }

  handleSearch(e) {
    const keyCode = e.keyCode || e.charCode;

    if (e.type === 'blur' || keyCode === 13) {
      this.geocode(e.target.value);
    }
  }

  geocode(query, shouldUpdatePoint = false) {
    let preparedQuery;

    if (Array.isArray(query)) {
      preparedQuery = query.slice(0).reverse().join(',');
    }
    if (typeof query === 'string') {
      preparedQuery = query.replace(/\s/g, '+');
    }

    if (!preparedQuery) {
      return;
    }

    const endpoint = withParams('https://geocode-maps.yandex.ru/1.x/', {
      geocode: preparedQuery,
      format: 'json',
      results: 1,
    });

    axios.get(endpoint, {
      transformRequest(data, headers) {
        delete headers.common.Authorization;
        delete headers['x-production'];

        return data;
      },
    }).then(({ data }) => {
      const result = data.response.GeoObjectCollection.featureMember;

      if (!result.length || !this.props.isOpen) {
        return;
      }

      const object = result[0].GeoObject;
      const coords = object.Point.pos.split(' ').reverse().map(c => parseFloat(c, 10));
      const address = [object.name, object.description].join(', ');

      this.setState({
        coords,
        address,
      }, () => {
        this.dangerouslyUpdateInputValue();

        // Update map position only if user searches by address
        if (typeof query === 'string' || shouldUpdatePoint) {
          this.state.map.setCenter(coords);
          this.state.placemark.geometry.setCoordinates(coords);
        }
      });
    });
  }

  getUserLocation() {
    if (!isSSR() && 'geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        this.geocode([coords.latitude, coords.longitude], true);
      });
    }
  }

  emitAddress() {
    const { coords, address } = this.state;
    const { onChoose, handleClose } = this.props;

    onChoose(`
      <a
        href="https://yandex.ru/maps/?text=${coords.join(',')}"
        class="geo-link"
        target="_blank"
        rel="noopener noreferrer"
        contenteditable="false"
      >
        <span>${address}</span>
      </a>
    `);

    handleClose();
  }

  render() {
    const { isOpen, handleClose } = this.props;
    const { isError } = this.state;

    return (
      <Modal isOpen={ isOpen } handleClose={ handleClose }>
        <div className="modal-geo" ref={ this.rootRef }>
          <div className="modal-geo__container">
            <h2>Отправить геолокацию</h2>
            <Form action={ noop }>
              <FormInput 
                name="address"
                placeholder="Поиск адреса по названию"
                icon="location"
                onBlur={ this.handleSearch }
                onKeyUp={ this.handleSearch }
              />
            </Form>
            <div className="modal-geo__map" ref={ this.mapRef }>
              <div className="modal-geo__map-preloader">
                { isError
                  ? <p>Ошибка при загрузке карты. <button type="button" onClick={ this.loadYandexMaps }>Попробовать снова</button></p>
                  : <Preloader />
                }
              </div>
            </div>
            <div className="modal-geo__buttons">
              <FormButton type="button" skin={ ['lightblue', 'size-m'] } onClick={ handleClose }>
                Отмена
              </FormButton>
              <FormButton type="button" skin={ ['darkblue', 'size-m'] } onClick={ this.emitAddress }>
                ОК
              </FormButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalGeo;

import React from 'react';

import './style.css';

const EmptyPage = ({ children }) => (
  <div className="empty-page">
    <div className="empty-page__frame">
      { children }
    </div>
  </div>
);

export default EmptyPage;

import React from 'react';
import PropTypes from 'prop-types';

import Preloader from 'components/Preloader';

import './style.css';

const Iframe = ({ src, title }) => (
  <div className="iframe">
    <div className="iframe__preloader">
      <Preloader />
    </div>
    <iframe
      src={ src }
      title={ title }
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
    />
  </div>
);

Iframe.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Iframe;

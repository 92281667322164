import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

import './style.css';

const Badge = ({ type, size, value }) => (
  <span
    className={classNames(
      'badge',
      `-${type}`,
      `-${size}`,
    )}
  >
    <Icon glyph={ `${type}-fill` } />
    { value && type === 'crown' && size === 'l'
      ? <span className="badge__value">{ value }</span>
      : null
    }
    { type === 'star' && size === 'm'
      ? <Tooltip>
          <b>Premium-пользователь</b>
        </Tooltip>
      : null
    }
  </span>
);

Badge.propTypes = {
  type: PropTypes.oneOf(['star', 'crown']).isRequired,
  size: PropTypes.oneOf(['s', 'm', 'l']).isRequired,
  value: PropTypes.number,
};

export default Badge;

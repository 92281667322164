import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import striptags from 'striptags';

import Scrollable from 'components/Scrollable';

import './style.css';

class WriteInput extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onInput: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    multiline: PropTypes.bool,
    autoFocus: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handlePaste = this.handlePaste.bind(this);

    this.state = {
      caretAt: 0,
    };
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => {
        this.inputRef.current && this.inputRef.current.focus();
      });
    }
  }

  handleInput() {
    let value = this.inputRef.current.innerHTML;

    value = value.replace(/&nbsp;/g, '\u00a0');

    this.props.onInput(value);
  }

  handleKeyPress(e) {
    const key = e.keyCode || e.charCode;

    if (key === 13) {
      this.props.onSubmit(e);
    }
  }

  handleClick(e) {
    const { value } = this.props;

    if (!value || e.detail !== 3) {
      return;
    }

    document.execCommand('selectAll', false, null);
  }

  handlePaste(e) {
    const type = 'text/html';
    const caretAt = window.getSelection().getRangeAt(0).startOffset;

    this.setState({
      caretAt,
    });

    // Browsers that support the 'text/html' type in the Clipboard API (Chrome, Firefox 22+)
    if (e && e.clipboardData && e.clipboardData.types && e.clipboardData.getData) {
      const types = e.clipboardData.types;

      if (((types instanceof DOMStringList) && types.contains(type)) || (types.indexOf && types.indexOf(type) !== -1)) {
        const pastedData = e.clipboardData.getData(type);

        e.stopPropagation();
        e.preventDefault();

        return this.processPaste(pastedData);
      }
    }

    // Everything else: Move existing element contents to a DocumentFragment for safekeeping
    const savedContent = document.createDocumentFragment();

    while(this.inputRef.current.childNodes.length > 0) {
      savedContent.appendChild(this.inputRef.current.childNodes[0]);
    }

    // Then wait for browser to paste content into it and cleanup
    this.waitForPastedData(savedContent);
  }

  waitForPastedData(savedContent) {
    // If data has been processed by browser, process it.
    // Otherwise, wait 20ms and try again.
    if (this.inputRef.current.childNodes && this.inputRef.current.childNodes.length > 0) {
      const pastedData = this.inputRef.current.innerHTML;

      this.inputRef.current.innerHTML = '';
      this.inputRef.current.appendChild(savedContent);

      this.processPaste(pastedData);
    } else {
      setTimeout(() => {
        this.waitForPastedData(savedContent);
      }, 20);
    }
  }

  moveCaret(offset) {
    const range = document.createRange();
    const selection = window.getSelection();

    if (offset === 'end') {
      range.selectNodeContents(this.inputRef.current);
      range.collapse(false);
    } else {
      range.setStart(this.inputRef.current.firstChild, offset);
      range.collapse(true);
    }

    selection.removeAllRanges();
    selection.addRange(range);
  }

  pasteAtCaret(html) {
    const selection = window.getSelection();

    if (
      selection.anchorNode === null ||
      (
        selection.anchorNode !== this.inputRef.current &&
        selection.anchorNode.parentNode !== this.inputRef.current
      )
    ) {
      this.inputRef.current.focus();
      this.moveCaret('end');
    }

    if (selection.getRangeAt && selection.rangeCount) {
      const proxy = document.createElement('div');
      const fragment = document.createDocumentFragment();

      let range = selection.getRangeAt(0);
      let node;
      let lastNode;

      proxy.innerHTML = html;

      while ((node = proxy.firstChild)) {
        lastNode = fragment.appendChild(node);
      }

      range.deleteContents();
      range.insertNode(fragment);

      if (lastNode) {
        range = range.cloneRange();
        range.setStartAfter(lastNode);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }

  processPaste(pastedData) {
    const { caretAt } = this.state;
    const command = 'insertHTML';

    // Remove tags, except allowed,
    // and 'style' attributes from remaining ones.
    // Remove whitespaces in Chrome either.
    pastedData = striptags(pastedData, ['a', 'img']);
    pastedData = pastedData.replace(/(<[^>]+) style=".*?"/gi, '$1');
    pastedData = pastedData.replace(/&nbsp;/g, '\u00a0');

    this.inputRef.current.focus();

    if (document.queryCommandSupported(command)) {
      return document.execCommand(command, false, pastedData);
    }

    this.moveCaret(caretAt);
    this.pasteAtCaret(pastedData);
  }

  render() {
    const { placeholder, value, multiline } = this.props;

    return (
      <div
        className={classNames(
          'write-input',
          { '-filled': !!value },
          { '-multiline': multiline }
        )}
      >
        <div className="write-input__container">
          <Scrollable>
            <div
              className="write-input__input"
              ref={ this.inputRef }
              onBlur={ this.handleBlur }
              onClick={ this.handleClick }
              onInput={ this.handleInput }
              onKeyPress={ this.handleKeyPress }
              onPaste={ this.handlePaste }
              contentEditable
            />
          </Scrollable>
        </div>
        <div className="write-input__placeholder">
          { placeholder }
        </div>
      </div>
    );
  }
};

export default WriteInput;

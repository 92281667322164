import React from 'react';

import './style.css';
import logoImage from './images/logo.svg';
import LazyLoadImage from 'components/LazyLoadImage';

const PartnerSochi = () => (
  <a href="http://www.hcsochi.ru/" target="_blank" rel="noopener noreferrer nofollow" className="partner-sochi">
    <LazyLoadImage 
      alt="Логотип ХК «Сочи»"
      src={logoImage}
    />
    <b>LinkYou&nbsp;— официальный партнер ХК&nbsp;«Сочи»</b>
  </a>
);

export default PartnerSochi;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';

import { getLSKey, LS_KEYS, COOKIE_PARAMS } from 'common/helpers';
import { applyToken } from 'common/middleware/api';
import { connectSockets, disconnectSockets } from 'common/middleware/socketio';

import UserPanelProfile from './components/Profile';
import Auth from 'components/Auth';
import Section, { SectionBody } from 'components/Section';

import './style.css';

class UserPanel extends Component {
  componentDidMount() {
    this.initSession();
  }

  componentDidUpdate(prevProps) {
    const { session } = this.props;
    if (session && !prevProps.session ||
      session && prevProps.session && session.token !== prevProps.session.token) {
      this.initSession();
    }
  }

  componentWillUnmount() {
    disconnectSockets();
  }

  initSession() {
    const { session, cookies } = this.props;

    if (session) {
      applyToken(session.token);
      connectSockets(session.token);
      cookies.set(getLSKey(LS_KEYS.SESSION), session, COOKIE_PARAMS);
    }
  };

  render() {
    const { session, isFormWorking } = this.props;

    if (session && !isFormWorking) {
      return <UserPanelProfile />;
    } else {
      return (
        <div className="user-panel__auth">
          <Section>
            <SectionBody>
              <Auth />
            </SectionBody>
          </Section>
        </div>
      );
    }
  }
};

function mapStateToProps(state) {
  const {
    session,
    workingForms,
  } = state;
  const isFormWorking =
    workingForms.indexOf('form-auth-signin') > -1 ||
    workingForms.indexOf('form-auth-signup') > -1;

  return {
    session,
    isFormWorking,
  };
}

export default withCookies(connect(
  mapStateToProps,
)(UserPanel));

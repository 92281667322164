import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import classNames from 'classnames';
import isMobile from 'ismobilejs';
import shortid from 'shortid';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { datetime, resolution, isSSR, getUser } from 'common/helpers';
import { openModalAlbum, closeModal, uploadPhotos } from 'common/actions';
import { selectUploadedPhotos } from './selectors';

import FormButton from 'components/FormButton';
import FormCheckbox from 'components/FormCheckbox';
import FormError from 'components/FormError';
import Icon from 'components/Icon';
import Iframe from 'components/Iframe';
import ModalGeo from 'components/ModalGeo';
import ModalYandexMusic from 'components/ModalYandexMusic';
import ModalYouTube from 'components/ModalYouTube';
import PhotoGrid from 'components/PhotoGrid';
import Preloader from 'components/Preloader';
import Tooltip from 'components/Tooltip';
import UserAvatar from 'components/UserAvatar';
import WriteEmoji from './components/Emoji';
import WriteInput from './components/Input';
import WritePane from './components/Pane';

import './style.css';

const IM = 'im';
const GALLERY = 'gallery';
const BLOG = 'blog';
const GIFT = 'gift';

const MODAL_GEO = 'MODAL_GEO';
const MODAL_YANDEX_MUSIC = 'MODAL_YANDEX_MUSIC';
const MODAL_YOUTUBE = 'MODAL_YOUTUBE';

const INITIAL_STATE = {
  addingAttachment: false,
  anonymous: true,
  audioIframeLink: '',
  audioLink: '',
  isEmojiOpen: false,
  paneOpen: '',
  isRequest: false,
  modalOpen: '',
  value: '',
  videoIframeLink: '',
  videoLink: '',
  photos: {},
  photosInQueue: [],
  fileInputKey: 0,
};

class Write extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onType: PropTypes.func,
    onCancelEditing: PropTypes.func,
    onChangeAttachmentsState: PropTypes.func,
    onGiftSend: PropTypes.func,
    entityId: PropTypes.number,
    multiline: PropTypes.bool,
    placeholder: PropTypes.string,
    mode: PropTypes.oneOf([IM, BLOG, GIFT, GALLERY]),
    tooltipAt: PropTypes.string,
    requestState: PropTypes.object,
    editData: PropTypes.object,
    allowEmpty: PropTypes.bool,
    autoFocus: PropTypes.bool,
  }

  static defaultProps = {
    multiline: false,
    onChangeAttachmentsState: noop,
    allowEmpty: false,
    autoFocus: true,
  }

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.rootRef = React.createRef();

    this.photosUpload = this.photosUpload.bind(this);
    this.attachAudio = this.attachAudio.bind(this);
    this.attachVideo = this.attachVideo.bind(this);
    this.clearEmojiTimeout = this.clearEmojiTimeout.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideAttachmentsPane = this.hideAttachmentsPane.bind(this);
    this.hideEmoji = this.hideEmoji.bind(this);
    this.insertHTML = this.insertHTML.bind(this);
    this.openModalGeo = this.openModalGeo.bind(this);
    this.openModalPhotos = this.openModalPhotos.bind(this);
    this.openModalYandexMusic = this.openModalYandexMusic.bind(this);
    this.openModalYouTube = this.openModalYouTube.bind(this);
    this.paneClose = this.paneClose.bind(this);
    this.paneOpen = this.paneOpen.bind(this);
    this.paneOpenAlbum = this.paneOpenAlbum.bind(this);
    this.paneOpenGifts = this.paneOpenGifts.bind(this);
    this.paneOpenLocation = this.paneOpenLocation.bind(this);
    this.photoAdd = this.photoAdd.bind(this);
    this.removeAudio = this.removeAudio.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
    this.removeVideo = this.removeVideo.bind(this);
    this.showAttachmentsPane = this.showAttachmentsPane.bind(this);
    this.showEmoji = this.showEmoji.bind(this);
    this.toggleEmoji = this.toggleEmoji.bind(this);

    this.emojiTimeout = null;

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    const { editData } = this.props;

    if (editData) {
      this.handleEdit(editData);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { requestState, uploadedPhotos, uploadPhotosRequestState, editData } = this.props;

    // Снимаем запрос с очереди, если загрузка фото не удалась
    if (uploadPhotosRequestState.error && !prevProps.uploadPhotosRequestState.error) {
      this.handleUploadError(uploadPhotosRequestState.payload.requestId);
    }

    // Убираем запрос из очереди после успешной загрузки фото
    if (!isEqual(uploadedPhotos, prevProps.uploadedPhotos)) {
      this.setState(state => ({
        photosInQueue: without(state.photosInQueue, ...uploadedPhotos),
      }));
    }

    // Включаем режим редактирования поста
    if (editData && !prevProps.editData) {
      this.handleEdit(editData);
    }

    // Обрабатываем смену состояния после запроса на добаление сущности (поста, комментария)
    if (requestState && prevProps.requestState && prevProps.requestState.request) {
      if (!requestState.request) {
        this.setState({
          isRequest: false,
        });
      }

      if (requestState.success) {
        this.clearField();
      }
    }
  }

  handleUploadError(requestId) {
    this.setState(state => {
      const photosInQueue = without(state.photosInQueue, requestId);
      const photos = Object.assign({}, state.photos);

      Object.keys(photos).forEach(photoId => {
        if (photos[photoId].requestId === requestId) {
          delete photos[photoId];
        }
      });

      return {
        photosInQueue,
        photos,
      };
    });
  }

  handleEdit(data) {
    this.inputRef.current.inputRef.current.innerHTML = data.text;
    this.inputRef.current.moveCaret('end');

    this.setState({
      audioIframeLink: data.audioLink,
      audioLink: data.audioLink,
      value: data.text,
      videoIframeLink: data.videoLink,
      videoLink: data.videoLink,
      photos: data.photos.slice(0).reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
      }, {}),
    });
  }

  handleCheckbox(e) {
    const input = e.target;

    this.setState({
      [input.name]: input.checked,
    });
  }

  handleInput(value) {
    const { onType } = this.props;

    if (typeof onType === 'function') {
      onType();
    }

    this.setState({ value });
  }

  handleSubmit(e) {
    const { value, videoLink, audioLink, anonymous, photos, photosInQueue } = this.state;
    const { currentUser, entityId, onSubmit, uploads, editData, allowEmpty, requestState } = this.props;
    const isValueEmpty = !value.trim().length;
    const data = {};

    e.preventDefault();

    if (
      photosInQueue.length ||
      (!allowEmpty && isValueEmpty) ||
      (allowEmpty && isValueEmpty && isEmpty(photos))
    ) {
      return;
    }

    data.comment = value;
    data.anonymous = anonymous;
    data.datetime = datetime();
    data.tempId = Math.random();
    data.user = currentUser.id;
    data.videoLink = videoLink;
    data.audioLink = audioLink;
    data.photos = [];

    if (editData) {
      data.postId = editData.id;
    }

    Object.keys(photos).forEach(photoId => {
      const { requestId } = photos[photoId];

      if (requestId) {
        if (requestId in uploads) {
          uploads[requestId].forEach(photo => {
            data.photos.push(photo.src);
          });
        }
      } else {
        data.photos.push(photos[photoId].src);
      }
    });

    data.photos = uniq(data.photos);

    onSubmit(data, entityId);

    if (requestState) {
      this.setState({
        isRequest: true,
      });
    } else {
      this.clearField();
    }
  }

  handleCancel() {
    this.clearField();
    this.props.onCancelEditing();
  }

  clearField() {
    this.props.onChangeAttachmentsState(false);
    this.inputRef.current.inputRef.current.innerHTML = '';
    this.setState({
      ...INITIAL_STATE,
    });
  }

  isWithAttachments(state = this.state) {
    const { videoLink, audioLink, photos } = state;
    const withAttachments = videoLink || audioLink || !isEmpty(photos);

    return withAttachments;
  }

  hideAttachmentsPane() {
    this.setState({
      addingAttachment: false,
    });
  }

  showAttachmentsPane() {
    this.setState({
      addingAttachment: true,
    });
  }

  hideEmoji() {
    const timeout = isMobile.any ? 0 : 750;

    this.clearEmojiTimeout();

    this.emojiTimeout = setTimeout(() => {
      this.setState({
        isEmojiOpen: false,
      });
    }, timeout);
  }

  clearEmojiTimeout() {
    clearTimeout(this.emojiTimeout);
  }

  showEmoji() {
    this.clearEmojiTimeout();

    this.setState({
      isEmojiOpen: true,
    });
  }

  toggleEmoji() {
    this.setState(state => ({
      isEmojiOpen: !state.isEmojiOpen,
    }));
  }

  removePhoto(id, requestId) {
    this.setState(state => {
      const newState = {
        photos: omit(state.photos, [id]),
      };

      let photosInRequestCount = 0;

      Object.keys(state.photos).forEach(key => {
        if (state.photos[key].requestId === requestId) {
          photosInRequestCount++;
        }
      });

      if (photosInRequestCount <= 1) {
        newState.photosInQueue = without(state.photosInQueue, id);
      }

      return newState;
    }, () => {
      if (!this.isWithAttachments()) {
        this.props.onChangeAttachmentsState(false);
      }
    });
  }

  removeVideo() {
    this.setState({
      videoIframeLink: '',
      videoLink: '',
    }, () => {
      if (!this.isWithAttachments()) {
        this.props.onChangeAttachmentsState(false);
      }
    });
  }

  removeAudio() {
    this.setState({
      audioIframeLink: '',
      audioLink: '',
    }, () => {
      if (!this.isWithAttachments()) {
        this.props.onChangeAttachmentsState(false);
      }
    });
  }

  openModal(type) {
    this.setState({
      modalOpen: type,
    });
  }

  openModalGeo() {
    this.openModal(MODAL_GEO);
  }

  openModalYouTube() {
    this.openModal(MODAL_YOUTUBE);
  }

  openModalYandexMusic() {
    this.openModal(MODAL_YANDEX_MUSIC);
  }

  openModalPhotos() {
    const { currentUser, openModalAlbum } = this.props;

    openModalAlbum(currentUser.id, {
      allowSelect: true,
      onChoose: this.photoAdd,
    });
  }

  closeModal() {
    this.setState({
      modalOpen: '',
    });
  }

  attachVideo(videoLink, videoIframeLink) {
    this.setState({
      videoLink,
      videoIframeLink,
    }, () => {
      this.props.onChangeAttachmentsState(true);
      this.closeModal();
    });
  }

  attachAudio(audioLink, audioIframeLink) {
    this.setState({
      audioLink,
      audioIframeLink,
    }, () => {
      this.props.onChangeAttachmentsState(true);
      this.closeModal();
    });
  }

  insertHTML(html) {
    this.inputRef.current.pasteAtCaret(html);
    this.inputRef.current.handleInput();
  }

  photosUpload(e) {
    const { uploadPhotos, onChangeAttachmentsState } = this.props;
    const { files } = e.target;
    const filesPreview = [];
    const requestId = shortid.generate();
    const usedTempIds = [];

    const generateTempId = () => {
      const id = Math.floor(Math.random() * 1000) + 1;

      if (usedTempIds.indexOf(id) === -1) {
        usedTempIds.push(id);
        return id;
      }

      generateTempId();
    };

    uploadPhotos(files, requestId);

    this.setState(state => {
      const photosInQueue = state.photosInQueue.slice(0);

      photosInQueue.push(requestId);

      return {
        photosInQueue,
      };
    });

    for (let i = 0; i < files.length; i++) {
      const fileReader = new FileReader();
      const file = files[i];

      fileReader.onload = e => {
        const image = new Image();
        const src = e.target.result;

        image.onload = () => {
          filesPreview.push({
            src,
            requestId,
            id: generateTempId(),
          });

          if (filesPreview.length === files.length) {
            this.photoAdd(filesPreview, null, requestId);
          }
        };

        image.src = src;
      };

      fileReader.readAsDataURL(file);
    }

    onChangeAttachmentsState(true);

    // hardly reset input value
    this.setState(state => ({
      fileInputKey: state.fileInputKey + 1,
    }));
  }

  photoAdd(photos, index, id) {
    this.setState(state => {
      const newPhotos = Object.assign({}, state.photos);

      photos.forEach(photo => {
        newPhotos[photo.id] = {
          id: photo.id,
          src: photo.src,
          requestId: id,
        };
      });

      return {
        photos: newPhotos,
      };
    }, this.props.closeModal);
  }

  paneOpen(mode) {
    this.setState({
      paneOpen: mode,
    });
  }

  paneOpenAlbum() {
    this.paneOpen('photo');
  }

  paneOpenGifts() {
    this.paneOpen('gift');
  }

  paneOpenLocation() {
    this.paneOpen('location');
  }

  paneClose() {
    this.setState({
      paneOpen: '',
    });
  }

  renderFileInput() {
    return (
      <input type="file" accept="image/png, image/jpeg" onChange={ this.photosUpload } key={ this.state.fileInputKey } multiple />
    );
  }

  render() {
    const {
      currentUser,
      multiline,
      placeholder,
      mode,
      tooltipAt,
      autoFocus,
      entityId,
      editData,
      requestState,
      onGiftSend,
    } = this.props;
    const {
      anonymous,
      value,
      addingAttachment,
      modalOpen,
      videoIframeLink,
      audioIframeLink,
      isEmojiOpen,
      paneOpen,
      isRequest,
      photos,
      photosInQueue,
    } = this.state;

    if (!currentUser || isEmpty(currentUser)) {
      return null;
    }

    return (
      <React.Fragment>
        <form
          className={classNames(
            'write',
            { '-submitting': isRequest },
          )}
          onSubmit={ this.handleSubmit }
          ref={ this.rootRef }
        >
          <div
            className={classNames(
              'write__form',
              { '-empty': !value },
            )}
          >
            <UserAvatar skin="circle" size="medium" user={ currentUser } isSelf withoutBadges />
            <div className="write__form-field">
              <WriteInput
                ref={ this.inputRef }
                value={ value }
                placeholder={ placeholder }
                multiline={ multiline }
                onInput={ this.handleInput }
                onSubmit={ this.handleSubmit }
                autoFocus={ autoFocus }
              />
              <div className="write__form-field-options">
                <div className="write__form-field-options-item">
                  <button
                    type="button"
                    className="write__form-field-options-handler"
                    onMouseEnter={ isMobile.any ? noop : this.showEmoji }
                    onMouseLeave={ isMobile.any ? noop : this.hideEmoji }
                    onClick={ isMobile.any ? this.toggleEmoji : noop  }
                  >
                    <Icon glyph="emoji" />
                  </button>
                  { !isSSR() && !resolution.isMobile() &&
                    <WriteEmoji
                      mode="dropdown"
                      at={ tooltipAt }
                      isOpen={ isEmojiOpen }
                      onChoose={ this.insertHTML }
                      onClose={ this.hideEmoji }
                      onMouseEnter={ isMobile.any ? noop : this.clearEmojiTimeout }
                      onMouseLeave={ isMobile.any ? noop : this.hideEmoji }
                    />
                  }
                </div>
                { [BLOG, IM].indexOf(mode) !== -1 &&
                  <React.Fragment>
                    <div className="write__form-field-options-item">
                      { mode === BLOG
                        ? <label className="write__form-field-options-handler hidden-b-m">
                            { this.renderFileInput() }
                            <Icon glyph="photo" />
                          </label>
                        : <button type="button" className="write__form-field-options-handler hidden-b-m" onClick={ resolution.isMobile() ? this.paneOpenAlbum : this.openModalPhotos }>
                            <Icon glyph="photo" />
                            Прикрепить фотографии
                          </button>
                      }
                    </div>
                    <div className="write__form-field-options-item">
                      <button
                        type="button"
                        className={classNames(
                          'write__form-field-options-handler',
                          { '-active': addingAttachment },
                        )}
                        onClick={ this.showAttachmentsPane }
                      >
                        <Icon glyph="plus-interesting" />
                      </button>
                      <Tooltip isActive={ addingAttachment } onHide={ this.hideAttachmentsPane } at={ tooltipAt }>
                        { mode === BLOG
                          ? <label className="hidden-a-m">
                              { this.renderFileInput() }
                              <Icon glyph="photo" />
                              Прикрепить фотографии
                            </label>
                          : <button type="button" className="hidden-a-m" onClick={ resolution.isMobile() ? this.paneOpenAlbum : this.openModalPhotos }>
                              <Icon glyph="photo" />
                              Прикрепить фотографии
                            </button>
                        }
                        { mode === BLOG
                          ? <React.Fragment>
                              <button type="button" onClick={ this.openModalYouTube }>
                                <Icon glyph="video" />
                                Приложить видео
                              </button>
                              <button type="button" onClick={ this.openModalYandexMusic }>
                                <Icon glyph="music" />
                                Приложить музыку
                              </button>
                            </React.Fragment>
                          : <button type="button" onClick={ resolution.isMobile() ? this.paneOpenGifts: onGiftSend }>
                              <Icon glyph="gift" />
                              Подарить подарок
                            </button>
                        }
                        <button type="button" onClick={ mode === IM && resolution.isMobile() ? this.paneOpenLocation : this.openModalGeo }>
                          <Icon glyph="location" />
                          Отправить геолокацию
                        </button>
                      </Tooltip>
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>
            { mode !== GIFT &&
              <div className="write__form-submit">
                <button type="submit" className="write__form-submit-button" disabled={ isRequest || !!photosInQueue.length }>
                  { isRequest
                    ? <Preloader />
                    : <Icon glyph="chat-stat" />
                  }
                </button>
              </div>
            }
            { mode === BLOG && !this.isWithAttachments() && !!editData &&
              <div className="write__form-cancel">
                <button type="button" onClick={ this.handleCancel } title="Отменить редактирование">
                  <Icon glyph="cross" />
                </button>
              </div>
            }
          </div>
          { !!requestState && !!requestState.error &&
            <div className="write__error">
              <FormError>
                { requestState.error.message }
              </FormError>
            </div>
          }
          { mode === GIFT &&
            <div className="write__gift">
              <div className="write__gift-visibility">
                <FormCheckbox name="anonymous" value="1" checked={ anonymous } changeValue={ this.handleCheckbox } caption="Показывать мое имя и сообщения только получателю" />
              </div>
              <div className="write__gift-button">
                <FormButton skin={ ['darkblue', 'size-m'] } type="submit">
                  Отправить<span className="hidden-b-m"> подарок</span>
                </FormButton>
              </div>
            </div>
          }
          { this.isWithAttachments() &&
            <div className="write__attachments">
              { !isEmpty(photos) &&
                <React.Fragment>
                  <PhotoGrid
                    photos={ Object.values(photos) }
                    photosInQueue={ photosInQueue }
                    onDelete={ this.removePhoto }
                    editable
                  />
                  <hr />
                </React.Fragment>
              }
              { !!videoIframeLink &&
                <React.Fragment>
                  <div className="write__attachments-embedded">
                    <FormButton skin={ ['circle', 'small'] } icon="option-delete" onClick={ this.removeVideo } />
                    <Iframe src={ videoIframeLink } title="YouTube video" />
                  </div>
                  <hr />
                </React.Fragment>
              }
              { !!audioIframeLink &&
                <React.Fragment>
                  <div className="write__attachments-embedded">
                    <FormButton skin={ ['circle', 'small'] } icon="option-delete" onClick={ this.removeAudio } />
                    <Iframe src={ audioIframeLink } title="Яндекс.Музыка" />
                  </div>
                  <hr />
                </React.Fragment>
              }
              { mode === BLOG &&
                <div className="write__attachments-submit">
                  <FormButton type="submit" skin={ ['darkblue', 'size-m'] } isRequest={ isRequest } disabled={ !!photosInQueue.length }>
                    Опубликовать
                  </FormButton>
                  { !!editData &&
                    <div className="write__attachments-submit-cancel">
                      <button type="button" onClick={ this.handleCancel }>Отменить</button>
                    </div>
                  }
                </div>
              }
            </div>
          }
          {(!mode || mode === IM || mode === GALLERY) && !isSSR() && resolution.isMobile() &&
            <WriteEmoji
              mode="overlay"
              isOpen={ isEmojiOpen }
              onChoose={ this.insertHTML }
              onClose={ this.hideEmoji }
            />
          }
          { mode === IM && !isSSR() && resolution.isMobile() &&
            <WritePane
              view={ paneOpen }
              recipientId={ entityId }
              currentUser={ currentUser }
              onClose={ this.paneClose }
              onChangeTab={ this.paneOpen }
              onPhotoAdd={ this.photoAdd }
              onLocationAdd={ this.insertHTML }
            />
          }
        </form>
        { mode === BLOG || mode === IM &&
          <ModalGeo isOpen={ modalOpen === MODAL_GEO } handleClose={ this.closeModal } onChoose={ this.insertHTML } />
        }
        { mode === BLOG &&
          <React.Fragment>
            <ModalYouTube isOpen={ modalOpen === MODAL_YOUTUBE } handleClose={ this.closeModal } onAdd={ this.attachVideo } />
            <ModalYandexMusic isOpen={ modalOpen === MODAL_YANDEX_MUSIC } handleClose={ this.closeModal } onAdd={ this.attachAudio } />
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
};

function mapStateToProps(state) {
  return {
    uploads: state.uploads,
    uploadedPhotos: selectUploadedPhotos(state),
    currentUser: getUser(state, (state.session || {}).user),
    uploadPhotosRequestState: state.states.uploadPhotos || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModalAlbum,
    closeModal,
    uploadPhotos,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Write);

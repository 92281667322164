import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { isSSR } from 'common/helpers';
import api from 'common/middleware/api';
import socketio from 'common/middleware/socketio';
import rootReducer from 'common/reducers';

const middlewares = [
  thunk,
  api,
  socketio,
];

if (!isSSR() && process.env.NODE_ENV !== 'production') {
  const logger = require('redux-logger').createLogger({
    collapsed: true,
    duration: true,
    diff: true,
  });

  middlewares.push(logger);
}

const configureStore = preloadedState => {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.css';

const Switcher = ({ active }) => (
  <span
    className={classNames(
      'switcher',
      { '-active': active },
    )}
  />
);

Switcher.propTypes = {
  active: PropTypes.bool.isRequired,
};

Switcher.defaultProps = {
  active: false,
};

export default Switcher;

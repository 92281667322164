import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import moment from 'moment';
import ImagesLoaded from 'react-images-loaded';

import { loadUsersInteresting, openModalPay, closeModal, hideNav } from 'common/actions';
import { declOfNum, formatNumber, getUser, isUserDisabled } from 'common/helpers';
import { selectData } from './selectors';

import Icon from 'components/Icon';
import UserAvatar from 'components/UserAvatar';
import FormButton from 'components/FormButton';
import UserName from 'components/UserName';
import UserJob from 'components/UserJob';
import Link from 'components/Link';
import Tooltip from 'components/Tooltip';

import './style.css';

class UsersInteresting extends Component {
  static propTypes = {
    allowBecome: PropTypes.bool,
  }

  static defaultProps = {
    allowBecome: true,
  }

  constructor(props) {
    super(props);

    this.openPayModal = this.openPayModal.bind(this);
    this.handleImagesLoaded = this.handleImagesLoaded.bind(this);

    this.state = {
      imagesLoaded: false,
    };
  }

  componentDidMount() {
    this.props.loadUsersInteresting();
  }

  openPayModal() {
    const { openModalPay, closeModal, hideNav } = this.props;
    hideNav();
    openModalPay({
      handleClose: closeModal,
      mode: 'interesting',
    });
  }

  handleImagesLoaded() {
    this.setState({
      imagesLoaded: true,
    });
  }

  render() {
    const { usersInterestingRequestState, currentUser, usersCount, data, isPaid, isLoggedIn, expireDate, allowBecome } = this.props;
    const { imagesLoaded } = this.state;

    if (!data.length || (isLoggedIn && isUserDisabled(currentUser))) {
      return null;
    }

    return (
      <div className={classNames(
        'users-interesting',
        { '-ready': usersInterestingRequestState.success && imagesLoaded },
      )}>
        <div className="users-interesting__heading">
          <Icon glyph="crown-stroke" />
          <div className="users-interesting__heading-title">Интересные анкеты</div>
        </div>
        <ImagesLoaded
          className="users-interesting__users"
          onAlways={ this.handleImagesLoaded }
        >
          { isLoggedIn && !isPaid &&
            <button type="button" className="users-interesting__users-item" onClick={ this.openPayModal }>
              <UserAvatar user={ currentUser } skin="circle" size="large" isSelf withoutBadges />
              <Icon glyph="plus-interesting" />
              <div className="users-interesting__users-item-blink">
                <div className="users-interesting__users-item-blink-animation" />
              </div>
            </button>
          }
          {
            data.slice(0, isLoggedIn && !isPaid ? 8 : 9).map(user =>
              <div className="users-interesting__users-item" key={ user.id }>
                <Link to={ `/user/${user.id}` }>
                  <UserAvatar user={ user } skin="circle" size="large" />
                  <Tooltip>
                    <UserName user={ user } withAge />
                    <UserJob job={ user.job } />
                  </Tooltip>
                </Link>
              </div>
            )
          }
        </ImagesLoaded>
        <React.Fragment>
          <div className="users-interesting__become">
            { allowBecome &&
              <FormButton skin={ ['darkblue', 'size-s', 'size-m-tablet'] } onClick={ this.openPayModal }>
                { isPaid ? 'Продлить' : 'Попасть сюда' }
              </FormButton>
            }
            <div className="users-interesting__become-auditory">
              { isPaid
                ? <React.Fragment>Ваша анкета будет здесь <span className="nobr">ещё { moment().to(expireDate, true) }</span></React.Fragment>
                : <React.Fragment>{ formatNumber(usersCount) } { declOfNum(usersCount, ['человек увидит', 'человека увидят', 'человек увидят']) } вас</React.Fragment>
              }
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
};

function mapStateToProps(state) {
  const usersInteresting = state.data.usersInteresting || {};

  return {
    data: selectData(state),
    usersCount: usersInteresting.cntUsers,
    isPaid: !!usersInteresting.userPaidData,
    isLoggedIn: state.session !== null,
    expireDate: (usersInteresting.userPaidData || {}).endDate,
    usersInterestingRequestState: state.states.usersInteresting || {},
    currentUser: getUser(state, (state.session || {}).user),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadUsersInteresting,
    openModalPay,
    closeModal,
    hideNav,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersInteresting);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { REGEX } from 'common/helpers';

import Form from 'components/Form';
import FormButton from 'components/FormButton';
import FormInput from 'components/FormInput';
import Iframe from 'components/Iframe';
import Modal from 'components/Modal';
import ModalYandexMusicHelp from 'components/ModalYandexMusicHelp';

import './style.css';

class ModalYouTube extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.goHelp = this.goHelp.bind(this);
    this.goBack = this.goBack.bind(this);
    this.addAudio = this.addAudio.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.state = {
      isHelp: false,
      iframeLink: '',
    };
  }

  addAudio(model) {
    this.props.onAdd(model.link, this.state.iframeLink);
    this.setState({
      iframeLink: '',
    });
  }

  handleInput(e) {
    const { value } = e.target;
    const result = REGEX.YANDEX_MUSIC.exec(value);
    const iframeLink = result ? `https://music.yandex.ru/iframe/#${result[1]}/${result[2]}/` : '';

    this.setState({
      iframeLink,
    });
  }

  goHelp() {
    this.setHelpState(true);
  }

  goBack() {
    this.setHelpState(false);
  }

  setHelpState(isHelp, callback) {
    this.setState({
      isHelp,
    }, callback);
  }

  render() {
    const { isOpen, handleClose } = this.props;
    const { isHelp, iframeLink } = this.state;

    return (
      <React.Fragment>
        <Modal isHidden={ isHelp } isOpen={ isOpen } handleClose={ handleClose }>
          <div className="modal-yandex-music">
            <h2>Вставьте ссылку на аудио <br />с <a href="https://music.yandex.ru/" target="_blank" rel="noopener noreferrer">Яндекс Музыки</a></h2>
            <Form action={ this.addAudio }>
              <FormInput
                placeholder="Ссылка на аудио"
                type="text"
                name="link"
                validate="yandexMusic"
                onInput={ this.handleInput }
                autofocus
              />
              { iframeLink
                ? <div className="modal-yandex-music__preview">
                    <Iframe src={ iframeLink } title="Яндекс.Музыка" />
                  </div>
                : <div className="modal-yandex-music__howto">
                    <FormButton skin={ ['circle'] } icon="option-help" onClick={ this.goHelp }>
                      Как добавить трек с Яндекс Музыки
                    </FormButton>
                  </div>
              }
              <FormButton type="submit" skin={ ['darkblue', 'size-m'] }>
                Добавить аудио
              </FormButton>
            </Form>
          </div>
        </Modal>
        <ModalYandexMusicHelp
          isOpen={ isHelp }
          handleClose={ this.goBack }
        />
      </React.Fragment>
    );
  }
};

export default ModalYouTube;

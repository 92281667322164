import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider, Cookies } from 'react-cookie';
import { ensureReady, After } from '@jaredpalmer/after';

import configureStore from 'common/store/configureStore';
import routes from './routes';

import Layout from './document/Layout';

const store = configureStore(window.__PRELOADED_STATE__);
const cookies = new Cookies(document.cookie);
const LayoutWithRouter = withRouter(Layout);

const renderClient = () => {
  ensureReady(routes).then(data =>
    hydrate(
      <BrowserRouter>
        <Provider store={store}>
          <CookiesProvider>
            <LayoutWithRouter>
              <After
                data={data}
                routes={routes}
                store={store}
                cookies={cookies}
              />
            </LayoutWithRouter>
          </CookiesProvider>
        </Provider>
      </BrowserRouter>,
      document.getElementById('root'),
    ),
  );
}

//https://github.com/nimacsoft/afterjs-assets :
// if we call ensureReady on browser (client), it will try to download styles and scripts for second time, (i don't know why browsers allow this). to fix this just call ensureReady on windows.onload method:
if (process.env.NODE_ENV === 'production') {
  window.onload = () => {
    renderClient();
  }
} else { //HMR не работает с window.onload, поэтому для разработки не используем это
  renderClient();
}

if (module.hot) {
  module.hot.accept();
}

import { LIST_LIMIT, generateTypes, withParams } from 'common/helpers';
import { CALL_API, Schemas } from 'common/middleware/api';

export const loadUsersInteresting = () => ({
  [CALL_API]: {
    types: generateTypes('USERS_INTERESTING'),
    method: 'get',
    endpoint: 'users/interesting',
    schema: Schemas.USERS_INTERESTING,
  }
});

export const loadUsersDaily = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('USERS_DAILY'),
    method: 'get',
    endpoint: withParams('users/daily', {
      city_id: params.selectedCity,
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.USERS_COMPACT,
  }
});

export const loadUsersNew = () => ({
  [CALL_API]: {
    types: generateTypes('USERS_NEW'),
    method: 'get',
    endpoint: withParams('users/new', {
      limit: LIST_LIMIT,
    }),
    schema: Schemas.USERS_COMPACT,
  }
});

export const loadUsersTop = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('USERS_TOP'),
    method: 'get',
    endpoint: withParams('users/top', {
      type: params.gender,
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.USERS_COMPACT,
  }
});

export const loadUsersRecommended = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('USERS_RECOMMENDED'),
    method: 'get',
    endpoint: withParams('users/matched', {
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.USERS_COMPACT,
  }
});

export const loadAnswers = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('USER_ANSWERS'),
    method: 'get',
    endpoint: withParams('user/answers', {
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.USER_ANSWERS,
  }
});

export const loadComments = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('USER_COMMENTS'),
    method: 'get',
    endpoint: withParams('user/comments', {
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.USER_COMMENTS,
  }
});

export const loadLikes = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('USER_LIKES'),
    method: 'get',
    endpoint: withParams('user/likes', {
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.USER_LIKES,
  }
});

export const loadSympathies = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('USER_SYMPATHIES'),
    method: 'get',
    endpoint: withParams('user/sympathies', {
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.USER_SYMPATHIES,
  }
});

export const loadSympathiesNew = () => ({
  [CALL_API]: {
    types: generateTypes('USER_SYMPATHIES_NEW'),
    method: 'get',
    endpoint: 'user/sympathies/popup',
    schema: Schemas.USERS_COMPACT,
  }
});

export const clearSympathiesNew = () => ({
  type: 'USER_SYMPATHIES_NEW_CLEAR',
});

export const loadFavorites = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('USERS_FAVORITES'),
    method: 'get',
    endpoint: withParams('users/favorites', {
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.USERS_COMPACT,
  }
});

export const loadFavoritesCount = () => ({
  [CALL_API]: {
    types: generateTypes('USERS_FAVORITES_COUNT'),
    method: 'get',
    endpoint: 'users/favorites/count'
  }
});

export const loadBlackList = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('USERS_BLACK_LIST'),
    method: 'get',
    endpoint: withParams('users/blacklist', {
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.USERS_COMPACT,
  }
});

export const loadBlackListCount = () => ({
  [CALL_API]: {
    types: generateTypes('USERS_BLACK_LIST_COUNT'),
    method: 'get',
    endpoint: 'users/blacklist/count'
  }
});

export const loadGuests = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('USERS_GUESTS'),
    method: 'get',
    endpoint: withParams('users/guests', {
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.USERS_GUESTS,
  }
});

export const loadUser = (id) => ({
  [CALL_API]: {
    types: generateTypes('USER'),
    method: 'get',
    endpoint: `user/${id}`,
    schema: Schemas.USER,
  }
});

export const loadUserCompact = (id) => ({
  [CALL_API]: {
    types: generateTypes('USER_COMPACT'),
    method: 'get',
    endpoint: `user/${id}/short`,
    schema: Schemas.USER_COMPACT,
  }
});

export const loadLikeyou = () => ({
  [CALL_API]: {
    types: generateTypes('LIKEYOU'),
    method: 'get',
    endpoint: 'likeyou/list',
    schema: Schemas.USERS,
  }
});

export const loadCities = (query) => ({
  [CALL_API]: {
    types: generateTypes('CITIES'),
    method: 'get',
    endpoint: withParams('lists/cities', {
      q: query,
    }),
    schema: Schemas.CITIES,
    payload: {
      query,
    },
  }
});

export const loadUblogs = (folder, params = {}) => ({
  [CALL_API]: {
    types: generateTypes('UBLOGS'),
    method: 'get',
    endpoint: withParams(`ublogs/${folder}`, {
      page: params.page,
      limit: params.limit || LIST_LIMIT,
    }),
    schema: Schemas.UBLOGS,
    payload: {
      folder,
    },
  }
});

export const loadUblog = (postId) => ({
  [CALL_API]: {
    types: generateTypes('UBLOG'),
    method: 'get',
    endpoint: `ublog/${postId}`,
    schema: Schemas.UBLOG,
  }
});

export const loadUblogComments = (postId) => ({
  [CALL_API]: {
    types: generateTypes('UBLOG_COMMENTS'),
    method: 'get',
    endpoint: `ublog/${postId}/comments`,
    schema: Schemas.UBLOG_COMMENTS,
  }
});

export const autocompleteCities = (query) => ({
  [CALL_API]: {
    types: generateTypes('AUTOCOMPLETE_CITIES'),
    method: 'get',
    endpoint: withParams('autocomplete/cities/all', {
      q: query,
    }),
    schema: Schemas.AUTOCOMPLETE_CITIES,
    payload: {
      query,
    },
  }
});

export const autocompleteProfessions = (query, gender) => ({
  [CALL_API]: {
    types: generateTypes('AUTOCOMPLETE_PROFESSIONS'),
    method: 'get',
    endpoint: withParams('autocomplete/professions', {
      gender,
      q: query,
    }),
    schema: Schemas.AUTOCOMPLETE_PROFESSIONS,
    payload: {
      query,
    },
  }
});

export const autocompleteOccupations = (query) => ({
  [CALL_API]: {
    types: generateTypes('AUTOCOMPLETE_OCCUPATIONS'),
    method: 'get',
    endpoint: withParams('autocomplete/profarea', {
      q: query,
    }),
    schema: Schemas.AUTOCOMPLETE_OCCUPATIONS,
    payload: {
      query,
    },
  }
});

export const autocompleteNationality = (query, gender) => ({
  [CALL_API]: {
    types: generateTypes('AUTOCOMPLETE_NATIONALITY'),
    method: 'get',
    endpoint: withParams('autocomplete/nationality', {
      gender,
      q: query,
    }),
    schema: Schemas.AUTOCOMPLETE_NATIONALITY,
    payload: {
      query,
    },
  }
});

export const autocompleteReligions = (query, gender) => ({
  [CALL_API]: {
    types: generateTypes('AUTOCOMPLETE_RELIGIONS'),
    method: 'get',
    endpoint: withParams('autocomplete/religions', {
      gender,
      q: query,
    }),
    schema: Schemas.AUTOCOMPLETE_RELIGIONS,
    payload: {
      query,
    },
  }
});

export const autocompleteLanguages = (query, gender) => ({
  [CALL_API]: {
    types: generateTypes('AUTOCOMPLETE_LANGUAGES'),
    method: 'get',
    endpoint: withParams('autocomplete/languages', {
      gender,
      q: query,
    }),
    schema: Schemas.AUTOCOMPLETE_LANGUAGES,
    payload: {
      query,
    },
  }
});

export const getBadges = () => ({
  [CALL_API]: {
    types: generateTypes('BADGES'),
    method: 'get',
    endpoint: 'badges',
  }
});

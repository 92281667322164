import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormButton from 'components/FormButton';
import ModalPhoto from 'components/ModalPhoto';

import './style.css';

const MAX_PHOTOS = 4;
const MODAL_PHOTO = 'MODAL_PHOTO';

class PhotoGrid extends Component {
  static propTypes = {
    photos: PropTypes.array,
    photosInQueue: PropTypes.array,
    editable: PropTypes.bool,
    onDelete: PropTypes.func,
  }

  static defaultProps = {
    photos: [],
    photosInQueue: [],
    editable: false,
  }

  constructor(props) {
    super(props);
    this.openPhoto = this.openPhoto.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deletePhoto = this.deletePhoto.bind(this);
    this.state = {
      modalOpen: '',
      clickedPhotoId: 0,
    };
  }

  openPhoto(e) {
    this.setState({
      modalOpen: MODAL_PHOTO,
      clickedPhotoId: parseInt(e.currentTarget.getAttribute('data-photo-id'), 10),
    });
  }

  closeModal() {
    this.setState({
      modalOpen: '',
    });
  }

  deletePhoto(e) {
    const { onDelete } = this.props;
    const id = e.currentTarget.getAttribute('data-photo-id');
    const requestId = e.currentTarget.getAttribute('data-request-id');

    if (!id || typeof onDelete !== 'function') {
      return;
    }

    onDelete(id, requestId);
  }

  renderImage(photos, index) {
    const { editable } = this.props;
    const allPhotos = this.props.photos;
    const photo = photos[index];
    const isInQueue = this.props.photosInQueue.indexOf(photo.requestId) !== -1;
    let gridClassName;

    if (photos.length === 1 || (photos.length >= 3 && index === 0)) {
      gridClassName = '-full';
    } else if (photos.length >= MAX_PHOTOS) {
      gridClassName = '-third';
    } else {
      gridClassName = '-half';
    }

    return (
      <div
        className={classNames(
          'photo-grid__image',
          gridClassName,
          { '-loading': isInQueue },
        )}
        style={{
          backgroundImage: `url("${photo.src}")`,
        }}
        key={ photo.id }
      >
        <button type="button" data-photo-id={ photo.id } onClick={ this.openPhoto } className="photo-grid__image-opener" />
        { (allPhotos.length > MAX_PHOTOS && (index + 1) === MAX_PHOTOS)
          ? <div className="photo-grid__image-overlay">
              <b>+{allPhotos.length - MAX_PHOTOS}</b>
            </div>
          : editable && <FormButton skin={ ['circle', 'small'] } icon="option-delete" onClick={ this.deletePhoto } data-photo-id={ photo.id } data-request-id={ photo.requestId } />
        }
      </div>
    );
  }

  render() {
    const { modalOpen, clickedPhotoId } = this.state;
    const photos = this.props.photos.slice(0, MAX_PHOTOS);
    const data = photos.slice(0).reduce((result, photo) => {
      result[photo.id] = photo;
      return result;
    }, {});

    if (!photos.length) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="photo-grid">
          { photos.map((p, index) => this.renderImage(photos, index)) }
        </div>
        <ModalPhoto
          data={ data }
          initialPhotoId={ clickedPhotoId }
          isOpen={ modalOpen === MODAL_PHOTO }
          handleClose={ this.closeModal }
        />
      </React.Fragment>
    );
  }
};

export default PhotoGrid;

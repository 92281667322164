import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import noop from 'lodash/noop';

import { formatDate, getUser } from 'common/helpers';

import Scrollable from 'components/Scrollable';
import CommentsItem from './components/Item';

import './style.css';

class Comments extends Component {
  static propTypes = {
    appearance: PropTypes.oneOf(['chat', 'comments']),
    data: PropTypes.array.isRequired,
    selectedMessages: PropTypes.array,
    failedMessages: PropTypes.array,
    entityId: PropTypes.number.isRequired,
    entityUserId: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    onScroll: PropTypes.func,
    deleteCommentState: PropTypes.object,
    allowSelect: PropTypes.bool,
    scrollTrigger: PropTypes.object,
    hasSendStatus: PropTypes.bool,
  }

  static defaultProps = {
    appearance: 'comments',
    data: [],
    users: {},
    usersCompact: {},
    selectedMessages: [],
    failedMessages: [],
    allowSelect: false,
    onSelect: noop,
    onScroll: noop,
  }

  renderSpacer(data, comment, index) {
    if (
      index === 0 ||
      !moment(comment.datetime).isSame(data[index - 1].datetime, 'day')
    ) {
      return (
        <div className="comments__spacer">
          { formatDate(comment.datetime, 'day') }
        </div>
      );
    }

    return null;    
  }

  render() {
    const {
      appearance,
      data,
      users,
      usersCompact,
      selectedMessages,
      failedMessages,
      entityId,
      entityUser,
      currentUser,
      onDelete,
      onSelect,
      onScroll,
      deleteCommentState,
      scrollTrigger,
      hasSendStatus,
    } = this.props;

    const dataToRender = data.slice(0).reverse();
    const lastCommentOfCurrentUser = data.find(comment => comment.user === currentUser.id) || {};

    return (
      <div className={ `comments -appearance-${appearance}` }>
        <Scrollable
          onScroll={ onScroll }
          scrollTrigger={ scrollTrigger }
        >
          {
            dataToRender.map((comment, index) => (
              <React.Fragment key={ comment.tempId || comment.id }>
                { appearance === 'chat' &&
                  this.renderSpacer(dataToRender, comment, index)
                }
                <CommentsItem
                  selected={ selectedMessages.indexOf(comment.id) !== -1 }
                  isFailed={ failedMessages.indexOf(comment.tempId) !== -1 }
                  hasSendStatus={hasSendStatus && lastCommentOfCurrentUser.id === comment.id}
                  appearance={ appearance }
                  data={ comment }
                  user={ usersCompact[comment.user] || users[comment.user] }
                  entityId={ entityId }
                  entityUser={ entityUser }
                  currentUser={ currentUser }
                  onDelete={ onDelete }
                  onSelect={ onSelect }
                  deleteCommentState={ deleteCommentState }
                />
              </React.Fragment>
            ))
          }
        </Scrollable>
      </div>
    );
  }
};

function mapStateToProps(state, ownProps) {
  return {
    users: state.entities.users,
    usersCompact: state.entities.usersCompact,
    entityUser: getUser(state, ownProps.entityUserId),
    currentUser: getUser(state, (state.session || {}).user),
  };
}

export default connect(
  mapStateToProps,
)(Comments);

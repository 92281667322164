import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadUsersRecommended } from 'common/actions';
import { selectData } from './selectors';

import Section, { SectionBody, SectionHeader } from 'components/Section';
import FormButton from 'components/FormButton';
import Icon from 'components/Icon';
import UserAvatar from 'components/UserAvatar';
import UserName from 'components/UserName';
import UserJob from 'components/UserJob';
import Link from 'components/Link';

import './style.css';

class UsersRecommended extends Component {
  componentDidMount() {
    const { isLoggedIn, loadUsersRecommended } = this.props;

    if (isLoggedIn) {
      loadUsersRecommended();
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoggedIn, loadUsersRecommended } = this.props;

    if (isLoggedIn && !prevProps.isLoggedIn) {
      loadUsersRecommended();
    }
  }

  render() {
    const { data, isLoggedIn } = this.props;

    if (!isLoggedIn) {
      return null;
    }

    return (
      <aside className="recommended">
        { data.length
          ? <Section>
              <SectionHeader>
                <Icon glyph="user" />
                <div className="recommended__title">Вам подходят</div>
              </SectionHeader>
              <SectionBody>
                <div className="recommended__list">
                  {
                    data.map(user => (
                      <div
                        className="recommended__item"
                        key={ user.id }
                      >
                        <Link to={ `/user/${user.id}` } className="recommended__link">
                          <UserAvatar user={ user } skin="circle" size="large" />
                          <span className="recommended__info">
                            <UserName user={ user } withAge />
                            <UserJob job={ user.job } />
                          </span>
                        </Link>
                      </div>
                    ))
                  }
                </div>
                <div className="recommended__button">
                  <FormButton skin={ ['lightblue', 'size-s'] } to="/matched">Смотреть все</FormButton>
                </div>
              </SectionBody>
            </Section>
          : null
        }
      </aside>
    );
  }
};

function mapStateToProps(state) {
  return {
    data: selectData(state),
    isLoggedIn: !!state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadUsersRecommended,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersRecommended);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'components/Icon';
import WritePaneAlbum from './components/Album';
import WritePaneGifts from './components/Gifts';
import WritePaneLocation from './components/Location';

import './style.css';

const TABS = ['photo', 'location', 'gift'];

class WritePane extends Component {
  static propTypes = {
    view: PropTypes.string,
    recipientId: PropTypes.number,
    currentUser: PropTypes.object,
    onClose: PropTypes.func,
    onChangeTab: PropTypes.func,
    onPhotoAdd: PropTypes.func,
    onLocationAdd: PropTypes.func,
  }
  constructor(props) {
    super(props);
    this.tabSwitch = this.tabSwitch.bind(this);
  }

  tabSwitch(e) {
    this.props.onChangeTab(e.currentTarget.dataset.tab);
  }

  render() {
    const { view, currentUser, recipientId, onClose, onPhotoAdd, onLocationAdd } = this.props;

    return (
      <div
        className={classNames(
          'write-pane',
          { '-active': !!view },
        )}
      >
        <div className="write-pane__body">
          <div className="write-pane__tabs">
            {
              TABS.map(tab =>
                <button
                  type="button"
                  className={classNames(
                    'write-pane__tabs-button',
                    { '-active': view === tab },
                  )}
                  onClick={ this.tabSwitch }
                  data-tab={ tab }
                  key={ tab }
                >
                  <Icon glyph={ tab } />
                </button>
              )
            }
          </div>
          <div className="write-pane__content">
            { view === 'photo' &&
              <WritePaneAlbum userId={ currentUser.id } onClose={ onClose } onPhotoAdd={ onPhotoAdd } />
            }
            { view === 'gift' &&
              <WritePaneGifts onClose={ onClose } recipientId={ recipientId } />
            }
            { view === 'location' &&
              <WritePaneLocation onClose={ onClose } onChoose={ onLocationAdd } />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default WritePane;

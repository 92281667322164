import React from 'react';

import { updateLayoutSettings } from 'common/actions';

import Meta from 'components/Meta';
import Section, { SectionBody } from 'components/Section';
import FormButton from 'components/FormButton';

import './style.css';

const NotFound = () => (
  <React.Fragment>
    <Meta
      title="404 Страница не найдена"
    />
    <div className="not-found">
      <Section>
        <SectionBody>
          <div className="not-found__body">
            <div className="not-found__title">404</div>
            <p>Такой страницы не существует</p>
            <FormButton skin={ ['darkblue', 'size-m'] } to="/">
              <span className="hidden-b-m">Перейти к знакомствам</span>
              <span className="hidden-a-m">К знакомствам</span>
            </FormButton>
          </div>
        </SectionBody>
      </Section>
    </div>
  </React.Fragment>
);

NotFound.getInitialProps = async ({ store, res }) => {
  res.statusCode = 404;

  store.dispatch(updateLayoutSettings({
    footer: false,
    recommended: true,
  }));

  return;
}

export default NotFound;

import React from 'react';
import PropTypes from 'prop-types';

import Form from 'components/Form';
import FormInput from 'components/FormInput';
import FormButton from 'components/FormButton';
import ModalSimple from 'components/ModalSimple';

const ModalPasswordUpdate = ({ onSubmit, isOpen, handleClose, actionState, recoveryCode }) => {
  return (
    <ModalSimple isOpen={ isOpen } handleClose={ handleClose }>
      <h2>Введите новый пароль</h2>
      <Form id="form-password-update" action={ onSubmit } actionState={ actionState }>
        <FormInput
          placeholder="Придумайте новый пароль"
          type="password"
          name="password"
          autofocus
        />
        <FormInput
          placeholder="Подтвердите новый пароль"
          type="password"
          name="password_confirm"
        />
        <input type="hidden" name="code" value={ recoveryCode } />
        <FormButton type="submit" skin={ ['darkblue', 'size-m'] }>
          Сохранить пароль
        </FormButton>
      </Form>
    </ModalSimple>
  );
};

ModalPasswordUpdate.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actionState: PropTypes.object.isRequired,
  recoveryCode: PropTypes.string,
};

export default ModalPasswordUpdate;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { prettyCount, formatNumber } from 'common/helpers';

import Preloader from 'components/Preloader';

import './style.css';

const Notification = ({ value, isLoading }) => {
  if (value < 0 || isNaN(value)) {
    return null;
  }

  return (
    <span
      className={classNames(
        'notification',
        { '-loading': isLoading },
      )}
      title={ formatNumber(value) }
    >
      { isLoading
        ? <span className="notification__preloader">
            <Preloader />
          </span>
        : null
      }
      { prettyCount(value) }
    </span>
  );
};

Notification.propTypes = {
  value: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};

export default Notification;

import { LIST_LIMIT, generateTypes, withParams } from 'common/helpers';
import { CALL_API, Schemas } from 'common/middleware/api';

export const startFormJob = id => ({
  id,
  type: 'START_FORM_JOB',
});

export const finishFormJob = id => ({
  id,
  type: 'FINISH_FORM_JOB',
});

export const sendTicketSupport = body => ({
  [CALL_API]: {
    body,
    types: generateTypes('TICKET_SUPPORT'),
    method: 'post',
    endpoint: 'tickets/support',
  }
});

export const sendTicketCooperation = body => ({
  [CALL_API]: {
    body,
    types: generateTypes('TICKET_COOPERATION'),
    method: 'post',
    endpoint: 'tickets/cooperation',
  }
});

export const search = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('SEARCH'),
    method: 'get',
    endpoint: withParams('users/search', {
      ...params,
      limit: LIST_LIMIT,
    }),
    schema: Schemas.USERS_COMPACT,
  }
});

export const getSearchResultsCount = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('SEARCH_RESULTS_COUNT'),
    method: 'get',
    endpoint: withParams('users/search/count', params),
  }
});

export const getSearchResultsCountNext = (params = {}) => ({
  [CALL_API]: {
    types: generateTypes('SEARCH_RESULTS_COUNT_NEXT'),
    method: 'get',
    endpoint: withParams('users/search/count', params),
  }
});

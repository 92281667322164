import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isSSR } from 'common/helpers';

import FormButton from 'components/FormButton';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import UserCard from 'components/UserCard';

import './style.css';

class ModalSympathies extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
    this.keyNav = this.keyNav.bind(this);
    this.state = {
      currentIndex: 0,
    };
  }

  componentDidMount() {
    if (!isSSR()) {
      document.addEventListener('keydown', this.keyNav, false);
    }
  }

  componentWillUnmount() {
    if (!isSSR()) {
      document.removeEventListener('keydown', this.keyNav, false);
    }
  }

  keyNav(e) {
    const { isOpen, handleClose } = this.props;
    const keyCode = e.keyCode || e.charCode;

    if (!isOpen) {
      return;
    }

    if (keyCode === 37) {
      this.goPrev();
    }
    if (keyCode === 39) {
      this.goNext();
    }
    if (keyCode === 13) {
      handleClose();
    }
  }

  goPrev() {
    this.setState(state => ({
      currentIndex: Math.max(0, state.currentIndex - 1),
    }));
  }

  goNext() {
    this.setState(state => ({
      currentIndex: Math.min(state.currentIndex + 1, this.props.data.length - 1),
    }));
  }

  render() {
    const { isOpen, handleClose, data, currentUser } = this.props;
    const { currentIndex } = this.state;

    return (
      <Modal isOpen={ isOpen } handleClose={ handleClose }>
        <div className="modal-sympathies">
          <div className="modal-sympathies__body">
            <div className="modal-sympathies__title">
              У вас взаимная симпатия! { data.length > 1 &&
                <span>{ currentIndex + 1 } из { data.length }</span>
              }
            </div>
            <div className="modal-sympathies__slider">
              { data.length > 1 &&
                <React.Fragment>
                  { currentIndex > 0 &&
                    <button type="button" className="modal-sympathies__slider-nav -prev" onClick={ this.goPrev }>
                      <Icon glyph="arrow-gift" />
                    </button>
                  }
                  { currentIndex < data.length - 1 &&
                    <button type="button" className="modal-sympathies__slider-nav -next" onClick={ this.goNext }>
                      <Icon glyph="arrow-gift" />
                    </button>
                  }
                </React.Fragment>
              }
              <div className="modal-sympathies__slider-body">
                <UserCard
                  user={ currentUser }
                />
                <UserCard
                  user={ data[currentIndex] }
                  disableMobileView
                />
                <div className="modal-sympathies__slider-body-heart">
                  <Icon glyph="heart-filled" />
                </div>
              </div>
            </div>
            <div className="modal-sympathies__close">
              <FormButton skin={ ['darkblue', 'size-m'] } onClick={ handleClose }>
                OK
              </FormButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalSympathies;

import { createSelectorCreator } from 'reselect'
import isEqual from 'lodash/isEqual';
import flatten from 'lodash/flatten';
import memoize from 'memoize-one';

const createDataSelector = createSelectorCreator(memoize, (a, b) => {
  if (Array.isArray(a)) {
    return isEqual(a, b);
  }

  return true;
});

export const selectData = createDataSelector(
  (state) => state.data.usersRecommended,
  (state) => state.entities.usersCompact,
  (usersRecommended, usersCompact) => {
    return flatten(usersRecommended || [])
      .slice(0, 7)
      .map(id => usersCompact[id])
      .filter(user => user.roleStatus !== "deleted")
  }
);

import React from 'react';
import PropTypes from 'prop-types';

import FormButton from 'components/FormButton';
import ModalSimple from 'components/ModalSimple';

const ModalAlert = ({ isOpen, handleClose, children, withLogo }) => (
  <ModalSimple isOpen={ isOpen } handleClose={ handleClose }>
    { withLogo &&
      <img src="/images/logo.svg" alt="LinkYou" />
    }
    { children }
    { !!handleClose &&
      <FormButton type="submit" skin={ ['darkblue', 'size-m'] } onClick={ handleClose }>
        OK
      </FormButton>
    }
  </ModalSimple>
);

ModalAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  withLogo: PropTypes.bool,
};

ModalAlert.defaultProps = {
  withLogo: false,
};

export default ModalAlert;
